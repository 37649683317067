(function (requirejs) {
'use strict';

  define('directives',['angular'], function(angular) {

  /* Directives */

  angular.module('eezycleanApp.directives', []).
    directive('appVersion', ['version', function(version) {
      return function(scope, elm, attrs) {
        elm.text(version);
      };
    }]);

  });
})(requirejs);

