(function (requirejs) {
'use strict';


    define('configuration/motifAbsence',['angular'],
        function(angular) {

            angular.module('eezycleanApp.configuration.motifAbsence', ['ngRoute', 'pascalprecht.translate'])


            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/configuration/motif-absence', {
                    templateUrl: '/partials/configuration/motifAbsence/list.tpl.html'
                });
                $routeProvider.when('/configuration/motif-absence/new', {
                    templateUrl: '/partials/configuration/motifAbsence/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.Configuration.MotifAbsence.Create.Title';
                        $scope.mode = 'create';
                        $scope.url = '/configuration/motif-absence';
                    }]
                });
                $routeProvider.when('/configuration/motif-absence/:id', {
                    templateUrl: '/partials/configuration/motifAbsence/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.Configuration.MotifAbsence.Edit.Title';
                        $scope.mode = 'edit';
                        $scope.url = '/configuration/motif-absence/:id';
                    }]
                });
            }]);

        }
    );
})(requirejs);

