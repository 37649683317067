(function (requirejs) {
'use strict';

    define('../lib/eezymanager-common/javascripts/services',['angular'], function(angular) {

        /* Services */

        // Demonstrate how to register services
        // In this case it is a simple value service.
        angular.module('eezymanager.common.services', ['tmh.dynamicLocale', 'ngCookies'])
            .value('version', '0.1')
            .constant('AUTH_EVENTS', {
                loginSuccess: 'auth-login-success',
                loginFailed: 'auth-login-failed',
                logoutSuccess: 'auth-logout-success',
                sessionTimeout: 'auth-session-timeout',
                notAuthenticated: 'auth-not-authenticated',
                notAuthorized: 'auth-not-authorized'
            })
            .constant('LANGUAGE_EVENTS', {
                languageChanged: 'language-changed'
            })
            .constant('AUTHORIZATION_EVENTS', {
                clientChanged: 'client-changed',
                employeeChanged: 'employee-changed'
            })
            .service('Language', ['$http', '$q', '$rootScope', '$translate', '$route', 'LANGUAGE_EVENTS', 'tmhDynamicLocale', 'EezyPromise', 'DTDefaultOptions', '$cookieStore', function($http, $q, $rootScope, $translate, $route, LANGUAGE_EVENTS, tmhDynamicLocale, EezyPromise, DTDefaultOptions, $cookieStore) {

                var currentLanguage;

                $http({
                    url: '/user-language'
                }).then(function(response) {
                    initLanguage(response.data);
                }, function(response) {
                    initLanguage(null);
                });

                function initLanguage(language) {
                    if (angular.isDefined(language) && language !== null && language !== '')
                    {
                        currentLanguage = language;
                    } else {
                        currentLanguage = $cookieStore.get('lang');
                    }

                    if (!angular.isDefined(currentLanguage) || currentLanguage === null || currentLanguage === '') {
                        $http({
                            url: '/accept-language'
                        }).then(function(response) {
                            if (angular.isDefined(response.data) && response.data !== null && response.data !== '')
                            {
                                currentLanguage = response.data;
                                useLanguage(currentLanguage);
                            } else {
                                useLanguage(navigator.language);
                            }
                        }, function(response) {
                            useLanguage(navigator.language);
                        });
                    } else {
                        $cookieStore.put('lang', currentLanguage);
                        tmhDynamicLocale.set(language);
                        useLanguage(currentLanguage);
                    }
                }

                function useLanguage(language) {
                    $translate.use(language).then(function() {
                        updateDatatableLanguage()
                            .then(function() {
                                $route.reload();
                                $rootScope.$broadcast(LANGUAGE_EVENTS.languageChanged);
                            });
                    });
                }

                function updateDatatableLanguage() {
                    var language = {
                        "sEmptyTable": $translate("dt.sEmptyTable"),
                        "sInfo": $translate("dt.sInfo"),
                        "sInfoEmpty": $translate("dt.sInfoEmpty"),
                        "sInfoFiltered": $translate("dt.sInfoFiltered"),
                        "sInfoPostFix": $translate("dt.sInfoPostFix"),
                        "sInfoThousands": $translate("dt.sInfoThousands"),
                        "sLengthMenu": $translate("dt.sLengthMenu"),
                        "sLoadingRecords": $translate("dt.sLoadingRecords"),
                        "sProcessing": $translate("dt.sProcessing"),
                        "sSearch": $translate("dt.sSearch"),
                        "sZeroRecords": $translate("dt.sZeroRecords"),
                        "oPaginate": {
                           "sFirst": $translate("dt.sFirst"),
                           "sLast": $translate("dt.sLast"),
                           "sNext": $translate("dt.sNext"),
                           "sPrevious": $translate("dt.sPrevious")
                        },
                        "oAria": {
                           "sSortAscending": $translate("dt.sSortAscending"),
                           "sSortDescending": $translate("dt.sSortDescending")
                        }
                    };
                    var promise = EezyPromise.resolveObjectPromises(language);
                    return promise
                        .then(function(result) {
                            DTDefaultOptions.setLanguage(result);
                        });
                }

                return {
                    currentLanguage: function() {
                        return currentLanguage;
                    },
                    changeLanguage: function(language) {
                        if (currentLanguage != language) {
                            $cookieStore.put('lang', language);
                            currentLanguage = language;
                            tmhDynamicLocale.set(language);
                            $translate.use(language).then(function() {
                                updateDatatableLanguage()
                                    .then(function() {
                                        $route.reload();
                                        $rootScope.$broadcast(LANGUAGE_EVENTS.languageChanged);
                                    });
                            });
                        }
                    }
                };
            }])
            .service('EezyUrl', [function() {
                this.generate = function(url, params) {
                    if (params) {
                        angular.forEach(params, function(value, key) {
                            url = url.replace(':' + key, value);
                        });
                    }

                    return url;
                };

                return this;
            }])
            .factory('EezyNotify', ['growl', function(growl) {
                return growl;
            }])
            .service('EezyPromise', ['$q', function($q) {

                /**
                 * Resolve any promises from a given object if there are any.
                 * @param obj the object
                 * @param excludedPropertiesName the list of properties to ignore
                 * @returns {promise} the promise that the object attributes promises are all resolved
                 */
                this.resolveObjectPromises = function(obj, excludedPropertiesName) {
                    var defer = $q.defer(),
                        promises = [],
                        resolvedObj = {},
                        excludedProp = excludedPropertiesName || [];
                    if (!angular.isObject(obj) || angular.isArray(obj)) {
                        defer.resolve(obj);
                    } else {
                        resolvedObj = angular.extend(resolvedObj, obj);
                        for (var prop in resolvedObj) {
                            if (resolvedObj.hasOwnProperty(prop) && excludedProp.indexOf(prop) === -1) {
                                if (angular.isArray(resolvedObj[prop])) {
                                    promises.push(this.resolveArrayPromises(resolvedObj[prop]));
                                } else {
                                    if (angular.isObject(resolvedObj[prop]) && resolvedObj[prop] && !angular.isFunction(resolvedObj[prop].then)) {
                                        promises.push(this.resolveObjectPromises(resolvedObj[prop]));
                                    } else {
                                        promises.push($q.when(resolvedObj[prop]));
                                    }
                                }
                            }
                        }
                        $q.all(promises).then(function (result) {
                            var index = 0;
                            for (var prop in resolvedObj) {
                                if (resolvedObj.hasOwnProperty(prop) && excludedProp.indexOf(prop) === -1) {
                                    resolvedObj[prop] = result[index++];
                                }
                            }
                            defer.resolve(resolvedObj);
                        });
                    }
                    return defer.promise;
                };

                /**
                 * Resolve the given array promises
                 * @param array the array containing promise or not
                 * @returns {promise} the promise that the array contains a list of objects/values promises that are resolved
                 */
                this.resolveArrayPromises = function(array) {
                    var defer = $q.defer(),
                        promises = [],
                        resolveArray = [];
                    if (!angular.isArray(array)) {
                        defer.resolve(array);
                    } else {
                        array.forEach(function (item) {
                            if (angular.isObject(item)) {
                                promises.push(this.resolveObjectPromises(item));
                            } else {
                                promises.push($q.when(item));
                            }
                        });
                        $q.all(promises).then(function (result) {
                            result.forEach(function (item) {
                                resolveArray.push(item);
                            });
                            defer.resolve(resolveArray);
                        });
                    }
                    return defer.promise;
                };

                return this;
            }])
            ;
    });
})(requirejs);

