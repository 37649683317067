(function (requirejs) {
'use strict';


define('../lib/eezymanager-core/javascripts/eezymanager-core',[
    'angular',
    './form',
    './message',
    './controllers',
    './admin/client',
    './admin/translation',
    './admin/site',
    './admin/tablet',
    './admin/icon',
    './admin/permission',
    './configuration/employee',
    './configuration/role',
    './configuration/scheduled_task'
    ],
    function(angular) {

        angular.module('eezymanager.core',
            [
                'eezymanager.core.form',
                'eezymanager.core.message',
                'eezymanager.core.admin.client',
                'eezymanager.core.admin.translation',
                'eezymanager.core.admin.site',
                'eezymanager.core.admin.tablet',
                'eezymanager.core.admin.permission',
                'eezymanager.core.admin.icon',
                'eezymanager.core.configuration.employee',
                'eezymanager.core.configuration.role',
                'eezymanager.core.configuration.scheduled_task',
                'eezymanager.core.admin.client.banner'
            ]
        );

    });
})(requirejs);

