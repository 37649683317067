(function (requirejs) {
'use strict';

    define('../lib/eezymanager-securesocial/javascripts/filters',['angular'], function(angular) {

        /* Filters */

        angular.module('eezymanager.securesocial.filters', []);

    });
})(requirejs);

