(function (requirejs) {
'use strict';


define('../lib/eezymanager-common/javascripts/eezymanager-common',['angular', 'moment', './controllers', './directives', './filters', './services', './form', './datatable', 'angular-route', 'ionSlider'],
    function(angular, moment, controllers) {





        // Declare app level module which depends on filters, and services
        angular.module('eezymanager.common', ['eezymanager.common.services', 'eezymanager.common.datatable', 'eezymanager.common.form', 'eezymanager.common.directives', 'eezymanager.common.filters', 'eezymanager.common.controllers', 'ngRoute', 'pascalprecht.translate', 'isteven-multi-select'])
            .config(['$routeProvider', function($routeProvider) {
            }])
            .run(['$rootScope', '$routeParams', '$locale', function($rootScope, $routeParams, $locale) {
                // TODO not needed (yet ?) $rootScope.$routeParams = $routeParams;
            }])
            .run(['Language', '$rootScope', 'LANGUAGE_EVENTS', '$http', function(Language, $rootScope, LANGUAGE_EVENTS, $http) {
                $rootScope.currentLanguage = Language.currentLanguage();
                $rootScope.$on(LANGUAGE_EVENTS.languageChanged, function() {
                    $rootScope.currentLanguage = Language.currentLanguage();
                });
                $rootScope.changeLanguage = function(key) {
                    Language.changeLanguage(key);
                    $rootScope.currentLanguage = key;
                    $http({
                        method: 'post',
                        url: '/change-language/' + key
                    }).success(function(){});
                };
            }])
            .config(['$provide', function($provide) {
                $provide.decorator('dateFilter', ['$delegate', '$injector', 'Session', '$filter', '$locale', function($delegate, $injector, Session, $filter, $locale) {
                    var oldDelegate = $delegate;

                    var standardDateFilterInterceptor = function(date, format, timezone) {
                        if (date === null) {
                            return oldDelegate.apply(this, [date, format, timezone]);
                        }
                        if(angular.isUndefined(timezone)) {
                            var identity = Session.getIdentity();
                            if (angular.isDefined(identity) && angular.isDefined(identity.timezone) && identity.timezone !== null) {
                                timezone = identity.timezone;
                            } else {
                                timezone = 'UTC';
                            }
                        }

                        var momentDate = moment(date).locale($locale.id).tz(timezone);
                        var timezoneStr;
                        if (angular.isDefined(momentDate)) {
                            timezoneStr = momentDate.format('z');
                            timezone = momentDate.format('Z');
                        }

                        if (format !== 'Z' && (angular.isUndefined(timezone) || ['medium', 'short', 'shortTime', 'mediumTime'].indexOf(format) > -1)) {
                            return oldDelegate.apply(this, [date, format, timezone]) + ' ' + timezoneStr;
                        } else if (angular.isDefined(format) && ['mediumNoTZ', 'shortNoTZ', 'shortTimeNoTZ', 'mediumTimeNoTZ'].indexOf(format)) {
                            return oldDelegate.apply(this, [date, format.replace("NoTZ", ""), timezone]);
                        } else {
                            return oldDelegate.apply(this, [date, format, timezone]);
                        }

                    };

                    return standardDateFilterInterceptor;
                }]);
            }])

        ;
    });
})(requirejs);

