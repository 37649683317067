(function (requirejs) {
'use strict';

    define('report/report',['angular', 'chartjs', 'moment-range'],
    function(angular, chartjs, moment_range) {

        angular.module('eezycleanApp.report', ['ngRoute'])
        // Routes
        .config(['$routeProvider', function($routeProvider) {
            $routeProvider.when('/report', {
                templateUrl: '/partials/report/report.tpl.html',
                controller: ['$rootScope', '$scope', '$http', function($rootScope, $scope, $http) {

                    $scope.period = ['week', 'month', 'year'];
                    $scope.filter = {};

                    var date = moment().utc().locale($rootScope.currentLanguage);
                    $scope.week = date.week();
                    $scope.month = date.format("MMMM");
                    $scope.year = date.year();

                    $scope.selectPeriod = function(period){
                        $scope.filter.period = $scope.period[period];
                        $scope.filter.range = date.range($scope.filter.period).toDate();
                    };

                    $scope.$watchGroup(['filter.sites', 'filter.allElements'],function(newValue, oldValue){
                        if (angular.isDefined($scope.filter.sites)) {
                            $scope.getTypeControleContradictoire();
                            $scope.getTypeEvaluation();
                            $scope.getTypeDeclaration();
                            $scope.getConsommable();
                        }
                    });

                    $scope.getTypeControleContradictoire = function(){
                        $http({
                            method: 'POST',
                            url: '/report/type/ctrlcont',
                            data: $scope.filter
                        }).success(function(data){
                            $scope.typeCtrlContList=data;
                        });
                    };

                    $scope.getTypeEvaluation = function(){
                        $http({
                            method: 'POST',
                            url: '/report/type/evaluation',
                            data: $scope.filter
                        }).success(function(data){
                            $scope.typeEvaluationList=data;
                        });
                    };

                    $scope.getTypeDeclaration = function(){
                        $http({
                            method: 'POST',
                            url: '/report/type/declaration',
                            data: $scope.filter
                        }).success(function(data){
                            $scope.typeDeclarationList=data;
                        });
                    };

                    $scope.getConsommable = function(){
                        $http({
                            method: 'POST',
                            url: '/report/type/consommable',
                            data: $scope.filter
                        }).success(function(data){
                            $scope.consommableList=data;
                        });
                    };

                    $scope.subOneWeek = function(){
                        $scope.week = date.subtract(1, 'w').week();
                        $scope.month = date.format("MMMM");
                        $scope.year = date.year();

                        $scope.filter.range = date.range($scope.filter.period).toDate();
                    };

                    $scope.addOneWeek = function(){
                        $scope.week = date.add(1, 'w').week();
                        $scope.month = date.format("MMMM");
                        $scope.year = date.year();

                        $scope.filter.range = date.range($scope.filter.period).toDate();
                    };

                    $scope.subOneMonth = function(){
                        $scope.month = date.subtract(1, 'M').format("MMMM");
                        $scope.week = date.week();
                        $scope.year = date.year();

                        $scope.filter.range = date.range($scope.filter.period).toDate();
                    };

                    $scope.addOneMonth = function(){
                        $scope.month = date.add(1, 'M').format("MMMM");
                        $scope.week = date.week();
                        $scope.year = date.year();

                        $scope.filter.range = date.range($scope.filter.period).toDate();
                    };

                    $scope.subOneYear = function(){
                        $scope.year = date.subtract(1, 'y').year();
                        $scope.week = date.week();
                        $scope.month = date.format("MMMM");

                        $scope.filter.range = date.range($scope.filter.period).toDate();
                    };

                    $scope.addOneYear = function(){
                        $scope.year = date.add(1, 'y').year();
                        $scope.week = date.week();
                        $scope.month = date.format("MMMM");

                        $scope.filter.range = date.range($scope.filter.period).toDate();
                    };

                    $scope.selectPeriod(0);

                }]
            });
        }])
        .directive("eezyDashboardAverage", ["$http", function($http){
            return{
                restrict : 'E',
                scope : {
                    reportDataUrl : '@',
                    cssClass : '@',
                    percentage : '@?',
                    filter:'=',
                    typeElement: '@?',
                    label: '@'
                },
                templateUrl : '/partials/report/directive/dashboardaverage.tpl.html',
                link :
                    function(scope, element, attrs){
                        scope.$watchGroup(['filter.sites', 'filter.range'],function(newValue, oldValue){
                            var filter = angular.copy(scope.filter);

                            filter.typeElement = scope.typeElement;

                            if (angular.isDefined(scope.filter) && angular.isDefined(scope.filter.sites) && angular.isDefined(scope.filter.range) && scope.filter.range.length > 0) {
                                $http({
                                    method: 'POST',
                                    url: scope.reportDataUrl,
                                    data: filter
                                }).success(function(data){
                                    scope.averageCC = data;
                                });
                            }
                        });
                    }
            };
        }])
        .directive('eezyDashboardChart', ['$http', '$rootScope', function($http, $rootScope){
            return{
                restrict : 'A',
                scope : {
                    reportChartDataUrl : '@',
                    typeElement : '@?',
                    filter:"=",
                    type: '@',
                    chart: '&',
                    secondChart: '@?',
                    fill: '@?'
                },
                link :
                    function(scope, element, attrs){
                        scope.$watchGroup(['filter.sites', 'filter.range'],function(newValue, oldValue){
                            var filter = angular.copy(scope.filter);

                            filter.type = scope.type;

                            filter.typeElement = scope.typeElement;

                            if(angular.isDefined(scope.secondChart)){
                                filter.secondChart = scope.secondChart;
                            }

                            if(angular.isDefined(scope.fill)){
                                filter.fill = false;
                            }

                            if (angular.isDefined(scope.filter) && angular.isDefined(scope.filter.sites) && angular.isDefined(scope.filter.range) && scope.filter.range.length > 0) {
                                $http({
                                    method: 'POST',
                                    url: scope.reportChartDataUrl,
                                    data: filter
                                }).success(function(data){
                                    if(!angular.isDefined(data.labels)){
                                        moment.locale($rootScope.currentLanguage);
                                        data.labels = moment.months();
                                    }

                                    //check if the chart is already drawn in the canvas
                                    if(angular.isDefined(scope.chart.chart)){
                                        //delete the chart for redraw it
                                        scope.chart.destroy();
                                    }

                                    scope.chart = new Chart(element,{
                                                            type: scope.type,
                                                            data: data,
                                                        });
                                });
                            }
                        });
                    }
            };
        }])
        .directive('eezyDashboardRanking', ['$http', function($http){
            return{
                restrict : 'E',
                scope : {
                    reportRankingDataUrl : '@',
                    typeElement : '@?',
                    cssClass : "@",
                    filter: "=",
                    label: '@',
                    limit: '@',
                    typeRank: "@"
                },
                templateUrl : '/partials/report/directive/dashboardranking.tpl.html',
                link :
                    function(scope, element, attrs){

                        scope.$watchGroup(['filter.sites', 'filter.range'],function(newValue, oldValue){
                            var filter = angular.copy(scope.filter);
                            filter.typeElement = scope.typeElement;

                            filter.limit = scope.limit;

                            if (angular.isDefined(scope.filter) && angular.isDefined(scope.filter.sites) && angular.isDefined(scope.filter.range) && scope.filter.range.length > 0) {
                                $http({
                                    method: 'POST',
                                    url: scope.reportRankingDataUrl,
                                    data: filter
                                }).success(function(data){
                                    scope.ranking = data;
                                });
                            }
                        });
                    }
            };
        }]);
    });


})(requirejs);
