(function (requirejs) {
'use strict';


define('../lib/eezymanager-core/javascripts/admin/icon',['angular'],
    function(angular) {

        angular.module('eezymanager.core.admin.icon', ['ngRoute', 'pascalprecht.translate'])


        // Routes
        .config(['$routeProvider', function($routeProvider) {
            $routeProvider.when('/admin/icon', {
                templateUrl: '/eezymanager/core/partials/admin/icon/list.tpl.html',
                controller: ['$scope', '$http', function($scope, $http) {

                    $scope.refresh = function() {
                        $http.get('/icon/list')
                            .success(function(response) {
                                $scope.icons = response;
                            });
                    };

                    $scope.refresh();
                }]
            });
        }]);

    });
})(requirejs);

