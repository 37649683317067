(function(requirejs) {
    'use strict';

    define('planning/planning',['angular', 'fullcalendar'],

        function(angular) {
            var showController = function($scope) {};
            showController.$inject = ['$scope'];

            angular.module('eezycleanApp.planning', ['ngRoute', 'pascalprecht.translate'])

            .controller('eezycleanApp.planning.filterCtrl', ['$scope', '$location', function($scope, $location) {

                $scope.$watch('filters', function(newVal, oldVal) {

                    if (angular.isDefined(newVal['employee.siteEmployees.site.id']) && newVal['employee.siteEmployees.site.id'].value !== null && newVal['employee.siteEmployees.site.id'].value !== '' &&
                        angular.isDefined(newVal.serviceId) && newVal.serviceId !== null && newVal.serviceId.value !== '') {
                        $location.path('/planning/planning/' + newVal['employee.siteEmployees.site.id'].value + '/' + newVal.serviceId.value);
                    }
                }, true);

            }])

            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/planning/planning', {
                    templateUrl: '/partials/planning/planning/planning.tpl.html',
                    controller: ['$scope', '$http', function($scope, $http) {
                        $scope.order = [
                            [2, 'asc']
                        ];

                        $http({
                            method: 'GET',
                            url: '/planning/planning/sites',
                        }).success(function(sites) {
                            $scope.bean = sites;
                            if (sites.length == 1) {
                                $scope.siteId = sites[0].id;
                            }
                        });
                    }]
                });
                $routeProvider.when('/planning/planning/:siteId/:serviceId', {
                    templateUrl: '/partials/planning/planning/planning.tpl.html',
                    controller: ['$scope', '$routeParams', '$http', function($scope, $routeParams, $http) {
                        $scope.order = [
                            [2, 'asc']
                        ];
                        $scope.serviceId = parseInt($routeParams.serviceId, 10);
                        $scope.siteId = parseInt($routeParams.siteId, 10);

                        $http({
                            method: 'GET',
                            url: '/planning/planning/sites',
                        }).success(function(sites) {
                            $scope.bean = sites;
                        });


                    }]
                });
            }])

            .directive('eezyFullCalendar', ['Language', '$http', '$routeParams', function(Language, $http, $routeParams) {
                return {
                    restrict: 'E',
                    template: '<div></div>',
                    scope: {
                        agentId: '=',
                        date: '=',
                        serviceId: '=',
                        creneauId: '=',
                        calendar: '=',
                        urlSave: '='
                    },
                    link: {
                        pre: function(scope, element, attr, controller) {},
                        post: function(scope, element, attr, controller) {
                            var calendar = element.find('> div').first();
                            if (angular.isDefined($routeParams.serviceId)) {
                                scope.serviceId = $routeParams.serviceId;
                            }
                            scope.$evalAsync(function() {
                                scope.calendar = calendar;
                                // On appel uniquement si le service est renseigné
                                scope.$watch('serviceId', function() {
                                    calendar.fullCalendar('refetchEvents');
                                    // Refresh toutes les 30 secondes
                                    /*setInterval(function() {
                                        calendar.fullCalendar('refetchEvents');
                                    }, 1000 * 30);*/
                                });

                                calendar.fullCalendar({
                                    lang: Language.currentLanguage(),
                                    timeFormat: 'H:mm',
                                    timezone: false,
                                    displayEventEnd: true,

                                    buttonIcons: false, // show the prev/next text
                                    weekNumbers: true,
                                    eventLimit: true, // allow "more" link when too many events

                                    editable: true,
                                    droppable: true,
                                    drop: function(date, event, ui) {
                                        scope.urlSave = "/planning/creneau/create";
                                        // On ne garde pas l'evenement dans le calendrier. On l'affichera lorsqu'il sera en bdd
                                        calendar.fullCalendar('removeEvents', function(event) {
                                            if (event.creneauId === undefined) {
                                                return true;
                                            }
                                        });

                                        //var template = $templateRequest("/partials/planning/planning/creneaux.tpl.html");

                                        /*template.then(function(template) {
                                                                            return $compile(template)(response);
                                                                            });
                                          */
                                        var l_agent = $(this).data('event').agent;
                                        // Titre
                                        angular.element('eezy-creneaux').find('.modal-title').html("<center>" + date.format('DD/MM/YYYY') + "<br>" + l_agent.employee.fullName + "</center>");
                                        // Agent
                                        scope.agentId = l_agent.id;
                                        scope.creneauId = undefined;
                                        scope.date = date;
                                        scope.$apply();
                                        // Affichage de la popup
                                        angular.element('eezy-creneaux').find('.modal.creneaux').modal('show');
                                    },

                                    header: {
                                        left: 'prev,next,today',
                                        center: 'title',
                                        right: 'month,agendaWeek,agendaDay'
                                    },

                                 dayClick: function(date, jsEvent, view) {},
                                    eventSources: [{
                                        events: function(start, end, timezone, callback) {
                                            if (scope.serviceId) {
                                                // Recup du planning pour la periode en cours
                                                $http.get('/planning/planning/' + scope.serviceId + '/' + start + '/' + end).success(function(response) {
                                                    var events = [];
                                                    $(response).each(function() {
                                                        events.push({
                                                            title: $(this).attr('absence') ? $(this).attr('agentRemplacantFullName') : $(this).attr('agentFullName'),
                                                            start: $(this).attr('beginTime'),
                                                            end: $(this).attr('endTime'),
                                                            creneauId: $(this).attr('id'),
                                                            color: $(this).attr('absence') ? 'orange' : '',
                                                            agentFullName: $(this).attr('agentFullName'),
                                                            absence: $(this).attr('absence'),
                                                            motif: $(this).attr('motifAbsence')
                                                        });
                                                    });
                                                    callback(events);
                                                });
                                            }
                                        }
                                    }],
                                    eventClick: function(calEvent, jsEvent, view) {
                                        // Titre
                                        angular.element('eezy-creneaux').find('.modal-title').html("<center>" + calEvent.start.format('DD/MM/YYYY') + "<br>" + calEvent.agentFullName + "</center>");
                                        scope.creneauId = calEvent.creneauId;
                                        scope.date = calEvent.start;
                                        scope.urlSave = "/planning/creneau/" + calEvent.creneauId;
                                        scope.$apply();
                                        // Affichage de la popup
                                        angular.element('eezy-creneaux').find('.modal.creneaux').modal('show');
                                    },
                                    // Déplacement d'un créneau
                                    eventDragStop: function(event, jsEvent, ui, view) {
                                        // On récupère le formulaire avec le créneau
                                        $http.get('/planning/planning/' + event.creneauId).success(function(response) {
                                            response.beginTime = event.start;
                                            response.endTime = event.end;
                                            // Mise à jour du créneau
                                            $http({
                                                url: '/planning/creneau/' + event.creneauId,
                                                method: 'POST',
                                                data: response
                                            });
                                        });
                                    },
                                    eventRender: function(event, element) {
                                        var renduHtml = "<div class='creneau'><div class='creneau-time'>" + element.find('.fc-time').text() + "</div>";

                                        if (event.absence) {
                                            renduHtml += "<div class='creneau-absent'>" + event.agentFullName + "</div>";
                                            renduHtml += "<div class='creneau-motif'>" + event.motif + "</div>";
                                        }
                                        renduHtml += event.title + "</div>";

                                       //element.find('.fc-event-content').html(renduHtml);
                                       element.find('.fc-content').html(renduHtml);

                                        //element.find(".fc-event-time").after($("<span class='fc-event-icons'></span>").html("Whatever you want the content of the span to be"));
                                    }
                                });
                            });
                        }
                    }
                };
            }])

            .directive('eezyDraggable', function() {
                return {
                    restrict: 'A',
                    scope: {
                        model: '=',
                        text: '@'
                    },
                    link: function(scope, element, attr) {
                        var drag = element;
                        drag.data('event', {
                            title: scope.model.employee.fullName,
                            stick: false,
                            agent: scope.model
                        });
                        drag.draggable({
                            zIndex: 999,
                            revert: true,
                            revertDuration: 0,
                            scroll: false,
                            cursor: 'pointer',
                            helper: function(event, ui) {
                                return $("<div></div>").append($(this).clone().html()).addClass('dragged_object').appendTo("body");
                            }
                        });
                    }
                };
            })

            .directive('eezyCreneaux', ['$templateRequest', '$compile', '$http', '$routeParams', function($templateRequest, $compile, $http, $routeParams) {
                return {
                    restrict: 'E',
                    templateUrl: '/partials/planning/planning/creneaux.tpl.html',
                    scope: {
                        agentId: '=',
                        serviceId: '=',
                        siteId: '=',
                        date: '=',
                        creneauId: '=',
                        calendar: '=',
                        urlSave: '='
                    },
                    link: function(scope, element, attrs) {

                        scope.preSubmit = function(data) {
                            data.beginTime = new Date(data.beginTime.getTime() - (new Date(data.beginTime.getTime())).getTimezoneOffset() * 60 * 1000);
                            data.endTime = new Date(data.endTime.getTime() - (new Date(data.endTime.getTime())).getTimezoneOffset() * 60 * 1000);
                            return data;
                        };

                        $(element).on('show.bs.modal', function() {
                            scope.$apply(function() {
                                scope.$$childHead.errors = undefined;
                                if (scope.creneauId === undefined) {
                                    // Création d'un formulaire créneau
                                    scope.$$childHead.form.creneauId = '';
                                    scope.$$childHead.form.agentId = scope.agentId;
                                    scope.$$childHead.form.serviceId = scope.serviceId;
                                    var dateTimeBegin = new Date(scope.date);
                                    // Début 8h
                                    dateTimeBegin.setHours(8);
                                    scope.$$childHead.form.beginTime = dateTimeBegin;
                                    var dateTimeEnd = new Date(scope.date);
                                    // Fin 17h
                                    dateTimeEnd.setHours(17);
                                    scope.$$childHead.form.endTime = dateTimeEnd;
                                } else {
                                    // Edition d'un créneau
                                    scope.$$childHead.form.id = scope.creneauId;
                                    $http.get('/planning/planning/' + scope.creneauId).success(function(response) {
                                        //                                    var newScope = scope.$$childHead.$new(); newScope.form=response;
                                        scope.$$childHead.form = response;
                                        //                                    scope.$apply();
                                    });
                                }
                                // On vide l'input d'aide à la saisie du remplacant
                                element.find('input').each(function() {
                                    if ($(this).attr('ng-model') === 'searchStr') {
                                        $(this).val("");
                                    }
                                });
                            });
                        });

                        $(element).on('hidden.bs.modal', function() {
                            // Réinitialisation du formulaire
                            $http.get('/planning/creneau/new').success(function(response) {
                                scope.$$childHead.form = response;
                            });
                        });



                        scope.onSuccess = function(response) {
                            // Rafraichisxement du calendrier
                            scope.calendar.fullCalendar('refetchEvents');
                            element.find('.modal').modal('hide');
                        };

                    }
                };
            }])


            ;
        }
    );

})(requirejs);
