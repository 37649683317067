(function (requirejs) {
'use strict';

    define('../lib/eezymanager-ged/javascripts/directive/autofocus',['angular'], function(angular) {

        angular.module('eezymanager.ged.directive.autofocus', [])
        .directive('autofocus', function () {
            return {
                restrict: 'A',
                link: function(scope, element) {
                    element.focus();
                }
            };
         });
    });
})(requirejs);

