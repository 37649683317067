(function (requirejs) {
'use strict';

    define('../lib/eezymanager-common/javascripts/datatable',['angular'], function(angular) {

        /* Directives */

        angular.module('eezymanager.common.datatable', ['eezymanager.common.services', 'ngResource', 'datatables.directive'])
            .directive('eezyFilter', ['$routeParams', function($routeParams) {
                return {
                    restrict: 'E',
                    scope: {
                        column: '@',
                        label: '@?',
                        defaultValue: '=?',
                        paramId:'@?',
                        multiple: '@?'
                    },
                    templateUrl: '/eezymanager/common/partials/datatable/filter.tpl.html',
                    transclude: true,
                    link: {
                        pre: function(scope, element, attrs, ctrl, transclude) {
                                scope.$watch('defaultValue', function(newVal, oldVal) {
                                    if (angular.isDefined(newVal)) {
                                        scope.$parent.filters[scope.column] = {
                                            multiple: angular.isDefined(scope.multiple),
                                            value: scope.defaultValue
                                        };
                                        scope.filter = newVal;
                                    }
                                });

                                scope.$parent.$watch('filters["'+scope.column+'"].value', function(newVal){
                                    scope.filter = newVal;
                                });

                                if(angular.isDefined(scope.defaultValue)){
                                    scope.$parent.filters[scope.column] = {
                                        multiple: angular.isDefined(scope.multiple),
                                        value: scope.defaultValue
                                    };
                                    scope.filter = scope.defaultValue;
                                }
                                else if((angular.isDefined(angular.isDefined(scope.paramId)) && scope.paramId)){
                                    scope.$parent.filters[scope.column] = {
                                        multiple: angular.isDefined(scope.multiple),
                                        value: $routeParams.id
                                    };
                                    scope.filter = $routeParams.id;
                                }

                                transclude(scope, function(clone) {
                                element.children('.form-group').append(clone);
                            });
                        },
                        post: function(scope, element, attrs) {

                            var column = scope.column + ':name';

                            scope.$watch('filter', function(newValue, oldValue) {

                                var api = scope.$parent.api;

                                if (angular.isDefined(api) && angular.isDefined(api.column(column))) {
                                    /*
                                    if (newValue === null || !angular.isDefined(newValue)) {
                                        newValue = '';
                                    }
                                    */
                                    scope.$parent.filters[scope.column] = {
                                        multiple: angular.isDefined(scope.multiple),
                                        value: newValue
                                    };
                                }
                            });
                        }
                    }

                };
            }])
            .directive('eezyRangeFilter', [function() {
                return {
                    restrict: 'E',
                    scope: {
                        column: '@',
                        label: '@?'
                    },
                    templateUrl: '/eezymanager/common/partials/datatable/filter.tpl.html',
                    transclude: true,
                    link: {
                        pre: function(scope, element, attrs, ctrl, transclude) {
                            scope.range = [];
                            if (!angular.isDefined(attrs.type)) {
                                scope.type = 'range';
                            } else {
                                if (attrs.type == 'date') {
                                    scope.type = 'dateRange';
                                } else {
                                    scope.type = attrs.type;
                                }
                            }
                            transclude(scope, function(clone) {
                                element.children('.form-group').append(clone);
                            });
                        },
                        post: function(scope, element, attrs) {

                            var column = scope.column;
                            var value = {};
                            scope.$parent.rangeFilters[column] = value;

                            scope.$watchCollection('range', function(newValue, oldValue) {
                                var api = scope.$parent.api;
                                if (angular.isDefined(api) && angular.isDefined(api.column(column))) {
                                    if (newValue === null || !angular.isDefined(newValue)) {
                                        delete value[scope.type];
                                    } else {
                                        value[scope.type] = newValue;
                                    }
                                }
                            });
                        }
                    }

                };
            }])
            .directive('eezyColumn', ['$templateRequest', function($templateRequest) {
                return {
                    restrict: 'E',
                    scope: false,
                    transclude: true,
                    controller: angular.noop,
                    link: function(scope, element, attrs, ctrl, transclude) {
                        var hasTemplate = false;
                        var hasTransclude = false;
                        var template;
                        if (angular.isDefined(attrs.template)) {
                            hasTemplate = true;
                            template = $templateRequest(attrs.template, true);
                        } else {
                            transclude(function(clone) {
                                if ( angular.isDefined(clone) && clone.length > 0) {
                                    hasTransclude = true;
                                }
                            });
                        }
                        scope.dtColumns.push({
                            title: attrs.title,
                            data: angular.isDefined(attrs.data) ? (hasTemplate || hasTransclude ? 'rendered_' + attrs.data : attrs.data) : 'rendered',
                            defaultContent: angular.isDefined(attrs.defaultContent) ? attrs.defaultContent : (hasTemplate || hasTransclude ? '' : ''),
                            template: hasTemplate ? template : undefined,
                            transclude: hasTransclude ? transclude : undefined,
                            name: angular.isDefined(attrs.name) ? attrs.name : attrs.data,
                            searchable: attrs.searchable === 'true',
                            orderable: attrs.orderable === 'true',
                            visible: attrs.visible === 'false' ? false : true,
                            type: angular.isDefined(attrs.type) ? attrs.type : undefined,
                            dateFormat: angular.isDefined(attrs.dateFormat) ? attrs.dateFormat : undefined
                        });
                    }
                };
            }])
            .directive('eezyDatatable', ['Session', '$q', '$compile', '$timeout', '$http', '$resource', '$location', '$filter', 'EezyUrl', 'DTInstances', '$translate', '$routeParams', '$templateRequest',
                function(Session, $q, $compile, $timeout, $http, $resource, $location, $filter, EezyUrl, DTInstances, $translate, $routeParams, $templateRequest) {

                var lastParams;
                var childScopes = [];
                var filteredItemNumber;

                return {
                    restrict: 'E',
                    scope: {
                        url: '@',
                        onRowClick: '=',
                        rowHref: '@',
                        order: '=?',
                        orderBy: '@?',
                        enablePagination: '@',
                        outputFilters: '=?',
                        filters: '=?',
                        scrollHeight: '@',
                        exportable: '@?',
                        selectable: '=?',
                        selectableCompare: '@?',
                        totalItem: '=?',
                        api: '=?'
                    },
                    transclude: true,
                    templateUrl: '/eezymanager/common/partials/datatable.tpl.html',
                    link: {
                        pre: function(scope, element, attrs, ctrl, transclude) {
                            scope.dtColumns = [];
                            if(angular.isUndefined(scope.filters)){
                                scope.filters = {};
                            }

                            scope.rangeFilters = {};
                            scope.session = Session;

                            var deferredApi = $q.defer();
                            scope.apiPromise = deferredApi.promise;

                            scope.dtInstanceCallback = function (dtInstance) {
                                element.find('table').parent().addClass('table-responsive');
                                scope.dtInstance = dtInstance;
                                scope.api = dtInstance.DataTable;
                                
                                // Sort after init to prevent bug
                                scope.api.order(scope.order);

                                var renderPromises = [];
                                angular.forEach(childScopes, function(childScope) {
                                    renderPromises.push($q(function(resolve, reject) {
                                        childScope.$evalAsync(function() {
                                            resolve();
                                        });
                                    }));
                                });

                                deferredApi.resolve(scope.api);
                            };

                            if(angular.isDefined(scope.selectable)){

                                scope.selectedRow = scope.selectable;

                                scope.dtColumns.push({
                                     title: '<span class="rendered_head_check_all">title</span>',
                                     data: 'rendered',
                                     defaultContent: '',
                                     template: $templateRequest('/eezymanager/common/partials/datatable/checkbox.tpl.html', true),
                                     name: undefined,
                                     searchable: false,
                                     orderable: false,
                                     visible: true,
                                     type: undefined,
                                     dateFormat: undefined,
                                     headerTemplate: '<input type="checkbox" ng-model="selectAll" ng-click="toggleAll()" class="rendered_head_check_all">'
                                 });
                            }

                            transclude(scope, function(clone, scope) {
                                element.children('form').append(clone.not('eezy-column'));
                            });

                            scope.$watchCollection('filters', function(newValue) {

                                scope.outputFilters = {};
                                angular.forEach(newValue, function(filter, key) {
                                    scope.outputFilters[key] = filter.value;
                                });

                                //refresh datatables with filters
                                if(angular.isDefined(scope.api)){
                                    scope.api.draw();
                                }

                            });

                            scope.$watch('rangeFilters', function(newValue) {
                                //refresh datatables with filters
                                if(angular.isDefined(scope.api)){
                                    scope.api.draw();
                                }
                            }, true);

                            scope.exportData = function() {
                                $http({
                                    method: 'POST',
                                    url: EezyUrl.generate(scope.url + '/export', $routeParams),
                                    data: lastParams
                                });
                            };
                        },
                        post: function(scope, element, attrs) {
                            scope.dtOptions = {
                                order: [],
                                ajax: {
                                    url: EezyUrl.generate(scope.url + '/search', $routeParams),
                                    type: 'POST'
                                },
                                sAjaxDataProp: 'data',
                                serverSide: true,
                                paginationType: 'full_numbers',
                                paging: scope.enablePagination === 'false' ? false : true,
                                 language: {
                                        search: "_INPUT_",
                                        searchPlaceholder: $translate.instant("dt.sSearch")
                                    },
                                scrollY: angular.isDefined(scope.scrollHeight) ? scope.scrollHeight : "",
                                rowCallback: function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {

                                    if (scope.rowHref)  {
                                        angular.element(nRow)
                                            .unbind('click')
                                            .bind('click', function(event) {
                                                scope.$apply(function() {
                                                    var url = EezyUrl.generate(scope.rowHref, aData);
                                                    $location.path(url);
                                                });
                                            });
                                    } else if (scope.onRowClick) {
                                        angular.element(nRow)
                                            .unbind('click')
                                            .bind('click', function(event) {
                                                scope.onRowClick(event, aData, iDisplayIndex, iDisplayIndexFull, nRow);
                                            });
                                    }

                                    angular.forEach(aData._rendered, function(value, index) {
                                        angular.element(nRow)
                                            .find('.rendered_' + index)
                                            .replaceWith(value);
                                    });

                                    delete aData._rendered;
                                },
                                headerCallback: function(thead, data, start, end, display){

                                    if(angular.isDefined(data[0]) && angular.isDefined(data[0].templateHeader)){
                                        angular.element(thead)
                                            .find('.rendered_head_check_all')
                                            .replaceWith(data[0].templateHeader);
                                    }

                                    angular.forEach(data, function(item){
                                        delete item.templateHeader;
                                    });
                                },
                                fnServerData: function(sSource, aoData, fnCallback, oSettings) {

                                    var params = {};
                                    angular.forEach(aoData, function(val) {
                                        params[val.name] = val.value;
                                    });

                                    var cols = {};
                                    angular.forEach(params.columns, function(value) {
                                        if (value.data.match(/^rendered_/)) {
                                            value.data = value.data.substring(9);
                                        }
                                        cols[value.name] = value;
                                    });

                                    angular.forEach(scope.rangeFilters, function(value, colName) {
                                        var col = cols[colName];

                                        if (angular.isDefined(col)) {
                                            angular.forEach(value, function(range, key) {
                                                col.search[key] = range;
                                            });
                                        }
                                    });

                                    angular.forEach(scope.filters, function(filter, colName) {
                                        var col = cols[colName];

                                        if (angular.isDefined(col) && filter.multiple) {
                                            col.search.values = filter.value;
                                        } else if (angular.isDefined(col)) {
                                            col.search.value = filter.value;
                                        }
                                    });


                                    if (angular.isUndefined(params.order) || params.order.length === 0) {
                                        if (angular.isDefined(scope.order) && scope.order.length > 0) {
                                            params.order = [];
                                            angular.forEach(scope.order, function(value) {
                                                params.order.push({
                                                    'column': value[0],
                                                    'dir': angular.isDefined(value[1]) ? value[1] : 'asc'
                                                });
                                            });
                                        } else if (angular.isDefined(scope.orderBy) && scope.orderBy !== null) {
                                            var i = 0;
                                            var found = false;
                                            var order = scope.orderBy.split(' ');
                                            angular.forEach(params.columns, function(value) {
                                                if (value.data === order[0]) {
                                                    found = i;
                                                }
                                                i ++;
                                            });

                                            if (found) {
                                                params.order = [{
                                                    'column': found,
                                                    'dir': angular.isDefined(order[1]) ? order[1] : 'asc'
                                                }];
                                            }
                                        }
                                    }

                                    lastParams = params;

                                    $http({
                                        url: oSettings.ajax.url,
                                        method: oSettings.ajax.type,
                                        data: params
                                    }).success(function(response) {
                                        if(angular.isDefined(scope.selectable)){

                                            var selectableSearch = false;

                                            angular.forEach(params.columns, function(column){
                                                if(angular.isDefined(column.search .values) && column.search.values.length > 0 && !selectableSearch) {
                                                    selectableSearch = true;
                                                }
                                            });

                                            filteredItemNumber = response.recordsFiltered;
                                            scope.totalItem = (selectableSearch) ? response.recordsFiltered : response.recordsTotal;
                                            scope.selectedRow = [];

                                            if(filteredItemNumber !== scope.selectable.length){
                                                scope.selectAll = false;
                                            }
                                            else {
                                                scope.selectAll = true;
                                            }
                                        }

                                        var promises = [];

                                        angular.forEach(response.data, function(row, index) {

                                            if(angular.isDefined(scope.selectable)){
                                                if(angular.isDefined(checkDataCorrespondence(row, scope.selectable))){
                                                    scope.selectedRow[index] = true;
                                                }
                                                else {
                                                    scope.selectedRow[index] = false;
                                                }
                                            }

                                            row._rendered = [];
                                            angular.forEach(scope.dtColumns, function(col) {

                                                if (col.type === 'date') {
                                                    if (angular.isDefined(row[col.data]) && row[col.data] !== null) {
                                                        row[col.data] = $filter('date')(new Date(row[col.data]), col.dateFormat);
                                                    }
                                                } else if (col.type === 'time') {
                                                    if (angular.isDefined(row[col.data]) && row[col.data] !== null && (typeof(col.data) != 'object' || col.data.constructor != Date)) {
                                                        var val = new Date(row[col.data] + (new Date(row[col.data])).getTimezoneOffset() * 60 * 1000);
                                                        row[col.data] = $filter('date')(val, col.dateFormat);
                                                    }
                                                }
                                                var newScope;

                                                if (angular.isDefined(col.template)) {
                                                    newScope = scope.$new();
                                                    childScopes.push(newScope);
                                                    newScope.data = row;
                                                    newScope.index = index;
                                                    promises.push(
                                                        col.template.then(function(template) {
                                                            return $compile(template);
                                                        }).then(function(templateFn) {
                                                            var rendered = templateFn(newScope);
                                                            var i = row._rendered.push(rendered) - 1;
                                                            row[col.data] = '<span class="rendered_' + i + '">' + i + '</span>';
                                                        }));
                                                }
                                                if (angular.isDefined(col.transclude)) {
                                                    newScope = scope.$parent.$new();
                                                    childScopes.push(newScope);
                                                    newScope.data = row;
                                                    newScope.index = index;
                                                    promises.push(
                                                        $q(function(resolve, reject) {
                                                            col.transclude(newScope, function(rendered) {
                                                                resolve(rendered);
                                                            });
                                                        }).then(function(rendered) {
                                                            var i = row._rendered.push(rendered) - 1;
                                                            row[col.data] = '<span class="rendered_' + i + '">' + i + '</span>';
                                                        }));
                                                }

                                                if(angular.isDefined(col.headerTemplate)){
                                                    scope.data = row;
                                                    scope.data.templateHeader = $compile(col.headerTemplate)(scope);
                                                }
                                            });
                                        });

                                        $q.all(promises).then(function() {
                                            if (!angular.isDefined(response.data)) {
                                                response.data = [];
                                            }
                                            fnCallback(response);
                                        });
                                    });
                                },
                                drawCallback: function(settings) {
                                    var renderPromises = [];
                                    angular.forEach(childScopes, function(childScope) {
                                        renderPromises.push($q(function(resolve, reject) {
                                            childScope.$evalAsync(function() {
                                                resolve();
                                            });
                                        }));
                                    });

                                    $q.all(renderPromises).then(function() {
                                        $timeout(function() {
                                            scope.apiPromise.then(function(api) {
                                                api.columns.adjust();
                                            });
                                        });
                                    });
                                }
                            };

                            scope.toggleAll = function(){

                                if(scope.selectAll){
                                    //Permet d'avoir tout les items du datatable
                                    var allItemsParam = angular.copy(lastParams);
                                    allItemsParam.length = 0;
                                    allItemsParam.start = 0;

                                    $http({
                                        method: 'POST',
                                        url: EezyUrl.generate(scope.url + '/search'),
                                        data: allItemsParam
                                    }).success(function(response){
                                        filteredItemNumber = response.data.length;
                                        scope.selectable = response.data;
                                    });

                                    for(var i = 0; i < scope.selectedRow.length; i++){
                                        scope.selectedRow[i] = true;
                                    }
                                }
                                else {
                                    scope.selectable = [];
                                    for(var j = 0; j < scope.selectedRow.length; j++){
                                            scope.selectedRow[j] = false;
                                    }
                                }
                            };

                            scope.toggleOne = function(checkBoxIndex){
                                var data = scope.api.rows(checkBoxIndex).data();
                                var indexItem = checkDataCorrespondence(data[0],scope.selectable);

                                //Add or delete element in the table
                                if(angular.isUndefined(indexItem)){
                                    scope.selectable.push(data[0]);
                                }
                                else {
                                    scope.selectable.splice(indexItem, 1);
                                }

                                if(filteredItemNumber !== scope.selectable.length){
                                    scope.selectAll = false;
                                }
                                else {
                                    scope.selectAll = true;
                                }
                            };

                            function checkDataCorrespondence(data, array){
                                var returnIndex;

                                angular.forEach(array, function(item, index){
                                    if(item[scope.selectableCompare] === data[scope.selectableCompare]){
                                        returnIndex = index;
                                    }
                                    else if (item === data[scope.selectableCompare]){
                                        returnIndex = index;
                                    }
                                });

                                return returnIndex;
                            }
                        }
                    }
                };
            }]);
    });
})(requirejs);

