(function (requirejs) {
'use strict';

    define('controllers',[],function() {

    /* Controllers */

    var controllers = {};

    controllers.MyCtrl1 = function() {};
    controllers.MyCtrl1.$inject = [];

    controllers.MyCtrl2 = function() {};
    controllers.MyCtrl2.$inject = [];

    return controllers;

    });
})(requirejs);

