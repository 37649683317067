(function (requirejs) {
'use strict';


    define('configuration/site/typePartie',['angular'],
        function(angular) {

            angular.module('eezycleanApp.configuration.site.typePartie', ['ngRoute', 'pascalprecht.translate'])


            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/configuration/site/type-lieu/:typeLieuId/type-partie/new', {
                    templateUrl: '/partials/configuration/site/typePartie/edit.tpl.html',
                    controller: ['$scope', '$routeParams', function($scope, $routeParams) {
                        $scope.title = 'EE.Core.Configuration.Site.TypePartie.Create.Title';
                        $scope.mode = 'create';
                        $scope.url = '/configuration/site/type-partie';
                        $scope.init = {
                            typeLieuId: $routeParams.typeLieuId
                        };

                        $scope.onSuccessRedirect = '/configuration/site/type-lieu/' + $routeParams.typeLieuId + '/type-partie/:id';
                    }]
                });
                $routeProvider.when('/configuration/site/type-lieu/:typeLieuId/type-partie/:id', {
                    templateUrl: '/partials/configuration/site/typePartie/edit.tpl.html',
                    controller: ['$scope', '$routeParams', function($scope, $routeParams) {
                        $scope.title = 'EE.Core.Configuration.Site.TypePartie.Edit.Title';
                        $scope.mode = 'edit';
                        $scope.url = '/configuration/site/type-partie/:id';
                        $scope.init = {
                        };

                        $scope.onSuccessRedirect = '/configuration/site/type-lieu/' + $routeParams.typeLieuId;
                    }]
                });
            }]);

        }
    );
})(requirejs);

