(function (requirejs) {
'use strict';

    define('../lib/eezymanager-common/javascripts/controllers',['angular'], function(angular) {

        /* Common controllers */

        angular.module('eezymanager.common.controllers', [])
            .controller('SideBarMenuController', ['$scope', '$http', function($scope, $http) {

                $http.get("/menu")
                    .success(function(response) {
                        $scope.menu = response;

                    });

            }]);

        /* Controllers */

        var controllers = {};

        controllers.MyCtrl1 = function() {};
        controllers.MyCtrl1.$inject = [];

        controllers.MyCtrl2 = function() {};
        controllers.MyCtrl2.$inject = [];

        return controllers;
    });
})(requirejs);

