(function (requirejs) {
'use strict';

    define('declaration/declaration',['angular'],
        function(angular) {

            var showController = function($scope) {

            };
            showController.$inject = ['$scope'];


            angular.module('eezycleanApp.declaration.declaration', ['ngRoute', 'pascalprecht.translate'])

            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/declaration/declaration', {
                    templateUrl: '/partials/declaration/declaration/list.tpl.html',
                    controller: ['$scope', 'Session', function($scope, Session) {
                        $scope.order = [[ Session.hasPermission('ADMIN') ? 3 : 2, 'desc' ]];
                    }]
                });
                $routeProvider.when('/declaration/declaration/:id/view', {
                    templateUrl: '/partials/declaration/declaration/view.tpl.html',
                    controller: showController
                });
            }]);

        }
    );
})(requirejs);

