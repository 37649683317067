(function (requirejs) {
'use strict';

    define('configuration/site',['angular',
        './site/service',
        './site/typeLieu',
        './site/typePartie',
        './site/typeElement',
        './site/typeCritereEvaluation'
        ], function(angular) {

        angular.module('eezycleanApp.configuration.site', [
            'eezycleanApp.configuration.site.service',
            'eezycleanApp.configuration.site.typeLieu',
            'eezycleanApp.configuration.site.typePartie',
            'eezycleanApp.configuration.site.typeElement',
            'eezycleanApp.configuration.site.typeCritereEvaluation'
        ]);


    });
})(requirejs);

