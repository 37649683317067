(function (requirejs) {
'use strict';


    define('configuration/typeTheme',['angular'],
        function(angular) {

            angular.module('eezycleanApp.configuration.typeTheme', ['ngRoute', 'pascalprecht.translate'])


            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/configuration/type-theme', {
                    templateUrl: '/partials/configuration/typeTheme/list.tpl.html'
                });
                $routeProvider.when('/configuration/type-theme/new', {
                    templateUrl: '/partials/configuration/typeTheme/create.tpl.html'
                });
                $routeProvider.when('/configuration/type-theme/:id', {
                    templateUrl: '/partials/configuration/typeTheme/edit.tpl.html'
                });
            }]);

        }
    );
})(requirejs);

