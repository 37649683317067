(function (requirejs) {
'use strict';

    define('../lib/eezymanager-ged/javascripts/directive/deletenode',['angular'], function(angular) {

        angular.module('eezymanager.ged.directive.eezyDeleteNode', [])
        .directive('eezyDeleteNode', function () {
            return {
                restrict: 'E',
                templateUrl: '/eezymanager/ged/partials/ged/directives/modal/deletenode.tpl.html',
                scope:{
                    folder:'@',
                    node:'@'
                },
                link: function(scope, element) {
                    scope.showDeleteNodeModal = function() {
                        scope.node = angular.fromJson(scope.node);
                        scope.folder = (scope.folder === 'true');
                        element.find('.modal').modal('show');
                    };

                    scope.deleteNode = function(){
                        element.find('.modal')
                        .on('hidden.bs.modal', function() {
                            scope.$parent.deleteDocument(scope.node);
                        })
                        .modal('hide');
                    };

                    scope.closeModal = function(){
                        element.find('.modal').modal('hide');
                    };
                }
            };
         });
    });
})(requirejs);

