(function (requirejs) {
'use strict';


define('../lib/eezymanager-ged/javascripts/ged',['angular'],
    function(angular) {

        angular.module('eezymanager.ged.ged', ['ngRoute', 'pascalprecht.translate', 'ngDraggable'])

        // Routes
        .config(['$routeProvider', function($routeProvider) {
            $routeProvider
            .when('/ged', {
                templateUrl: '/eezymanager/ged/partials/ged/ged.tpl.html'
            });
        }])
        .controller('DocumentDragCtrl', ['$scope', function($scope) {
        }])

        ;
    });
})(requirejs);

