(function (requirejs) {
'use strict';

define('../lib/eezymanager-core/javascripts/admin/tablet',['angular'],
    function(angular) {

        angular.module('eezymanager.core.admin.tablet', ['ngRoute', 'pascalprecht.translate'])


        // Routes
        .config(['$routeProvider', function($routeProvider) {
            $routeProvider.when('/admin/tablet', {
                templateUrl: '/eezymanager/core/partials/admin/tablet/list.tpl.html'
            });
            $routeProvider.when('/admin/tablet/new', {
                templateUrl: '/eezymanager/core/partials/admin/tablet/create.tpl.html'
            });
            $routeProvider.when('/admin/tablet/:id', {
                templateUrl: '/eezymanager/core/partials/admin/tablet/edit.tpl.html'
            });
        }]);

    });
})(requirejs);

