(function (requirejs) {
'use strict';


    define('../lib/eezymanager-securesocial/javascripts/services',['angular'], function(angular) {

        /* Services */

        angular.module('eezymanager.securesocial.services', ['ngCookies'])
            .constant('EEZY_ACCESS_CHECK_TYPES', {
                atLeastOne: 'at-least-one',
                combinationRequired: 'combination-required'
            })
            .service('Session', ['$rootScope', 'EEZY_ACCESS_CHECK_TYPES', '$location', 'AUTH_EVENTS', '$cookies', '$injector', function($rootScope, EEZY_ACCESS_CHECK_TYPES, $location, AUTH_EVENTS, $cookies, $injector) {

                this.ready = false;

                this.init = function() {
                    var data = $cookies.get('session');
                    if (data) {
                        this.token = data.token;
                        this.expires = data.expires;
                        this.authenticated = true;
                    } else {
                        this.authenticated = false;
                    }
                };
                this.create = function(token, expires) {
                    var data = {
                        token: token,
                        expires: expires
                    };
                    $cookies.put('session', data);

                    this.token = token;
                    this.expires = expires;
                    this.authenticated = true;
                    $rootScope.$broadcast(AUTH_EVENTS.loginSuccess);
                };
                this.destroy = function() {
                    this.token = null;
                    this.expires = null;
                    this.authenticated = false;
                    this.identity = null;
                    $cookies.remove('session');
                };

                this.logout = function() {
                    this.destroy();
                    $injector.get('$http')({
                       method: 'GET',
                       url: '/logout'
                    }).success(function(response) {
                        $location.path('/login');
                        $rootScope.$broadcast(AUTH_EVENTS.logoutSuccess);
                    });
                };

                this.setIdentity = function(identity) {
                    this.identity = identity;
                    this.ready = true;
                };

                this.getIdentity = function() {
                    return this.identity;
                };

                this.hasPermission = function(permission) {
                    if (!this.authenticated) {
                        return false;
                    }
                    if (this.identity && this.identity.admin === true) {
                        return true;
                    }
                    if (this.identity) {
                        var authorized = false;
                        if (this.identity.permissions !== undefined) {
                            var len, i;
                            for (i = 0, len = this.identity.permissions.length; i < len; i ++) {
                                if (this.identity.permissions[i] == permission) {
                                    authorized = true;
                                    break;
                                }
                            }
                        }
                        return authorized;
                    } else {
                        return false;
                    }
                };

                this.hasRole = function(role) {
                    if (!this.authenticated) {
                        if (role == 'ROLE_ANONYMOUS') {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    if (this.identity && this.identity.admin === true) {
                        return true;
                    }
                    if (this.identity) {
                        var authorized = false;
                        if (this.identity.roles !== undefined) {
                            var i, len;
                            for (i = 0, len = this.identity.roles.length; i < len; i ++) {
                                if (this.identity.roles[i] == role) {
                                    authorized = true;
                                    break;
                                }
                            }
                        }
                        return authorized;
                    } else {
                        return false;
                    }
                };

                this.checkPermissions = function(permissions, checkType) {
                    if (typeof(permissions) == 'string') {
                        permissions = permissions.split(',');
                    }
                    if (!this.authenticated) {
                        return false;
                    }
                    if (this.identity && this.identity.admin === true) {
                        return true;
                    }

                    if (checkType === undefined) {
                        checkType = EEZY_ACCESS_CHECK_TYPES.atLeastOne;
                    }

                    var authorized = false;

                    var i, len;
                    if (checkType == EEZY_ACCESS_CHECK_TYPES.atLeastOne) {
                        for (i = 0, len = permissions.length; i < len; i ++) {
                            if (this.hasPermission(permissions[i].trim())) {
                                authorized = true;
                                break;
                            }
                        }
                    } else if (checkType == EEZY_ACCESS_CHECK_TYPES.combinationRequired) {
                        authorized = true;
                        for (i = 0, len = permissions.length; i < len; i ++) {
                            if (!this.hasPermission(permissions[i].trim())) {
                                authorized = false;
                                break;
                            }
                        }
                    }

                    return authorized;
                };

                this.checkRoles = function(roles, checkType) {
                    if (typeof(roles) == 'string') {
                        roles = roles.split(',');
                    }
                    if (!this.authenticated) {
                        if (roles.length == 1 && this.hasRole(roles[0])) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    if (this.identity && this.identity.admin === true) {
                        return true;
                    }

                    if (checkType === undefined) {
                        checkType = EEZY_ACCESS_CHECK_TYPES.atLeastOne;
                    }

                    var authorized = false;
                    var i, len;
                    if (checkType == EEZY_ACCESS_CHECK_TYPES.atLeastOne) {
                        for (i = 0, len = roles.length; i < len; i ++) {
                            if (this.hasRole(roles[i].trim())) {
                                authorized = true;
                                break;
                            }
                        }
                    } else if (checkType == EEZY_ACCESS_CHECK_TYPES.combinationRequired) {
                        authorized = true;
                        for (i = 0, len = roles.length; i < len; i ++) {
                            if (!this.hasRole(roles[i].trim())) {
                                authorized = false;
                                break;
                            }
                        }
                    }

                    return authorized;
                };

                this.checkAccess = function(access) {
                    if (access === undefined) {
                        return this.authenticated;
                    }
                    if (access.allowAnonymous !== undefined && access.allowAnonymous === true && !this.authenticated) {
                        return true;
                    } else if (!this.authenticated) {
                        return false;
                    }
                    var authorized = true;
                    if (access.permissions !== undefined) {
                        authorized = authorized && this.checkPermissions(access.permissions, access.permissionType);
                    }
                    if (access.roles !== undefined) {
                        authorized = authorized && this.checkRoles(access.roles, access.roleType);
                    }

                    return authorized;
                };

                return this;
            }]);
    });
})(requirejs);

