(function (requirejs) {
'use strict';


    define('configuration/typeCritere',['angular'],
        function(angular) {

            angular.module('eezycleanApp.configuration.typeCritere', ['ngRoute', 'pascalprecht.translate'])


            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/configuration/type-critere', {
                    templateUrl: '/partials/configuration/typeCritere/list.tpl.html'
                });
                $routeProvider.when('/configuration/type-critere/new', {
                    templateUrl: '/partials/configuration/typeCritere/create.tpl.html'
                });
                $routeProvider.when('/configuration/type-critere/:id', {
                    templateUrl: '/partials/configuration/typeCritere/edit.tpl.html'
                });
            }]);
        }
    );
})(requirejs);

