(function (requirejs) {
'use strict';


    define('configuration/typeCtrlCont',['angular'],
        function(angular) {

            angular.module('eezycleanApp.configuration.typeCtrlCont', ['ngRoute', 'pascalprecht.translate'])


            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/configuration/type-controle-contradictoire', {
                    templateUrl: '/partials/configuration/typeCtrlCont/list.tpl.html'
                });
                $routeProvider.when('/configuration/type-controle-contradictoire/new', {
                    templateUrl: '/partials/configuration/typeCtrlCont/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.Configuration.TypeCtrlCont.Create.Title';
                        $scope.mode = 'create';
                        $scope.url = '/configuration/type-controle-contradictoire';
                    }]
                });
                $routeProvider.when('/configuration/type-controle-contradictoire/:id', {
                    templateUrl: '/partials/configuration/typeCtrlCont/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.Configuration.TypeCtrlCont.Edit.Title';
                        $scope.mode = 'edit';
                        $scope.url = '/configuration/type-controle-contradictoire/:id';
                    }]
                });
            }]);
        }
    );
})(requirejs);

