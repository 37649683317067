(function (requirejs) {
'use strict';

     define('planning/duplicateweek',['angular'],
        function(angular) {
        var duplicateweek = angular.module('eezycleanApp.planning.duplicateweek', ['ngRoute']);

            duplicateweek.config(['$routeProvider', function($routeProvider) {
                  $routeProvider.when('/planning/duplicateweek/:siteId/:serviceId',{
                        templateUrl: '/partials/planning/planning/duplicateweek.tpl.html',
                        controller: ['$scope', function($scope) {
                            $scope.title = 'EE.Core.Planning.Duplicate.TitleHead';
                            $scope.url = '/planning/duplicateCreneaux/:serviceId';
                        }]
                    });
             }]);

           duplicateweek.controller('eezycleanApp.planning.duplicateweekCtrl',['$scope','$http','$routeParams',
                function($scope, $http, $routeParams){

                       /*$scope.showDialog = false;
                       $scope.showModal = function() {
                            $scope.showDialog = true;
                       };*/

                        $scope.form.nbFirstWeek = 0;

                        $scope.options = {
                             daysOfWeekDisabled: "0,2,3,4,5,6",
                             calendarWeeks: true,
                             orientation: "top auto"
                       };

                       $scope.optionsTarget = {
                             daysOfWeekDisabled: "0,2,3,4,5,6",
                             calendarWeeks: true,
                             startDate: null,
                             orientation: "top auto"
                       };

                       var numberDuplicate = function(){

                          if (angular.isDefined($scope.form.dateFirstWeekToDuplicate) && $scope.form.dateFirstWeekToDuplicate !== null) {

                              $http({
                                 method: 'POST',
                                 url:'/planning/numberCreneauDuplicate/'+$routeParams.serviceId,
                                 data: $scope.form
                                 }).success(function(result){
                                 $scope.numberCreneauDuplicate = result;
                                });
                          }


                            //Vérification de la date du début de la duplication
                            var date = new Date($scope.form.dateFirstWeekToDuplicate);
                            var nbFirstWeek = $scope.form.nbFirstWeek;
                            if (!angular.isDefined(nbFirstWeek)) {
                                nbFirstWeek = 0;
                            }
                            date.setDate(date.getDate() + 7*(nbFirstWeek + 1));
                            $scope.optionsTarget.startDate = date;
                       };

                       $scope.$watch('form.dateFirstWeekToDuplicate',numberDuplicate);

                       $scope.$watch('form.nbFirstWeek', numberDuplicate);

                }]);
           }
     );
})(requirejs);
