(function (requirejs) {
'use strict';


define('../lib/eezymanager-core/javascripts/admin/permission',['angular'],
    function(angular) {

        angular.module('eezymanager.core.admin.permission', ['ngRoute', 'pascalprecht.translate'])


        // Routes
        .config(['$routeProvider', function($routeProvider) {
            $routeProvider.when('/admin/permission', {
                templateUrl: '/eezymanager/core/partials/admin/permission/list.tpl.html'
            });
            $routeProvider.when('/admin/permission/new', {
                templateUrl: '/eezymanager/core/partials/admin/permission/create.tpl.html'
            });
            $routeProvider.when('/admin/permission/:id', {
                templateUrl: '/eezymanager/core/partials/admin/permission/edit.tpl.html'
            });
        }]);

    });
})(requirejs);

