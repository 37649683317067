(function (requirejs) {
'use strict';


define('../lib/eezymanager-core/javascripts/admin/translation',['angular'],
    function(angular) {

        angular.module('eezymanager.core.admin.translation', ['ngRoute', 'pascalprecht.translate'])

        // Routes
        .config(['$routeProvider', function($routeProvider) {
            $routeProvider.when('/admin/translation', {
                templateUrl: '/eezymanager/core/partials/admin/translation/list.tpl.html'
            });
            $routeProvider.when('/admin/translation/new', {
                templateUrl: '/eezymanager/core/partials/admin/translation/edit.tpl.html',
                controller: ['$scope', function($scope) {
                    $scope.title = 'EE.Core.Admin.Translation.Create.Title';
                    $scope.mode = 'create';
                    $scope.url = '/admin/translation';
                }]
            });
            $routeProvider.when('/admin/translation/import', {
                templateUrl: '/eezymanager/core/partials/admin/translation/import.tpl.html'
            });
            $routeProvider.when('/admin/translation/export', {
                templateUrl: '/eezymanager/core/partials/admin/translation/export.tpl.html'
            });
            $routeProvider.when('/admin/translation/:id', {
                templateUrl: '/eezymanager/core/partials/admin/translation/edit.tpl.html',
                controller: ['$scope', function($scope) {
                    $scope.title = 'EE.Core.Admin.Translation.Edit.Title';
                    $scope.mode = 'edit';
                    $scope.url = '/admin/translation/:id';
                }]
            });
        }]);
    });
})(requirejs);

