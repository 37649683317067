(function (requirejs) {
'use strict';


    define('configuration/typeDeclarationQuotidienne',['angular'],
        function(angular) {

            angular.module('eezycleanApp.configuration.typeDeclarationQuotidienne', ['ngRoute', 'pascalprecht.translate'])


            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/configuration/type-declaration-quotidienne', {
                    templateUrl: '/partials/configuration/typeDeclarationQuotidienne/list.tpl.html'
                });
                $routeProvider.when('/configuration/type-declaration-quotidienne/new', {
                    templateUrl: '/partials/configuration/typeDeclarationQuotidienne/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.Configuration.TypeDeclarationQuotidienne.Create.Title';
                        $scope.mode = 'create';
                        $scope.url = '/configuration/type-declaration-quotidienne';
                    }]
                });
                $routeProvider.when('/configuration/type-declaration-quotidienne/:id', {
                    templateUrl: '/partials/configuration/typeDeclarationQuotidienne/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.Configuration.TypeDeclarationQuotidienne.Edit.Title';
                        $scope.mode = 'edit';
                        $scope.url = '/configuration/type-declaration-quotidienne/:id';
                    }]
                });
            }])

            // Translations
            .config(['$translateProvider', function($translateProvider) {

                // EN
                $translateProvider.translations('en', {
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Title': 'Admin daily declaration types',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.List': 'Daily declaration types list',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Column.ID': 'ID',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Column.Label': 'Label',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Column.Processus': 'Processus',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Column.Sites': 'Sites',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Create': 'Create daily declaration type',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Create.Title': 'Create daily declaration type',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Edit.Title': 'Edit daily declaration type',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Field.Label': 'Label',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Field.Icon': 'Icon',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Field.Position': 'Position',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Field.Processus': 'Processus',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Field.Processus.EmptyLabel': 'Select a processus',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Field.Sites': 'Sites',
                    '': ''
                });

                // FR
                $translateProvider.translations('fr', {
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Title': 'Administration des types de déclaration quotidienne',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.List': 'Liste des types de déclaration quotidienne',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Column.ID': 'ID',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Column.Label': 'Libellé',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Column.Processus': 'Processus',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Column.Sites': 'Sites',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Create': 'Créer un type de déclaration quotidienne',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Create.Title': 'Création d\'un type de déclaration quotidienne',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Edit.Title': 'Modification d\'un type de déclaration quotidienne',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Field.Label': 'Libellé',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Field.Icon': 'Icône',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Field.Position': 'Position',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Field.Processus': 'Processus',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Field.Processus.EmptyLabel': 'Sélectionnez un processus',
                    'EE.Core.Configuration.TypeDeclarationQuotidienne.Field.Sites': 'Sites',
                    '': ''
                });
            }]);


        }
    );
})(requirejs);

