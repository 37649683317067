(function (requirejs) {
'use strict';


    define('configuration/periodicite',['angular'],
        function(angular) {

            angular.module('eezycleanApp.configuration.periodicite', ['ngRoute', 'pascalprecht.translate'])


            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/configuration/periodicite', {
                    templateUrl: '/partials/configuration/periodicite/list.tpl.html'
                });
                $routeProvider.when('/configuration/periodicite/new', {
                    templateUrl: '/partials/configuration/periodicite/create.tpl.html'
                });
                $routeProvider.when('/configuration/periodicite/:id', {
                    templateUrl: '/partials/configuration/periodicite/edit.tpl.html'
                });
            }]);
        }
    );
})(requirejs);

