(function (requirejs) {
'use strict';


    define('configuration/typeUsure',['angular'],
        function(angular) {

            angular.module('eezycleanApp.configuration.typeUsure', ['ngRoute', 'pascalprecht.translate'])


            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/configuration/type-usure', {
                    templateUrl: '/partials/configuration/typeUsure/list.tpl.html'
                });
                $routeProvider.when('/configuration/type-usure/new', {
                    templateUrl: '/partials/configuration/typeUsure/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.Configuration.TypeUsure.Create.Title';
                        $scope.mode = 'create';
                        $scope.url = '/configuration/type-usure';
                        /*
                        $scope.init = {
                                        id: 1,
                                        label: "Type d'usure 1",
                                        usures: [
                                          {
                                            position: 1,
                                            label: "Comme neuf",
                                            color: "#00ff53",
                                            conforme: true,
                                            taux: 100
                                          },
                                          {
                                            position: 2,
                                            label: "Normal",
                                            color: "#00d5ff",
                                            conforme: true,
                                            taux: 80
                                          },
                                          {
                                            position: 3,
                                            label: "Usé",
                                            color: "#ffa200",
                                            conforme: true,
                                            taux: 50
                                          },
                                          { position: 4, label: "Trop usé", color: "#ff0000", taux: 0 }
                                        ]
                                      };*/
                    }]
                });
                $routeProvider.when('/configuration/type-usure/:id', {
                    templateUrl: '/partials/configuration/typeUsure/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.Configuration.TypeUsure.Edit.Title';
                        $scope.mode = 'edit';
                        $scope.url = '/configuration/type-usure/:id';
                    }]
                });
            }]);

        }
    );
})(requirejs);

