(function (requirejs) {
'use strict';

define('../lib/eezymanager-core/javascripts/admin/site',['angular'],
    function(angular) {

        angular.module('eezymanager.core.admin.site', ['ngRoute', 'pascalprecht.translate'])


        // Routes
        .config(['$routeProvider', function($routeProvider) {
            $routeProvider.when('/admin/site', {
                templateUrl: '/eezymanager/core/partials/admin/site/list.tpl.html',
                controller: ['$scope', function($scope) {
                    $scope.order = [[2, 'asc'], [3, 'asc']];
                }]
            });
            $routeProvider.when('/admin/site/new', {
                templateUrl: '/eezymanager/core/partials/admin/site/create.tpl.html'
            });
            $routeProvider.when('/admin/site/:id', {
                templateUrl: '/eezymanager/core/partials/admin/site/edit.tpl.html'
            });
        }]);

    });
})(requirejs);

