(function (requirejs) {
'use strict';


    define('admin/processus',['angular'],
        function(angular) {

            angular.module('eezycleanApp.admin.processus', ['ngRoute', 'pascalprecht.translate'])


            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/admin/processus', {
                    templateUrl: '/partials/admin/processus/list.tpl.html'
                });
                $routeProvider.when('/admin/processus/new', {
                    templateUrl: '/partials/admin/processus/create.tpl.html'
                });
                $routeProvider.when('/admin/processus/:id', {
                    templateUrl: '/partials/admin/processus/edit.tpl.html'
                });
            }]);
        }
    );
})(requirejs);

