(function (requirejs) {
'use strict';


define('manage/employee',['angular'],
    function(angular) {

        angular.module('eezycleanApp.manage.employee', ['ngRoute', 'pascalprecht.translate'])


        // Routes
        .config(['$routeProvider', function($routeProvider) {
            $routeProvider.when('/manage/employee', {
                templateUrl: '/partials/manage/employee/list.tpl.html'
            });
            $routeProvider.when('/manage/employee/new', {
                templateUrl: '/partials/manage/employee/create.tpl.html'
            });
            $routeProvider.when('/manage/employee/:id', {
                templateUrl: '/partials/manage/employee/edit.tpl.html'
            });
        }]);

    });
})(requirejs);

