(function (requirejs) {
'use strict';


    define('../lib/eezymanager-securesocial/javascripts/admin/user',['angular'],
        function(angular) {

            angular.module('eezymanager.securesocial.admin.user', ['ngRoute', 'pascalprecht.translate', 'eezymanager.securesocial.services'])


            // Routes
            .config(['$routeProvider', 'EEZY_ACCESS_CHECK_TYPES', function($routeProvider, EEZY_ACCESS_CHECK_TYPES) {
                $routeProvider.when('/admin/user', {
                    templateUrl: '/eezymanager/securesocial/partials/admin/user/list.tpl.html'
                });
                $routeProvider.when('/admin/user/new', {
                    templateUrl: '/eezymanager/securesocial/partials/admin/user/create.tpl.html'
                });
                $routeProvider.when('/admin/user/:id', {
                    templateUrl: '/eezymanager/securesocial/partials/admin/user/edit.tpl.html'
                });
            }]);


        }
    );
})(requirejs);

