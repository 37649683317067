(function (requirejs) {
'use strict';


    define('configuration/periodiciteElement',['angular'],
        function(angular) {

            angular.module('eezycleanApp.configuration.periodiciteElement', ['ngRoute', 'pascalprecht.translate'])


            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/configuration/periodicite-element', {
                    templateUrl: '/partials/configuration/periodiciteElement/list.tpl.html'
                });
                $routeProvider.when('/configuration/periodicite-element/new', {
                    templateUrl: '/partials/configuration/periodiciteElement/create.tpl.html'
                });
                $routeProvider.when('/configuration/periodicite-element/:id', {
                    templateUrl: '/partials/configuration/periodiciteElement/edit.tpl.html'
                });
            }]);
        }
    );
})(requirejs);

