(function (requirejs) {
'use strict';


define('../lib/eezymanager-ged/javascripts/eezymanager-ged',[

    'angular',
    'mp.deepBlur',
    'ngFileUpload',
    'ngBusy',
    './directive/filemanager',
    './directive/autofocus',
    './directive/deletenode',
    './ged/menu',
    './ged'],
    function(angular) {

        angular.module('eezymanager.ged',
            [
                'eezymanager.ged.directive.filemanager',
                'eezymanager.ged.directive.autofocus',
                'eezymanager.ged.directive.eezyDeleteNode',
                'eezymanager.ged.directive.eezyDeleteNode',
                'eezymanager.ged.ged.menu',
                'eezymanager.ged.ged'
            ]
        );

    });
})(requirejs);

