(function (requirejs) {
'use strict';


    define('configuration/typeAudit',['angular'],
        function(angular) {

            angular.module('eezycleanApp.configuration.typeAudit', ['ngRoute', 'pascalprecht.translate'])


            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/configuration/type-audit', {
                    templateUrl: '/partials/configuration/typeAudit/list.tpl.html'
                });
                $routeProvider.when('/configuration/type-audit/new', {
                    templateUrl: '/partials/configuration/typeAudit/create.tpl.html'
                });
                $routeProvider.when('/configuration/type-audit/:id', {
                    templateUrl: '/partials/configuration/typeAudit/edit.tpl.html'
                });
            }]);

        }
    );
})(requirejs);

