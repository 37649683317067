(function (requirejs) {
'use strict';


    define('configuration/site/typeElement',['angular'],
        function(angular) {

            angular.module('eezycleanApp.configuration.site.typeElement', ['ngRoute', 'pascalprecht.translate'])


            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/configuration/site/type-lieu/:typeLieuId/type-partie/:typePartieId/type-element/new', {
                    templateUrl: '/partials/configuration/site/typeElement/edit.tpl.html',
                    controller: ['$scope', '$routeParams', function($scope, $routeParams) {
                        $scope.title = 'EE.Core.Configuration.Site.TypeElement.Create.Title';
                        $scope.mode = 'create';
                        $scope.url = '/configuration/site/type-element';
                        $scope.init = {
                            typePartieId: $routeParams.typePartieId
                        };
                    }]
                });
                $routeProvider.when('/configuration/site/type-lieu/:typeLieuId/type-partie/:typePartieId/type-element/:id', {
                    templateUrl: '/partials/configuration/site/typeElement/edit.tpl.html',
                    controller: ['$scope', '$routeParams', function($scope, $routeParams) {
                        $scope.title = 'EE.Core.Configuration.Site.TypeElement.Edit.Title';
                        $scope.mode = 'edit';
                        $scope.url = '/configuration/site/type-element/:id';
                        $scope.init = {
                        };
                    }]
                });
            }]);

        }
    );
})(requirejs);

