(function (requirejs) {
'use strict';

  define('filters',['angular'], function(angular) {

  /* Filters */

  angular.module('eezycleanApp.filters', []).
    filter('interpolate', ['version', function(version) {
      return function(text) {
        return String(text).replace(/\%VERSION\%/mg, version);
      };
    }]);

  });
})(requirejs);

