(function (requirejs) {
'use strict';


define('../lib/eezymanager-core/javascripts/message',['angular'],
    function(angular) {

        angular.module('eezymanager.core.message', ['ngRoute', 'pascalprecht.translate'])
            .controller('CoreMessageCtrl', ['Session', '$q', '$rootScope', '$scope', '$http', '$location', '$translate', 'growl', function (Session, $q, $rootScope, $scope, $http, $location, $translate, growl) {
                var websocket;
                $scope.messages = [];
                $scope.newMessagesCount = 0;
                $http({
                    'method': 'GET',
                    'url': '/messages'
                }).success(function(response) {
                    $scope.messages = response;
                });

                $scope.clickMessage = function(message) {
                    websocket.send(angular.toJson(
                        {
                            type: 'CLICK_MESSAGE',
                            data: message
                        }
                    ));
                };

                $scope.showMessages = function() {
                    angular.forEach($scope.messages, function(message) {
                        if (message.status == 'NEW') {
                            websocket.send(angular.toJson(
                                {
                                    type: 'VIEW_MESSAGE',
                                    data: message
                                }
                            ));
                        }
                    });
                };


                function messagesUpdated() {
                    while ($scope.messages.length > 10) {
                        $scope.messages.pop();
                    }
                    $scope.newMessagesCount = 0;
                    angular.forEach($scope.messages, function(message) {
                        if (message.status == 'NEW') {
                            $scope.newMessagesCount ++;
                        }
                    });
                }

                $scope.$watch('messages', messagesUpdated, true);


                function openWebSocket() {
                    var identity = Session.getIdentity();
                    if (angular.isDefined(identity)) {
                        if (identity !== null && typeof identity !== 'undefined' && identity.admin === true) {
                            setTimeout(function() {
                                openWebSocket();
                            }, 5000);
                            return;
                        }
                    } else {
                        setTimeout(function() {
                            openWebSocket();
                        }, 1000);
                        return;
                    }

                    var WS = window.MozWebSocket ? MozWebSocket : WebSocket;
                    var wsURL = "ws://" + $location.host() + ':' + $location.port() + "/ws/messages";

                    var protocol = $location.protocol();
                    if (protocol === "https") {
                        wsURL = wsURL.replace('ws://', 'wss://');
                    }
                    websocket = new WS(wsURL);
                    var receiveEvent = function(event) {
                        var response = JSON.parse(event.data);
                        var message;
                        switch (response.type) {
                            case 'MESSAGE':
                                message = response.data;

                                if (message.deleted) {
                                    var deleteIndex = null;
                                    $.each($scope.messages, function (index, item) {
                                        if (item.id == message.id) {
                                            deleteIndex = index;
                                        }
                                    });

                                    if (deleteIndex !== null) {
                                        $scope.messages.splice(deleteIndex, 1);
                                    }
                                } else {
                                    $scope.messages.splice(0, 0, response.data);
                                    var title = $translate(response.data.messageCode + ".title");
                                    var text = $translate(response.data.messageCode + ".text");

                                    $q.all([title, text]).then(function(translations) {
                                        var text = translations[1];
                                        if (angular.isDefined(response.data.params) && angular.isDefined(response.data.params.href))
                                        {
                                            text = '<a href="'+response.data.params.href+'">' + text + '</a>';
                                        }

                                        growl.info(text, {ttl: 5000, title: translations[0]});
                                    });
                                }
                            break;
                            case 'UPDATE_MESSAGE':
                                message = response.data;
                                angular.forEach($scope.messages, function(msg) {
                                    if (message.id == msg.id) {
                                        angular.copy(message, msg);
                                    }
                                });
                            break;
                            case 'EVENT':
                                var eventData = response.data;
                                $rootScope.$emit('server.' + eventData.type, eventData.params);
                            break;
                            default:
                            break;
                        }

                        $scope.$apply();
                    };

                    websocket.onmessage = receiveEvent;

                    var ping = function() {
                        websocket.send(angular.toJson(
                            {type: 'PING'}
                        ));
                        setTimeout(ping, 15000);
                    };

                    websocket.onopen = ping;
                    websocket.onerror = function(error) {
                        setTimeout(function() {
                            openWebSocket();
                        }, 5000);
                    };
                }

                openWebSocket();


            }])
        ;
    });
})(requirejs);

