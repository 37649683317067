(function (requirejs) {
'use strict';


define('../lib/eezymanager-ged/javascripts/ged/menu',['angular'],
    function(angular) {

        angular.module('eezymanager.ged.ged.menu', ['ngRoute', 'pascalprecht.translate'])
        .directive('eezyGedMenu', ['$http', function($http){

            return {
                restrict:'E',
                scope:{
                    siteUrl: '@',
                    nodeContext: '='
                },
                transclude: false,
                templateUrl:  '/eezymanager/ged/partials/ged/directives/menu/menu.tpl.html',
                link: function(scope){

                    $http({
                        method:'GET',
                        url: scope.siteUrl
                    }).success(function(response) {
                        scope.sites = response;
                        if (scope.sites.length > 0) {
                            scope.siteId = scope.sites[0].id;
                            scope.nodeContext = {
                                type: 'site',
                                identifier: scope.siteId
                            };
                        }
                    });

                    scope.selectSite = function(site){
                       scope.siteId = site;
                        scope.nodeContext = {
                            type: 'site',
                            identifier: site
                        };
                    };

                    scope.selectPublicFolder = function(){
                        scope.siteId = null;
                        scope.nodeContext = {
                            type: 'public',
                            identifier: null
                        };
                    };

                }
            };
        }]);

    });
})(requirejs);

