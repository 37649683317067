(function (requirejs) {
'use strict';


    define('configuration/typeDeclarationMensuelle',['angular'],
        function(angular) {

            angular.module('eezycleanApp.configuration.typeDeclarationMensuelle', ['ngRoute', 'pascalprecht.translate'])


            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/configuration/type-declaration-mensuelle', {
                    templateUrl: '/partials/configuration/typeDeclarationMensuelle/list.tpl.html'
                });
                $routeProvider.when('/configuration/type-declaration-mensuelle/new', {
                    templateUrl: '/partials/configuration/typeDeclarationMensuelle/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.Configuration.TypeDeclarationMensuelle.Create.Title';
                        $scope.mode = 'create';
                        $scope.url = '/configuration/type-declaration-mensuelle';
                    }]
                });
                $routeProvider.when('/configuration/type-declaration-mensuelle/:id', {
                    templateUrl: '/partials/configuration/typeDeclarationMensuelle/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.Configuration.TypeDeclarationMensuelle.Edit.Title';
                        $scope.mode = 'edit';
                        $scope.url = '/configuration/type-declaration-mensuelle/:id';
                    }]
                });
            }])

            // Translations
            .config(['$translateProvider', function($translateProvider) {

                // EN
                $translateProvider.translations('en', {
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Title': 'Admin monthly declaration types',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.List': 'Monthly declaration types list',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Column.ID': 'ID',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Column.Label': 'Label',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Column.Processus': 'Processus',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Column.Sites': 'Sites',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Create': 'Create monthly declaration type',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Create.Title': 'Create monthly declaration type',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Edit.Title': 'Edit monthly declaration type',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Field.Label': 'Label',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Field.Icon': 'Icon',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Field.Position': 'Position',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Field.Processus': 'Processus',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Field.Processus.EmptyLabel': 'Select a processus',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Edit.Field.DebutMois': 'Begin month',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Field.Sites': 'Sites',
                    '': ''
                });

                // FR
                $translateProvider.translations('fr', {
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Title': 'Administration des types de déclaration mensuelle',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.List': 'Liste des types de déclaration mensuelle',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Column.ID': 'ID',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Column.Label': 'Libellé',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Column.Processus': 'Processus',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Column.Sites': 'Sites',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Create': 'Créer un type de déclaration mensuelle',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Create.Title': 'Création d\'un type de déclaration mensuelle',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Edit.Title': 'Modification d\'un type de déclaration mensuelle',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Field.Label': 'Libellé',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Field.Icon': 'Icône',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Field.Position': 'Position',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Field.Processus': 'Processus',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Field.Processus.EmptyLabel': 'Sélectionnez un processus',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Edit.Field.DebutMois': 'Début de mois',
                    'EE.Core.Configuration.TypeDeclarationMensuelle.Field.Sites': 'Sites',
                    '': ''
                });
            }]);


        }
    );
})(requirejs);

