(function (requirejs) {
'use strict';


define('../lib/eezymanager-core/javascripts/configuration/scheduled_task',['angular'],
    function(angular) {

        angular.module('eezymanager.core.configuration.scheduled_task', ['ngRoute', 'pascalprecht.translate'])


        // Routes
        .config(['$routeProvider', function($routeProvider) {
            $routeProvider.when('/configuration/scheduled-task', {
                templateUrl: '/eezymanager/core/partials/configuration/scheduled_task/list.tpl.html',
                controller: ['$rootScope', '$scope', '$timeout', function($rootScope, $scope, $timeout) {
                    var needReload = false;
                    function reload() {
                        if (angular.isDefined($scope.datatableApi) && $scope.datatableApi !== null) {
                            $scope.datatableApi.ajax.reload();
                            needReload = false;
                        } else {
                            needReload = true;
                        }
                    }

                    $scope.$watch('datatableApi', function(datatableApi, oldVal) {
                        if (needReload) {
                            reload();
                        }
                    });

                    $rootScope.$on('server.event.task.start', reload);
                    $rootScope.$on('server.event.task.error', reload);
                    $rootScope.$on('server.event.task.end', reload);
                }]
            });
            $routeProvider.when('/configuration/scheduled-task/new', {
                templateUrl: '/eezymanager/core/partials/configuration/scheduled_task/edit.tpl.html',
                controller: ['$scope', function($scope) {
                    $scope.title = 'EE.Core.Configuration.ScheduledTask.Create.Title';
                    $scope.mode = 'create';
                    $scope.url = '/configuration/scheduled-task';
                }]
            });
            $routeProvider.when('/configuration/scheduled-task/:id', {
                templateUrl: '/eezymanager/core/partials/configuration/scheduled_task/edit.tpl.html',
                controller: ['$scope', function($scope) {
                    $scope.title = 'EE.Core.Configuration.ScheduledTask.Edit.Title';
                    $scope.mode = 'edit';
                    $scope.url = '/configuration/scheduled-task/:id';
                }]
            });
        }])
        .controller('ScheduledTaskRowCtrl', ['$scope', '$http', function($scope, $http) {
            $scope.runTask = function(taskId) {
                $scope.loadingRun = $http({
                    method: 'post',
                    url: '/configuration/scheduled-task/run/' + taskId
                });
            };
            $scope.restartTask = function(taskId) {
                $scope.loadingRestart = $http({
                    method: 'post',
                    url: '/configuration/scheduled-task/restart/' + taskId
                }).success(function(response) {
                    $scope.api.ajax.reload();
                });
            };
            $scope.reset = function(taskId) {
                $scope.loadingReset = $http({
                    method: 'post',
                    url: '/configuration/scheduled-task/reset/' + taskId
                }).success(function(response) {
                    $scope.api.ajax.reload();
                });
            };
        }])
        ;

    });
})(requirejs);

