(function (requirejs) {
'use strict';

    define('configuration',['angular',
        './configuration/typeAudit',
        './configuration/typeChapitre',
        './configuration/typeTheme',
        './configuration/typeCritere',
        './configuration/typeCritereEvaluation',
        './configuration/periodicite',
        './configuration/periodiciteElement',
        './configuration/site',
        './configuration/typeUsure',
        './configuration/typeCtrlCont',
        './configuration/typeEvaluation',
        './configuration/typeDeclaration',
        './configuration/sousTypeDeclaration',
        './configuration/typePlanning',
        './configuration/motifAbsence',
        './configuration/consommable',
        './configuration/typeDeclarationQuotidienne',
        './configuration/typeDeclarationMensuelle',
        './configuration/agent',
        './manage/employee',
        './report/report'
        ], function(angular) {

    /* Services */

    // Demonstrate how to register services
    // In this case it is a simple value service.
    angular.module('eezycleanApp.configuration', [
        'eezycleanApp.configuration.typeAudit',
        'eezycleanApp.configuration.typeChapitre',
        'eezycleanApp.configuration.typeTheme',
        'eezycleanApp.configuration.typeCritere',
        'eezycleanApp.configuration.typeCritereEvaluation',
        'eezycleanApp.configuration.periodicite',
        'eezycleanApp.configuration.periodiciteElement',
        'eezycleanApp.configuration.site',
        'eezycleanApp.configuration.typeUsure',
        'eezycleanApp.configuration.typeCtrlCont',
        'eezycleanApp.configuration.typeEvaluation',
        'eezycleanApp.configuration.typeDeclaration',
        'eezycleanApp.configuration.sousTypeDeclaration',
        'eezycleanApp.configuration.typePlanning',
        'eezycleanApp.configuration.motifAbsence',
        'eezycleanApp.configuration.consommable',
        'eezycleanApp.configuration.typeDeclarationQuotidienne',
        'eezycleanApp.configuration.typeDeclarationMensuelle',
        'eezycleanApp.configuration.agent',
        'eezycleanApp.manage.employee',
        'eezycleanApp.report'
        ])
        ;


    });
})(requirejs);

