(function (requirejs) {
'use strict';


    define('configuration/typePlanning',['angular'],
        function(angular) {

            angular.module('eezycleanApp.configuration.typePlanning', ['ngRoute', 'pascalprecht.translate'])


            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/configuration/type-planning', {
                    templateUrl: '/partials/configuration/typePlanning/list.tpl.html'
                });
                $routeProvider.when('/configuration/type-planning/new', {
                    templateUrl: '/partials/configuration/typePlanning/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.Configuration.typePlanning.Create.Title';
                        $scope.mode = 'create';
                        $scope.url = '/configuration/type-planning';
                    }]
                });
                $routeProvider.when('/configuration/type-planning/:id', {
                    templateUrl: '/partials/configuration/typePlanning/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.Configuration.typePlanning.Edit.Title';
                        $scope.mode = 'edit';
                        $scope.url = '/configuration/type-planning/:id';
                    }]
                });
            }]);


        }
    );
})(requirejs);

