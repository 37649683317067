(function (requirejs) {
'use strict';
    define('configuration/sousTypeDeclaration',['angular'],
        function(angular) {

            angular.module('eezycleanApp.configuration.sousTypeDeclaration', ['ngRoute', 'pascalprecht.translate'])

            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/configuration/sous-type-declaration', {
                    templateUrl: '/partials/configuration/sousTypeDeclaration/list.tpl.html'
                });
                $routeProvider.when('/configuration/sous-type-declaration/new', {
                    templateUrl: '/partials/configuration/sousTypeDeclaration/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.Configuration.SousTypeDeclaration.Create.Title';
                        $scope.mode = 'create';
                        $scope.url = '/configuration/sous-type-declaration';
                    }]
                });
                $routeProvider.when('/configuration/sous-type-declaration/:id', {
                    templateUrl: '/partials/configuration/sousTypeDeclaration/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.Configuration.SousTypeDeclaration.Edit.Title';
                        $scope.mode = 'edit';
                        $scope.url = '/configuration/sous-type-declaration/:id';
                    }]
                });
            }])
            ;
        }
    );
})(requirejs);

