(function (requirejs) {
'use strict';


    define('configuration/typeChapitre',['angular'],
        function(angular) {

            angular.module('eezycleanApp.configuration.typeChapitre', ['ngRoute', 'pascalprecht.translate'])


            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/configuration/type-chapitre', {
                    templateUrl: '/partials/configuration/typeChapitre/list.tpl.html'
                });
                $routeProvider.when('/configuration/type-chapitre/new', {
                    templateUrl: '/partials/configuration/typeChapitre/create.tpl.html'
                });
                $routeProvider.when('/configuration/type-chapitre/:id', {
                    templateUrl: '/partials/configuration/typeChapitre/edit.tpl.html'
                });
            }]);

        }
    );
})(requirejs);

