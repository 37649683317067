(function (requirejs) {
'use strict';


    define('configuration/site/typeLieu',['angular'],
        function(angular) {

            angular.module('eezycleanApp.configuration.site.typeLieu', ['ngRoute', 'pascalprecht.translate'])


            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/configuration/site/type-lieu', {
                    templateUrl: '/partials/configuration/site/typeLieu/list.tpl.html'
                });
                $routeProvider.when('/configuration/site/type-lieu/new', {
                    templateUrl: '/partials/configuration/site/typeLieu/edit.tpl.html',
                    controller: ['$scope', '$routeParams', function($scope, $routeParams) {
                        $scope.title = 'EE.Core.Configuration.Site.TypeLieu.Create.Title';
                        $scope.mode = 'create';
                        $scope.url = '/configuration/site/type-lieu';

                        $scope.onSuccessRedirect = '/configuration/site/type-lieu/:id';
                    }]
                });
                $routeProvider.when('/configuration/site/type-lieu/:id', {
                    templateUrl: '/partials/configuration/site/typeLieu/edit.tpl.html',
                    controller: ['$scope', '$routeParams', function($scope, $routeParams) {
                        $scope.title = 'EE.Core.Configuration.Site.TypeLieu.Edit.Title';
                        $scope.mode = 'edit';
                        $scope.url = '/configuration/site/type-lieu/:id';

                        $scope.onSuccessRedirect = '/configuration/site/type-lieu';
                    }]
                });
            }]);

        }
    );
})(requirejs);

