(function (requirejs) {
'use strict';


define('../lib/eezymanager-core/javascripts/controllers',['angular'],
    function(angular) {

        angular.module('eezymanager.core.admin.client.banner', ['ngRoute', 'pascalprecht.translate'])

        .controller('CoreClientBannerCtrl', ['$scope', '$http','$routeParams', 'EezyUrl', 'AUTHORIZATION_EVENTS', function($scope, $http, $routeParams, EezyUrl, AUTHORIZATION_EVENTS){

            $scope.updateBanner = function(){
                $http({
                    method: 'GET',
                    url: '/global/client/current/get'
                }).success(function(result){
                    $scope.banner = result;
                });
            };

           $scope.updateBanner();

        }]);
    });
})(requirejs);

