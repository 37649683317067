(function (requirejs) {
'use strict';

    define('../lib/eezymanager-common/javascripts/directives',['angular', 'jquery-slimscroll'], function(angular) {

        /* Directives */

        angular.module('eezymanager.common.directives', ['eezymanager.common.services', 'ngResource', 'checklist-model', 'infinite-scroll', 'ui.bootstrap'])
            .directive('eezyLoading', [function() {
                return {
                    restrict: 'A',
                    scope: {
                       eezyLoading: '='
                    },
                    link: function(scope, element, attrs) {
                        //element.button();
                        scope.$watch('eezyLoading', function(newVal, oldVal) {
                            if (angular.isDefined(newVal) && newVal !== null) {
                                element.attr('disabled', 'disabled');
                                newVal.then(function() {
                                    element.removeAttr('disabled');
                                });
                            }
                        });
                    }
                };
            }])
            .directive('appVersion', ['version', function(version) {
                return function(scope, elm, attrs) {
                    elm.text(version);
                };
            }])
            .directive('eezyMenu', ['$timeout', '$http', 'AUTH_EVENTS', function($timeout, $http, AUTH_EVENTS) {
                return {
                    restrict: 'E',
                    transclude: true,
                    replace: true,
                    scope: {
                        menuUrl: '@'
                    },
                    templateUrl: '/eezymanager/common/partials/menu.tpl.html',
                    link: function(scope, element, attrs, ctrl, transclude) {

                        scope.closeSubMenusBut = function(target) {
                            var otherMenu = '> li.has-sub > .sub-menu';
                            element.find(otherMenu).not(target).slideUp(250, function() {
                                $(this).closest('li').removeClass('expand');
                            });
                        };

                        scope.toggleMinify = function() {
                            var sidebarClass = 'page-sidebar-minified';
                            var targetContainer = '#page-container';
                            if ($(targetContainer).hasClass(sidebarClass)) {
                                $(targetContainer).removeClass(sidebarClass);
                            } else {
                                $(targetContainer).addClass(sidebarClass);
                            }
                            $(window).trigger('resize');
                        };

                        transclude(scope, function(clone) {
                            element.prepend(clone);
                        });
                    }
                };
            }])
            .directive('eezyMenuHeader', [ function() {
                return {
                    restrict: 'E',
                    replace: true,
                    scope: {
                        title: '@'
                    },
                    templateUrl: '/eezymanager/common/partials/menu/menu-header.tpl.html'
                };
            }])
            .directive('eezyMenuEntry', ['$timeout', '$http', 'AUTH_EVENTS', 'AUTHORIZATION_EVENTS', '$location', '$rootScope', function($timeout, $http, AUTH_EVENTS, AUTHORIZATION_EVENTS, $location, $rootScope) {
                return {
                    restrict: 'E',
                    transclude: true,
                    replace: true,
                    scope: {
                        title: '@',
                        path: '@',
                        pathSubMenu: '@',
                        linkItem:'@',
                        icon: '@'
                    },
                    templateUrl: '/eezymanager/common/partials/menu/menu-entry.tpl.html',
                    link: {
                        pre: function(scope, element, attrs, ctrl, transclude) {
                            scope.updateVisiblity = function() {
                                var len = element.find('.sub-menu')
                                    .children()
                                    .not('.hidden')
                                    .length;
                                if (len === 0 && (scope.path === null || !angular.isDefined(scope.path))) {
                                    element.addClass('hidden');
                                }
                            };
                        },
                        post: function(scope, element, attrs, ctrl, transclude) {
                            var len;
                            var loadMenuFromUrl = function(clone) {
                                $http({
                                    url: scope.pathSubMenu,
                                    method: 'GET'
                                }).success(function(response) {
                                    scope.subMenu = response;
                                    len = response.length;
                                    element.find('.sub-menu')
                                        .append(clone);
                                    if (len > 0) {
                                        element.addClass('has-sub');
                                        element.removeClass('hidden');
                                    } else {
                                        element.removeClass('has-sub');
                                        element.addClass('hidden');
                                    }
                                });
                            };


                            transclude(scope, function(clone) {
                                if(angular.isDefined(scope.pathSubMenu)){
                                    loadMenuFromUrl(clone);
                                    scope.$on(AUTHORIZATION_EVENTS.employeeChanged, function() {loadMenuFromUrl(clone); });
                                    scope.$on(AUTHORIZATION_EVENTS.clientChanged, function() {loadMenuFromUrl(clone); });
                                    scope.$on(AUTH_EVENTS.loginSuccess, function() {loadMenuFromUrl(clone); });
                                }
                                else{
                                    len = element.find('.sub-menu')
                                        .append(clone)
                                        .children()
                                        .length;
                                    if (len > 0) {
                                        element.addClass('has-sub');
                                    } else {
                                        element.find('.caret').remove();
                                    }
                                }
                            });

                            scope.closeSubMenusBut = function(target) {

                                var otherMenu = '> ul.sub-menu > li.has-sub > .sub-menu';

                                element.find(otherMenu).not(target).slideUp(250, function() {
                                    $(this).closest('li').removeClass('expand');
                                });
                            };

                            scope.click = function() {

                                if (scope.path) {
                                    $location.path(scope.path);
                                }

                                if (element.hasClass('has-sub')) {
                                    var target = element.find('> .sub-menu');
                                    //var otherMenu = '.sidebar .nav > li.has-sub > .sub-menu';

                                    $(target).slideToggle(250, function() {
                                        var targetLi = $(this).closest('li');
                                        if ($(targetLi).hasClass('expand')) {
                                            $(targetLi).removeClass('expand');
                                        } else {
                                            $(targetLi).addClass('expand');
                                        }
                                    });

                                    scope.$parent.closeSubMenusBut(target);
                                }
                            };

                            // Run async after other directives like eezy-access-permission finished running
                            scope.$evalAsync(function() {
                                scope.updateVisiblity();
                            });

                            $rootScope.$watch('session.identity', function() {
                                element.removeClass('hidden');
                                scope.$evalAsync(function() {
                                    scope.updateVisiblity();
                                });
                            });
                        }
                    }

                };
            }])
            .directive('eezySlimScroll', ['$timeout', function($timeout) {
                return {
                    restrict: 'A',
                    link: function(scope, element, attrs) {
                        var scrollBarOption = {
                            height: attrs.height,
                            alwaysVisible: attrs.alwaysVisible === 'true'
                        };

                        element.slimScroll(scrollBarOption);
                    }
                };
            }])
            .directive('eezyButton', ['$location', '$routeParams', 'EezyUrl', function($location, $routeParams, EezyUrl) {
                return {
                    restrict: 'E',
                    scope: {
                        onClickRedirect: '@',
                        onClick: '&click',
                        label: '@',
                        type: '@',
                        additionalClasses: '@?'
                    },
                    templateUrl: '/eezymanager/common/partials/button.tpl.html',
                    link: function(scope, element, attrs) {
                        var types = scope.type.split(',');
                        scope.classes = '';
                        angular.forEach(types, function(value) {
                            scope.classes = scope.classes + ' btn-' + value.trim();
                        });
                        if (angular.isDefined(scope.additionalClasses)) {
                            scope.classes = scope.classes + ' ' + scope.additionalClasses;
                        }
                        scope.click = function() {
                            if (scope.onClickRedirect) {
                                $location.path(EezyUrl.generate(scope.onClickRedirect, $routeParams));
                            } else if (scope.onClick) {
                                scope.onClick();
                            }
                        };
                    }
                };
            }])
            .directive('eezyHeader', function() {
                return {
                    restrict: 'E',
                    scope: {
                        h1: '@',
                        h2: '@',
                        lead: '@',
                        subtext: '@',
                        navModule: '@',
                        navService: '@'
                    },
                    templateUrl: '/eezymanager/common/partials/header.tpl.html',
                    transclude: true
                };
            })
            .directive('eezyNav', function() {
                return {
                    restrict: 'E',
                    scope: {
                        module: '@',
                        service: '@'
                    },
                    templateUrl: '/eezymanager/common/partials/navigation.tpl.html',
                    link: function($scope) {
                        $scope.data = angular.injector([$scope.module]).get($scope.service).get(function() {
                            return $scope.$apply();
                        });
                    }
                };
            })

            // Panels
            .directive('eezyPanel', function() {
                return {
                    restrict: 'E',
                    templateUrl: '/eezymanager/common/partials/panel/panel.tpl.html',
                    scope: true,
                    transclude: true,
                    link: {
                        pre: function(scope, element, attrs, ctrl, transclude) {
                            transclude(scope, function(clone, scope) {
                                element.children('.panel').append(clone);
                            });
                        },
                        post: function(scope, element, attrs) {
                            element.find('.panel-heading .panel-title')
                                .append(scope.heading);
                            element.find('.panel-body')
                                .append(scope.body);
                        }
                    }
                };
            })
            .directive('eezyPanelHeading', function() {
                return {
                    restrict: 'E',
                    transclude: true,
                    scope: false,
                    link: function(scope, element, attrs, ctrl, transclude) {
                        transclude(scope.$parent, function(clone, scope) {
                            scope.heading = clone;
                        });
                    }
                };
            })
            .directive('eezyPanelBody', function() {
                return {
                    restrict: 'E',
                    transclude: true,
                    scope: false,
                    link: function(scope, element, attrs, ctrl, transclude) {
                        transclude(scope.$parent, function(clone, scope) {
                            scope.body = clone;
                        });
                    }
                };
            })
            .directive('eezyInfiniteScroll',['$http', function($http){
                return{
                    restrict: 'E',
                    transclude: false,
                    templateUrl: '/eezymanager/common/partials/infinitescroll/infinite-scroll.tpl.html',
                    scope: {
                        url:'@',
                        urlItem:'@',
                        columnSettings:'=?',
                        searchSettings:'=?'
                    },
                    link: {
                        pre: function(scope, element, attrs, ctrl, transclude){
                        },
                        post: function(scope, element, attrs){
                            $http({
                                url: scope.url,
                                method: 'POST'
                            }).success(function(response) {
                                scope.data=[];
                                var flags= [];
                                for(var i=0; i<response.length; i++) {
                                    if(flags[response[i].level1]) continue;
                                    flags[response[i].level1] = true;
                                    scope.data.push(response[i].level1);
                                }
                            });
                        }
                    },
                    controller: ['$scope', function($scope){
                        $scope.scrollDisabled = false;
                        $scope.cpt = 0;
                        $scope.sortedDatas = [];

                        $scope.loadMore = function(){

                            if ($scope.scrollDisabled) return;
                            $scope.scrollDisabled = true;

                            if($scope.cpt < $scope.data.length){
                                var currentLevel1Id = $scope.data[$scope.cpt];

                                $http({
                                    url: $scope.urlItem + '/' + currentLevel1Id,
                                    method: 'GET'
                                }).success(function(response) {

                                    var currentLevel1;
                                    var currentLevel2;

                                    angular.forEach(response.structure, function(value, key){
                                        if(!angular.isDefined(currentLevel1)){
                                            currentLevel1 = value.level1;
                                            currentLevel1.urlData = response.url;
                                            currentLevel1.level2 = [];
                                        }
                                        if(!angular.isDefined(currentLevel2) || value.level2.id != currentLevel2.id){
                                            currentLevel2 = value.level2;
                                            currentLevel2.data = [];
                                            currentLevel2.columnName = value.columnName;
                                            currentLevel2.dataName = value.dataName;
                                            currentLevel1.level2.push(currentLevel2);
                                        }
                                    });
                                    $scope.cpt++;
                                    $scope.sortedDatas.push(currentLevel1);
                                    $scope.scrollDisabled = false;
                                });
                            }

                        };
                    }]
                };
            }])
            ;
    });
})(requirejs);

