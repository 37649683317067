(function (requirejs) {
'use strict';


    define('configuration/site/service',['angular'],
        function(angular) {

            angular.module('eezycleanApp.configuration.site.service', ['ngRoute', 'pascalprecht.translate'])


            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/configuration/site/service', {
                    templateUrl: '/partials/configuration/site/service/list.tpl.html'
                });
                $routeProvider.when('/configuration/site/service/new', {
                    templateUrl: '/partials/configuration/site/service/create.tpl.html'
                });
                $routeProvider.when('/configuration/site/service/:id', {
                    templateUrl: '/partials/configuration/site/service/edit.tpl.html'
                });
            }]);

        }
    );
})(requirejs);

