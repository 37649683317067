(function (requirejs) {
'use strict';

    define('../lib/eezymanager-securesocial/javascripts/directives',['angular'], function(angular) {



        /* Directives */

        angular.module('eezymanager.securesocial.directives', ['pascalprecht.translate'])
            .directive('eezyClientSelector', ['$rootScope', '$http', '$route', 'AUTHORIZATION_EVENTS', 'AUTH_EVENTS', function($rootScope, $http, $route, AUTHORIZATION_EVENTS, AUTH_EVENTS) {
                return {
                    restrict: 'E',
                    templateUrl: '/eezymanager/securesocial/partials/clientSelector.tpl.html',
                    scope: true,
                    link: function(scope, element, attrs) {

                        var cb = function() {
                            $http.get('/authorization/client')
                                .success(function(response) {
                                    scope.client = response.clientId;
                                    scope.$watch('client', function (newValue, oldValue) {
                                        if (newValue != oldValue) {
                                            $http({
                                                method: 'POST',
                                                url:    '/authorization/set-client',
                                                data: {
                                                    clientId: newValue
                                                }
                                            }).success(function(response) {
                                                $rootScope.$broadcast(AUTHORIZATION_EVENTS.clientChanged);
                                                window.location.reload();
                                            });
                                        }
                                    });
                                });

                            $http.get('/authorization/available-clients')
                                .success(function(response) {
                                    scope.clients = response;
                                });
                        };

                        $rootScope.$on(AUTH_EVENTS.loginSuccess, cb);
                        $rootScope.$on(AUTHORIZATION_EVENTS.employeeChanged, cb);

                        cb();
                    }
                };
            }])

            .directive('eezyEmployeeSelector', ['$rootScope', '$http', '$route', 'AUTHORIZATION_EVENTS', 'AUTH_EVENTS', function($rootScope, $http, $route, AUTHORIZATION_EVENTS, AUTH_EVENTS) {
                return {
                    restrict: 'E',
                    templateUrl: '/eezymanager/securesocial/partials/employeeSelector.tpl.html',
                    scope: true,
                    link: function(scope, element, attrs) {

                        var cb = function() {
                            $http.get('/authorization/employee')
                                .success(function(response) {
                                    scope.employee = response.employeeId;
                                    scope.$watch('employee', function (newValue, oldValue) {
                                        if (newValue != oldValue) {
                                            $http({
                                                method: 'POST',
                                                url:    '/authorization/set-employee',
                                                data: {
                                                    employeeId: newValue
                                                }
                                            }).success(function(response) {
                                                $rootScope.$broadcast(AUTHORIZATION_EVENTS.employeeChanged);
                                                window.location.reload();
                                            });
                                        }
                                    });
                                });

                            $http.get('/authorization/available-employees')
                                .success(function(response) {
                                    scope.employees = response;
                                });
                        };

                        $rootScope.$on(AUTHORIZATION_EVENTS.clientChanged, cb);
                        $rootScope.$on(AUTH_EVENTS.loginSuccess, cb);

                        cb();
                    }
                };
            }])

            .directive('eezyAccessPermissions', ['Session', '$rootScope', function(Session, $rootScope) {
                return {
                    restrict: 'A',
                    link: function(scope, element, attrs) {
                        var check = function() {
                            var authorized = false;
                            var permissions = attrs.eezyAccessPermissions;
                            var checkType = attrs.eezyAccessCheckType;
                            authorized = Session.checkPermissions(permissions, checkType);

                            if (authorized) {
                                element.removeClass('hidden');
                            } else {
                                element.addClass('hidden');
                            }
                        };

                        check();

                        $rootScope.$watch('session.identity', check);
                    }
                };
            }])

            .directive('eezyAccessPermissions', ['Session', '$rootScope', function(Session, $rootScope) {
                return {
                    restrict: 'E',
                    transclude: true,
                    link: function(scope, element, attrs, ctrl, transclude) {
                        var check = function() {
                            var authorized = false;
                            var permissions = attrs.permissions;
                            var checkType = attrs.checkType;
                            authorized = Session.checkPermissions(permissions, checkType);

                            if (authorized) {
                                transclude(function(clone) {
                                    element.append(clone);
                                });
                            }
                        };

                        check();
                    }
                };
            }])

            .directive('eezyAccessRoles', ['Session', '$rootScope', function(Session, $rootScope) {
                return {
                    restrict: 'A',
                    link: function(scope, element, attrs) {

                        var check = function() {
                            var authorized = false;
                            var roles = attrs.eezyAccessRoles;
                            var checkType = attrs.eezyAccessCheckType;
                            authorized = Session.checkRoles(roles, checkType);

                            if (authorized) {
                                element.removeClass('hidden');
                            } else {
                                element.addClass('hidden');
                            }
                        };

                        check();

                        $rootScope.$watch('session.identity', check);
                    }
                };
            }])

            .directive('eezyAccessRoles', ['Session', '$rootScope', function(Session, $rootScope) {
                return {
                    restrict: 'E',
                    transclude: true,
                    link: function(scope, element, attrs, ctrl, transclude) {
                        var check = function() {
                            var authorized = false;
                            var roles = attrs.roles;
                            var checkType = attrs.checkType;
                            authorized = Session.checkRoles(roles, checkType);

                            if (authorized) {
                                transclude(function(clone) {
                                    element.append(clone);
                                });
                            }
                        };

                        check();
                    }
                };
            }])

            .directive('eezyAccessAuthenticated', ['Session', '$rootScope', function(Session, $rootScope) {
                return {
                    restrict: 'A',
                    link: function(scope, element, attrs) {

                        var check = function() {
                            var authorized = Session.authenticated;

                            if (!authorized) {
                                element.addClass('hidden');
                            }
                        };

                        check();
                    }
                };
            }])

            .directive('eezyAccessAuthenticated', ['Session', '$rootScope', function(Session, $rootScope) {
                return {
                    restrict: 'E',
                    transclude: true,
                    link: function(scope, element, attrs, ctrl, transclude) {
                        var check = function() {
                            var authorized = Session.authenticated;

                            if (authorized) {
                                transclude(function(clone) {
                                    element.append(clone);
                                });
                            }
                        };

                        check();
                    }
                };
            }]);
    });
})(requirejs);


