(function (requirejs) {
'use strict';


define('../lib/eezymanager-core/javascripts/configuration/role',['angular'],
    function(angular) {

        angular.module('eezymanager.core.configuration.role', ['ngRoute', 'pascalprecht.translate'])


        // Routes
        .config(['$routeProvider', function($routeProvider) {
            $routeProvider.when('/configuration/role', {
                templateUrl: '/eezymanager/core/partials/configuration/role/list.tpl.html'
            });
            $routeProvider.when('/configuration/role/new', {
                templateUrl: '/eezymanager/core/partials/configuration/role/create.tpl.html'
            });
            $routeProvider.when('/configuration/role/:id', {
                templateUrl: '/eezymanager/core/partials/configuration/role/edit.tpl.html'
            });
        }]);

    });
})(requirejs);

