(function (requirejs) {
'use strict';

function RGB2HSV(rgb) {
    var hsv = {};
    var max=max3(rgb.r,rgb.g,rgb.b);
    var dif=max-min3(rgb.r,rgb.g,rgb.b);
    hsv.saturation=(max===0.0)?0:(100*dif/max);
    if (hsv.saturation===0) hsv.hue=0;
    else if (rgb.r===max) hsv.hue=60.0*(rgb.g-rgb.b)/dif;
    else if (rgb.g===max) hsv.hue=120.0+60.0*(rgb.b-rgb.r)/dif;
    else if (rgb.b===max) hsv.hue=240.0+60.0*(rgb.r-rgb.g)/dif;
    if (hsv.hue<0.0) hsv.hue+=360.0;
    hsv.value=Math.round(max*100/255);
    hsv.hue=Math.round(hsv.hue);
    hsv.saturation=Math.round(hsv.saturation);
    return hsv;
}

// RGB2HSV and HSV2RGB are based on Color Match Remix [http://color.twysted.net/]
// which is based on or copied from ColorMatch 5K [http://colormatch.dk/]
function HSV2RGB(hsv) {
    var rgb={};
    if (hsv.saturation===0) {
        rgb.r=rgb.g=rgb.b=Math.round(hsv.value*2.55);
    } else {
        hsv.hue/=60;
        hsv.saturation/=100;
        hsv.value/=100;
        var i=Math.floor(hsv.hue);
        var f=hsv.hue-i;
        var p=hsv.value*(1-hsv.saturation);
        var q=hsv.value*(1-hsv.saturation*f);
        var t=hsv.value*(1-hsv.saturation*(1-f));
        switch(i) {
        case 0: rgb.r=hsv.value; rgb.g=t; rgb.b=p; break;
        case 1: rgb.r=q; rgb.g=hsv.value; rgb.b=p; break;
        case 2: rgb.r=p; rgb.g=hsv.value; rgb.b=t; break;
        case 3: rgb.r=p; rgb.g=q; rgb.b=hsv.value; break;
        case 4: rgb.r=t; rgb.g=p; rgb.b=hsv.value; break;
        default: rgb.r=hsv.value; rgb.g=p; rgb.b=q;
        }
        rgb.r=Math.round(rgb.r*255);
        rgb.g=Math.round(rgb.g*255);
        rgb.b=Math.round(rgb.b*255);
    }
    return rgb;
}

//Adding HueShift via Jacob (see comments)
function hueShift(h,s) {
    h+=s; while (h>=360.0) h-=360.0; while (h<0.0) h+=360.0; return h;
}

//min max via Hairgami_Master (see comments)
function min3(a,b,c) {
    return (a<b)?((a<c)?a:c):((b<c)?b:c);
}
function max3(a,b,c) {
    return (a>b)?((a>c)?a:c):((b>c)?b:c);
}

/**
*
*  Javascript string pad
*  http://www.webtoolkit.info/
*
**/

var STR_PAD_LEFT = 1;
var STR_PAD_RIGHT = 2;
var STR_PAD_BOTH = 3;

function strpad(str, len, pad, dir) {

    if (typeof(len) == "undefined") { len = 0; }
    if (typeof(pad) == "undefined") { pad = ' '; }
    if (typeof(dir) == "undefined") { dir = STR_PAD_RIGHT; }

    if (len + 1 >= str.length) {

        switch (dir){

            case STR_PAD_LEFT:
                str = new Array(len + 1 - str.length).join(pad) + str;
            break;

            case STR_PAD_BOTH:
                var right = Math.ceil((padlen = len - str.length) / 2);
                var left = padlen - right;
                str = new Array(left+1).join(pad) + str + new Array(right+1).join(pad);
            break;

            default:
                str = str + new Array(len + 1 - str.length).join(pad);
            break;

        } // switch

    }

    return str;

}


    define('../lib/eezymanager-common/javascripts/filters',['angular'], function(angular) {

        /* Filters */

        angular.module('eezymanager.common.filters', [])
            .filter('interpolate', ['version', function(version) {
                return function(text) {
                    return String(text).replace(/\%VERSION\%/mg, version);
                };
            }])
            .filter('invertColor', function() {
                return function(hexTripletColor) {
                    var color = hexTripletColor;
                    color = color.substring(1);           // remove #
                    color = parseInt(color, 16);          // convert to integer
                    color = 0xFFFFFF ^ color;             // invert three bytes
                    color = color.toString(16);           // convert to hex
                    color = ("000000" + color).slice(-6); // pad with leading zeros
                    color = "#" + color;                  // prepend #
                    return color;
                };
            })
            .filter('complementColor', function() {
                return function(hexTripletColor) {
                    if (angular.isDefined(hexTripletColor)) {
                        var color = hexTripletColor;
                        color = color.substring(1);           // remove #
                        var rgb = {
                            r: parseInt(color.substring(0, 2), 16),
                            g: parseInt(color.substring(2, 4), 16),
                            b: parseInt(color.substring(4, 6), 16)
                        };

                        // complement
                        var temprgb=rgb;
                        var temphsv=RGB2HSV(temprgb);
                        temphsv.hue=hueShift(temphsv.hue,180.0);

                        rgb=HSV2RGB(temphsv);
                        var pad = '00';
                        color = '#' +
                            strpad(rgb.r.toString(16), 2, '0', STR_PAD_LEFT) +
                            strpad(rgb.g.toString(16), 2, '0', STR_PAD_LEFT) +
                            strpad(rgb.b.toString(16), 2, '0', STR_PAD_LEFT);

                        return color;
                    } else {
                        return hexTripletColor;
                    }
                };
            })
            .filter('textColor', function() {
                return function(hexTripletColor) {
                    if (angular.isDefined(hexTripletColor)) {
                        var color = hexTripletColor;
                        color = color.substring(1);           // remove #
                        var rgb = {
                            r: parseInt(color.substring(0, 2), 16),
                            g: parseInt(color.substring(2, 4), 16),
                            b: parseInt(color.substring(4, 6), 16)
                        };

                        var gamma = 2.2;
                        var l = 0.2126 * Math.pow( rgb.r / 255.0, gamma ) + 0.7152 * Math.pow( rgb.g / 255.0, gamma ) + 0.0722 * Math.pow( rgb.b / 255.0, gamma );

                        if (l > 0.5) {
                            return '#000000';
                        } else {
                            return '#ffffff';
                        }
                        /*
                        // complement
                        var hsv=RGB2HSV(rgb);

                        if (hsv.value > 50.0) {
                            return '#000000';
                        } else {
                            return '#ffffff';
                        }*/
                    } else {
                        return hexTripletColor;
                    }
                };
            })
            ;

    });
})(requirejs);

