(function (requirejs) {
'use strict';


define('../lib/eezymanager-core/javascripts/form',['angular'],
    function(angular) {

        angular.module('eezymanager.core.form', ['ngRoute', 'pascalprecht.translate'])
            .constant('EEZY_REFRESH_ICON', 'core.icon.refresh')
            .directive('eezyIconSelector', ['$http', '$parse', function($http, $parse) {
                function setValue(value, output) {
                    angular.forEach(output, function(option) {
                        option.ticked = false;
                    });

                    angular.forEach(output, function(option) {
                        if (option.name == value) {
                            option.ticked = true;
                        }
                    });
                }

                return {
                    restrict: 'E',
                    scope: {
                        model: '='
                    },
                    templateUrl: '/eezymanager/core/partials/form/iconSelector.tpl.html',
                    link: function(scope, element, attr) {
                        scope.id = 'icon_selector_' + Math.floor((Math.random()*9999)+1);
                        scope.$watch('output', function(output) {
                            if (angular.isDefined(output) && output !== null && angular.isDefined(output.originalObject)) {
                                scope.model = output.originalObject.name;
                            }
                        });
                        scope.$watch('model', function(model) {
                            scope.$evalAsync(function() {
                                element.find('input').val(model);
                            });
                        });
                    }
                };
            }])
            .directive('eezyLanguageSelector', function() {
                return {
                    restrict: 'E',
                    scope: {
                        model: '='
                    },
                    templateUrl: '/eezymanager/core/partials/form/languageSelector.tpl.html'
                };
            })
            .directive('eezyDisplayIcon', ['$http','$routeParams', 'EezyUrl', 'EEZY_REFRESH_ICON', function($http, $routeParams, EezyUrl, EEZY_REFRESH_ICON){
                return {
                    restrict: 'E',
                    scope: {
                        cssClass: '@',
                        url: '@',
                        deleteIconUrl:'@',
                    },
                    templateUrl: '/eezymanager/core/partials/form/iconDisplay.tpl.html',
                    link: function(scope, element, attr){
                        var url = EezyUrl.generate(scope.url, $routeParams);

                        scope.refresh = function(){
                            $http({
                                method: 'GET',
                                url: url
                            }).success(function(response){
                                scope.icons = response;
                            });
                        };

                        scope.refresh();

                        scope.$on(EEZY_REFRESH_ICON, function() {
                            scope.refresh();
                        });

                        scope.deleteIcon = function(){
                            var url = EezyUrl.generate(scope.deleteIconUrl, $routeParams);

                            $http({
                                method: 'DELETE',
                                url: url
                            }).success(function(response){
                                scope.refresh();
                            });

                        };
                    }
                };
            }]);

    });
})(requirejs);

