(function (requirejs) {
'use strict';


    define('configuration/site/typeCritereEvaluation',['angular'],
        function(angular) {

            angular.module('eezycleanApp.configuration.site.typeCritereEvaluation', ['ngRoute', 'pascalprecht.translate'])


            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/configuration/site/type-critere-evaluation', {
                    templateUrl: '/partials/configuration/site/typeCritereEvaluation/list.tpl.html'
                });
                $routeProvider.when('/configuration/site/type-critere-evaluation/new', {
                    templateUrl: '/partials/configuration/site/typeCritereEvaluation/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.Configuration.Site.TypeCritereEvaluation.Create.Title';
                        $scope.mode = 'create';
                        $scope.url = '/configuration/site/type-critere-evaluation';
                    }]
                });
                $routeProvider.when('/configuration/site/type-critere-evaluation/:id', {
                    templateUrl: '/partials/configuration/site/typeCritereEvaluation/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.Configuration.Site.TypeCritereEvaluation.Edit.Title';
                        $scope.mode = 'edit';
                        $scope.url = '/configuration/site/type-critere-evaluation/:id';
                    }]
                });
            }]);

        }
    );
})(requirejs);

