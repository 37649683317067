(function (requirejs) {
'use strict';

    requirejs.config({
        paths: {
            'angular': ['//cdn.jsdelivr.net/webjars/angularjs/1.4.0/angular.min'],
            'angular-route': ['//cdn.jsdelivr.net/webjars/angularjs/1.4.0/angular-route.min'],
            'angular-sanitize': ['//cdn.jsdelivr.net/webjars/angularjs/1.4.0/angular-sanitize.min'],
            'angular-cookies': ['//cdn.jsdelivr.net/webjars/angularjs/1.4.0/angular-cookies.min'],
            'angular-resource': ['//cdn.jsdelivr.net/webjars/angularjs/1.4.0/angular-resource.min'],
            'angular-animate': ['//cdn.jsdelivr.net/webjars/angularjs/1.4.0/angular-animate.min'],
            'angular-datatables': ['//cdn.jsdelivr.net/webjars/angular-datatables/0.4.3/angular-datatables.min'],
            'angular-translate': ['//cdn.jsdelivr.net/webjars/angular-translate/2.7.2/angular-translate.min'],
            'angular-translate-loader-static-files': ['../lib/angular-translate-loader-static-files/angular-translate-loader-static-files'],
            'angular-multiselect': ['//cdn.jsdelivr.net/webjars/angular-multi-select/3.0.0/isteven-multi-select'],
            'angular-switchery': ['../lib/angular-switchery/ng-switchery'],
            'angular-dynamic-locale': ['//cdn.jsdelivr.net/webjars/angular-dynamic-locale/0.1.27/tmhDynamicLocale.min'],
            'angular-growl-2': ['//cdn.jsdelivr.net/webjars/angular-growl-2/0.7.3/angular-growl.min'],

            'checklist-model': ['../lib/checklist-model/checklist-model'],

            'jquery': ['//cdn.jsdelivr.net/webjars/jquery/1.11.1/jquery.min'],
            'jquery-migrate': ['//cdn.jsdelivr.net/webjars/jquery-migrate/1.2.1/jquery-migrate.min'],
            'jquery-ui': ['//cdn.jsdelivr.net/webjars/jquery-ui/1.11.4/jquery-ui.min'],
            'jquery-hashchange': ['../lib/jquery-hashchange/jquery.hashchange.min'],
            'jquery-slimscroll': ['//cdn.jsdelivr.net/webjars/jQuery-slimScroll/1.3.3/jquery.slimscroll.min'],
            'jquery-cookie': ['//cdn.jsdelivr.net/webjars/jquery-cookie/1.3.1/jquery-cookie'],
            'jquery-datatables': ['../lib/jquery-datatables/js/jquery.dataTables'],
            'datatables-bootstrap': ['../lib/jquery-datatables/js/dataTables.bootstrap'],
            'jquery-datatables-tabletools': ['../lib/jquery-datatables/extensions/TableTools/js/dataTables.tableTools'],

            'jquery-flot': ['../lib/flot'],
            'moment': '//cdn.jsdelivr.net/webjars/momentjs/2.9.0/moment',
            'moment-timezone': '//cdn.jsdelivr.net/webjars/moment-timezone/0.5.1/moment-timezone-with-data',
            'pace': ['../lib/pace/pace'],
            'fullcalendar': ['//cdn.jsdelivr.net/webjars/fullcalendar/2.4.0/fullcalendar.min'],
            'fullcalendar-locale-fr': ['//cdn.jsdelivr.net/webjars/fullcalendar/2.4.0/lang/fr'],

            'angucomplete': ['../lib/angucomplete/angucomplete'],

            'bootstrap': ['//cdn.jsdelivr.net/webjars/bootstrap/3.3.2-2/js/bootstrap.min'],
            'bootstrap-datepicker': ['//cdn.jsdelivr.net/webjars/bootstrap-datepicker/1.4.0/js/bootstrap-datepicker.min'],
            'bootstrap-datepicker-locale-fr': ['//cdn.jsdelivr.net/webjars/bootstrap-datepicker/1.4.0/locales/bootstrap-datepicker.fr.min'],

            'ui-sortable': ['//cdn.jsdelivr.net/webjars/angular-ui-sortable/0.13.0/sortable.min'],

            'coloradmin': ['../lib/coloradmin/js/apps'],
            'dropzone': ['//cdn.jsdelivr.net/webjars/dropzone/4.0.1/dropzone'],
            'lightbox': ['../lib/lightbox/js/lightbox-2.6.min'],
            
            'infinite-scroll': ['../lib/infinite-scroll/ng-infinite-scroll'],

            'mp.deepBlur': ['../lib/deep-blur/angular-deep-blur'],

            'ngFileUploadShim': ['../lib/ng-file-upload/ng-file-upload-shim'],
            'ngFileUpload': ['../lib/ng-file-upload/ng-file-upload'],

            'ui-bootstrap' : ['//cdn.jsdelivr.net/webjars/angular-ui-bootstrap/0.13.0/ui-bootstrap-tpls.min'],

            'ngBusy' : ['../lib/angular-busy/angular-busy'],

            'ionSliderAngular' : ['../lib/ion-range-slider-angular-js/slider'],
            'ionSlider' : ['../lib/ion-rangeslider/js/ion.rangeSlider'],
            'ngDraggable' : ['../lib/ngDraggable/ngDraggable'],

            'chartjs' : ['../lib/chartjs/dist/Chart.min'],
            'moment-range' : ['../lib/moment-range/dist/moment-range.min']

//            'eezymanager-common': ['../lib/eezymanager-common/javascripts'],
//            'eezymanager-core': ['../eezymanager/core/js'],
//            'eezymanager-securesocial': ['../eezymanager/securesocial/js']
        },
        shim: {
            'angular': {
                deps: ['jquery'],
                exports: 'angular'
            },
            'angular-sanitize': {
                deps: ['angular'],
                exports: 'angular'
            },
            'angular-route': {
                deps: ['angular'],
                exports: 'angular'
            },
            'angular-cookies': {
                deps: ['angular'],
                exports: 'angular'
            },
            'angular-resource': {
                deps: ['angular'],
                exports: 'angular'
            },
            'angular-animate': {
                deps: ['angular'],
                exports: 'angular'
            },
            'angular-translate': {
                deps: ['angular'],
                exports: 'angular'
            },
            'angular-translate-loader-static-files': {
                deps: ['angular', 'angular-translate'],
                exports: 'angular'
            },
            'angular-dynamic-locale': {
                deps: ['angular'],
                exports: 'angular'
            },
            'angular-multiselect': {
                deps: ['angular'],
                exports: 'angular'
            },
            'angular-switchery': {
                deps: ['angular'],
                exports: 'angular'
            },
            'angular-growl-2': {
                deps: ['angular'],
                exports: 'angular'
            },
            'checklist-model': {
                deps: ['angular'],
                exports: 'angular'
            },
            'jquery': {
                exports: 'jQuery'
            },
            'jquery-migrate': {
                deps: ['jquery']
            },
            'jquery-ui': {
                deps: ['jquery']
            },
            'jquery-hashchange': {
                deps: ['jquery']
            },
            'jquery-slimscroll': {
                deps: ['jquery'],
                export: ['jQuery.fn.slimScroll']
            },
            'jquery-datatables': {
                deps: ['jquery'],
                exports: 'jQuery.fn.dataTable'
            },
            'jquery-flot/jquery.flot': {
                deps: ['jquery'],
                exports: 'jQuery.fn.flot'
            },
            'jquery-flot/jquery.flot.categories': {
                deps: ['jquery', 'jquery-flot/jquery.flot'],
                exports: 'jQuery.fn.flot'
            },
            'jquery-flot/jquery.flot.stack': {
                deps: ['jquery', 'jquery-flot/jquery.flot']
            },
            'jquery-flot/jquery.flot.resize': {
                deps: ['jquery', 'jquery-flot/jquery.flot']
            },
            'datatables-bootstrap': {
                deps: ['jquery-datatables']
            },
            'jquery-datatables-tabletools': {
                deps: ['jquery-datatables']
            },
            'ui-sortable': {
                deps: ['angular', 'jquery']
            },
            'pace': {
                deps: ['ngFileUpload'],
                exports: 'Pace'
            },
            'coloradmin': {
                deps: ['jquery', 'jquery-ui', 'jquery-datatables', 'jquery-slimscroll', 'bootstrap', 'pace']
            },
            'lightbox': {
                deps: ['jquery']
            },
            'bootstrap': {
                deps: ['jquery']
            },
            'bootstrap-datepicker': {
                deps: ['bootstrap', 'jquery', 'jquery-ui']
            },
            'bootstrap-datepicker-locale-fr': {
                deps: ['bootstrap-datepicker']
            },
            'angular-datatables': {
                deps: ['angular', 'jquery', 'jquery-datatables', 'jquery-datatables-tabletools'],
                exports: 'angular'
            },
            'jquery-cookie': {
                deps: ['angular'],
                exports: 'angular'
            },
            'dropzone': {
                deps: ['jquery'],
                exports: 'jQuery.fn.dropzone'
            },
            'angucomplete': {
                deps: ['angular']
            },
            'infinite-scroll': {
                deps: ['angular', 'jquery']
            },
            'mp.deepBlur': {
                deps: ['angular']
            },
            'ngFileUploadShim': {
                deps: ['angular']
            },
            'ngFileUpload': {
                deps: ['angular', 'ngFileUploadShim']
            },
            'moment-timezone': {
                deps: ['moment']
            },
            'fullcalendar': {
                deps: ['jquery', 'moment'],
                exports: 'jQuery.fn.fullcalendar'
            },
            'fullcalendar-locale-fr': {
                deps: ['fullcalendar']
            },
            'ui-bootstrap': {
                deps :['angular']
            },
            'ngBusy':{
                deps :['angular']
            },
            'ionSlider':{
                deps :['jquery']
            },
            'ionSliderAngular':{
                deps :['jquery','angular','ionSlider']
            },
            'ngDraggable': {
                deps :['angular']
            },
            'chartjs':{
                deps: ['jquery', 'moment'],
                exports: 'Chart'
            },
            'moment-range':{
                deps: ['jquery', 'moment']
            }
        }
    });

    requirejs.onError = function (err) {
        console.log(err);
    };

    require([
        'angular',
        'jquery',
        './controllers',
        'pace',
        './directives',
        './filters',
        './services',
        './admin',
        './configuration',
        './audit',
        './actionsplan',
        './ctrlcont/controleContradictoire',
        './evaluation/evaluation',
        './declaration/declaration',
        './planning/planning',

        './planning/duplicateweek',

        '../lib/eezymanager-common/javascripts/eezymanager-common',
        '../lib/eezymanager-core/javascripts/eezymanager-core',
        '../lib/eezymanager-securesocial/javascripts/eezymanager-securesocial',
        '../lib/eezymanager-ged/javascripts/eezymanager-ged',
        'coloradmin',
        'angular-route',
        'angular-sanitize',
        'angular-cookies',
        'angular-resource',
        'angular-animate',
        'angular-translate',
        'angular-translate-loader-static-files',
        'angular-dynamic-locale',
        'infinite-scroll',
        'angular-datatables',
        'datatables-bootstrap',
        'angular-multiselect',
        'angular-switchery',
        'angular-growl-2',
        'checklist-model',
        'ui-sortable',
        'jquery-flot/jquery.flot',
        'jquery-flot/jquery.flot.categories',
        'jquery-flot/jquery.flot.stack',
        'jquery-flot/jquery.flot.resize',
        'bootstrap-datepicker',
        'bootstrap-datepicker-locale-fr',
        'dropzone',
        'mp.deepBlur',
        'ngFileUpload',
        'ngBusy',
        'ionSliderAngular',
        'angucomplete',
        'lightbox',
        'fullcalendar',
        'fullcalendar-locale-fr',
        'ui-bootstrap',
        'ngDraggable',
        'moment-timezone',
        'chartjs',
        'moment-range'
    ],
    function(angular, jQuery, controllers, pace) {
        angular.module('eezycleanApp', ['eezycleanApp.admin',
                                        'eezycleanApp.configuration',
                                        'eezycleanApp.audit',
                                        'eezycleanApp.ctrlcont.controleContradictoire',
                                        'eezycleanApp.evaluation.evaluation',
                                        'eezycleanApp.declaration.declaration',
                                        'eezycleanApp.actionsplan',
                                        'eezycleanApp.planning',
                                        'eezymanager.common',
                                        'eezymanager.core',
                                        'eezymanager.securesocial',
                                        'eezymanager.ged',

                                        'eezycleanApp.planning.duplicateweek',
                                        'ngRoute',
                                        'ngAnimate',
                                        'ngSanitize',
                                        'datatables',
                                        'ngResource',
                                        'NgSwitchery',
                                        'ui.sortable',
                                        'angucomplete',
                                        'infinite-scroll',
                                        'ionSlider',
                                        'angular-growl',
                                        'ui.bootstrap.timepicker',
                                        'ngDraggable'
                                        ])
            .config(['$httpProvider', function($httpProvider) {
                $httpProvider.interceptors.push(['EezyNotify', '$q', function(EezyNotify, $q) {
                    return {
                        'responseError': function(rejection) {
                            if (rejection.status >= 500 && rejection.status < 600) {
                                if (angular.isDefined(rejection.data) && rejection.data !== null && rejection.data !== '') {
                                    EezyNotify.error(rejection.data);
                                } else {
                                    EezyNotify.error('Error ' + rejection.status + ': ' + rejection.statusText);
                                }
                            }
                            return $q.reject(rejection);
                        }
                    };
                }]);
            }])
            .config(['$routeProvider', '$locationProvider', function($routeProvider, $locationProvider) {
                $locationProvider.html5Mode(false);

                $routeProvider.otherwise({
                    redirectTo: '/'
                });
            }])
            .factory('missingTranslationHandler', ['$http', function ($http) {


                return function (translationID, uses) {
                    if (!angular.isDefined(window.missingTranslations)) {
                        window.missingTranslations = {};
                    }
                    if (!angular.isDefined(window.missingTranslations[uses])) {
                        window.missingTranslations[uses] = [];
                        window.missingTranslations[uses].print = function() {
                            for (var i = 0; i < window.missingTranslations[uses].length; i ++) {
                                console.log(window.missingTranslations[uses][i]);
                            }
                        };
                        window.missingTranslations[uses].send = function() {
                            $http({
                                method: 'POST',
                                url: 'missing-translation',
                                data: {
                                    lang: uses,
                                    translationIds: window.missingTranslations[uses]
                                }
                            });
                        };
                    }
                    if (window.missingTranslations[uses].indexOf(translationID) == -1) {
                        window.missingTranslations[uses].push(translationID);
                    }


                };
            }])
            .config(['$translateProvider', 'tmhDynamicLocaleProvider', function($translateProvider, tmhDynamicLocaleProvider) {
                $translateProvider.useStaticFilesLoader({
                files:[
                    {
                        prefix: 'eezymanager/securesocial/locales/locale-',
                        suffix: '.json'
                    },
                    {
                        prefix: 'translations/',
                        suffix: ''
                    }
                ]});
                $translateProvider.useSanitizeValueStrategy('sanitizeParameters');
                $translateProvider.preferredLanguage(navigator.language);
                $translateProvider.useMissingTranslationHandler('missingTranslationHandler');
                $translateProvider.forceAsyncReload(true);

                tmhDynamicLocaleProvider.localeLocationPattern("lib/angularjs/i18n/angular-locale_{{locale}}.js");
                tmhDynamicLocaleProvider.useStorage('$cookieStore');
                tmhDynamicLocaleProvider.defaultLocale(navigator.language);
            }])

            .config(['growlProvider', function(growlProvider) {
              //growlProvider.globalPosition('bottom-center');
            }])

            .config(['$httpProvider', function($httpProvider) {
                var nbRequests = 0;
                $httpProvider.interceptors.push(['$q', '$location', 'Session', function($q, $location, Session) {

                    var reqResolve;

                    return {
                        'request': function(config) {
                            if (nbRequests === 0) {
                                pace.start();
                            }
                            nbRequests ++;
                            return config;
                        },
                        // optional method
                        'response': function(response) {
                            nbRequests --;
                            if (nbRequests === 0) {
                                pace.stop();
                            }
                            // do something on success
                            return response;
                        },
                    };
                }]);
            }])
            .run(['$route', angular.noop])
        ;

        angular.bootstrap(document, ['eezycleanApp']);

    });
})(requirejs);

define("main", function(){});

