(function (requirejs) {
'use strict';

  define('services',['angular'], function(angular) {

  /* Services */

  // Demonstrate how to register services
  // In this case it is a simple value service.
  angular.module('eezycleanApp.services', []).
    value('version', '0.1');

  });
})(requirejs);

