(function (requirejs) {
'use strict';


    define('actionsplan/actionsplan',['angular'],
        function(angular) {

            angular.module('eezycleanApp.actionsplan.actionsplan', ['ngRoute', 'pascalprecht.translate'])


            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/actionsplan', {
                    templateUrl: '/partials/action/list.tpl.html'
                });
                $routeProvider.when('/actionsplan/new', {
                    templateUrl: '/partials/action/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.ActionsPlan.Create.Title';
                        $scope.mode = 'create';
                        $scope.url = '/actionsplan/new';
                    }]
                });
                $routeProvider.when('/actionsplan/:id', {
                    templateUrl: '/partials/action/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.ActionsPlan.Edit.Title';
                        $scope.mode = 'edit';
                        $scope.url = '/actionsplan/:id';
                    }]
                });
            }]);
        }
    );
})(requirejs);

