(function (requirejs) {
'use strict';


    define('configuration/consommable',['angular'],
        function(angular) {

            angular.module('eezycleanApp.configuration.consommable', ['ngRoute', 'pascalprecht.translate'])


            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/configuration/consommable', {
                    templateUrl: '/partials/configuration/consommable/list.tpl.html'
                });
                $routeProvider.when('/configuration/consommable/new', {
                    templateUrl: '/partials/configuration/consommable/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.Configuration.Consommable.Create.Title';
                        $scope.mode = 'create';
                        $scope.url = '/configuration/consommable';
                    }]
                });
                $routeProvider.when('/configuration/consommable/:id', {
                    templateUrl: '/partials/configuration/consommable/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.Configuration.Consommable.Edit.Title';
                        $scope.mode = 'edit';
                        $scope.url = '/configuration/consommable/:id';
                    }]
                });
                $routeProvider.when('/consommable/consommable/synthese/:id', {
                    templateUrl: '/partials/consommable/consommable/synthese.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.Synthese.Consommable.Title';
                        //$scope.mode = 'edit';
                        //$scope.url = '/configuration/consommable/:id';
                    }]
                });
            }]);
        }
    );
})(requirejs);

