(function (requirejs) {
'use strict';

    define('../lib/eezymanager-ged/javascripts/directive/filemanager',['angular'], function(angular) {

        angular.module('eezymanager.ged.directive.filemanager', ['mp.deepBlur', 'ngFileUpload', 'cgBusy'])
            .directive('eezyFileManager', ['$http', '$routeParams', '$location', 'EezyUrl', '$httpParamSerializer', '$timeout','Upload', '$q', function($http, $routeParams, $location, EezyUrl, $httpParamSerializer, $timeout, Upload, $q) {

                return {
                    restrict: 'E',
                    scope:{
                        documentsUrl: '@',
                        filesUrl: '@',
                        deleteUrl: '@',
                        updateFileUrl: '@',
                        uploadFileUrl: '@',
                        addFolderUrl: '@',
                        renameNodeUrl: '@',
                        maxDepth:'@',
                        nodeContext: '=',
                        newFolderName: '@',
                        sizeFileLimitMb: '@',
                        allowedTypes:'@'
                    },
                    transclude: false,
                    templateUrl: '/eezymanager/ged/partials/ged/directives/documents.tpl.html',
                    link: function(scope, element, attrs, ctrl) {
                        var limitSize = null;
                        if(scope.sizeFileLimitMb !== null && angular.isDefined(scope.sizeFileLimitMb)) {
                            limitSize = parseInt(scope.sizeFileLimitMb) * 1048576;
                        }
                        scope.breadCrumb = [];
                        scope.folder = {};
                        scope.rename = null;
                        scope.node = {};
                        scope.documents = [];
                        scope.formats = scope.allowedTypes.split(',');

                        var defaultFileIcon = 'fa-file-o';

                        var typesIcons = [
                                            {type: 'application/pdf', icon:'fa-file-pdf-o'}
                        ];

                        //Permet de ne pas envoyer 2 fois la même requête
                        var sending = false;

                        var iconForFiles = function(response) {
                            scope.documents = [];

                            angular.forEach(response, function(node, index) {
                                if(!node.folder) {
                                    angular.forEach(typesIcons, function(typeIcons) {
                                        if(typeIcons.type === node.mimeType) {
                                            node.icon = typeIcons.icon;
                                        }
                                        else{
                                            node.icon = defaultFileIcon;
                                        }
                                    });
                                }
                                scope.documents.push(node);
                            });
                        };

                        var createUrl = function(site, path, download) {
                            var params = {
                                'type': scope.nodeContext.type,
                                'identifier': scope.nodeContext.identifier,
                                'download': download
                            };
                            angular.forEach(path, function(value, index) {
                                params['path[' + index + ']'] = value;
                            });

                            var param = $httpParamSerializer(params);
                            return scope.filesUrl + '?' + param;
                        };

                        scope.$watch('nodeContext', function(newVal) {

                            if(angular.isDefined(newVal)) {
                                switch(newVal.type) {
                                    case 'site':
                                        scope.siteId = newVal.identifier;
                                        scope.selectSite(newVal.identifier);
                                        break;
                                    case 'public':
                                        scope.breadCrumb = [];
                                        scope.selectFolder();
                                        break;
                                }
                            }
                        });

                        scope.hideErrors = function() {
                            element.find('.drop-zone .wrong-type').css('display','none');
                            element.find('.drop-zone .oversized-file').css('display','none');
                            element.find('.drop-zone .subfolder-limit').css('display','none');
                        };

                        scope.showCreateFolderForm = function ($event) {
                            scope.creatingFolder = true;
                            $event.stopPropagation();
                        };

                        scope.selectSite = function(siteId) {
                            scope.hideErrors();
                            scope.newFolder = false;
                            scope.siteId = siteId;
                            scope.breadCrumb = [];
                            scope.selectFolder();
                        };
                        scope.selectFolderNav = function(folder, parent) {
                            scope.hideErrors();
                            scope.selectFolder(folder, parent);
                        };
                        scope.selectFolder = function(folder, parent) {
                            scope.newFolder = false;
                            var path = pathForPost(folder, parent);

                            scope.loading = $http({
                                        method:'POST',
                                        url:scope.documentsUrl,
                                        data: {
                                                'type': scope.nodeContext.type,
                                                'identifier': scope.nodeContext.identifier,
                                                'path': path
                                        }
                                    }).success(function(response) {
                                        iconForFiles(response);
                                        if( folder !== null && angular.isDefined(folder)) {
                                            if (parent === true) {
                                                scope.breadCrumb.pop();
                                            } else {
                                                scope.breadCrumb.push(folder);
                                            }
                                        }
                                    });
                        };

                        scope.displayDocument = function(file) {
                            window.open(createUrl(scope.siteId, pathForPost(file), false));
                        };

                        scope.downloadDocument = function(file) {
                            window.open(createUrl(scope.siteId, pathForPost(file), true), '_self', '');
                        };

                        scope.deleteDocument = function(document) {
                            if(!sending) {
                                sending = true;

                                var path = pathForPost(document);

                                    scope.loading = $http({
                                        method:'POST',
                                        url:scope.deleteUrl,
                                        data: {
                                                'type': scope.nodeContext.type,
                                                'identifier': scope.nodeContext.identifier,
                                                'path': path
                                        }
                                    }).success(function(response) {
                                        iconForFiles(response);
                                        sending = false;
                                    });
                            }
                        };

                        scope.updateFile = function(input, document) {
                            upload(scope.updateFileUrl, input.files, pathForPost(document));
                        };

                        scope.uploadFile = function(input) {
                            upload(scope.uploadFileUrl, input.files, pathForPost());
                        };

                        //Recuperation de l'event de drop
                        scope.$on('eezyDroppedFiles', function(event, args) {
                            var path = [];
                            var returnParent = false;
                            if(args.folder !== null && angular.isDefined(args.folder) && args.folder !== "") {
                                path =  pathForPost(angular.fromJson(args.folder));
                                returnParent = true;
                            }
                            else{
                                path =  pathForPost();
                            }

                            scope.formats = args.format;

                            upload(scope.uploadFileUrl, args.files, path, null, returnParent);
                        });

                        // Recuperation de l'event drop-move
                        scope.$on('filemanager.drop-move', function(event, args) {
                            var targetFolder =  pathForPost(args.folder, args.parent);
                            var document = pathForPost(args.document);

                            var index = scope.documents.indexOf(args.document);
                            if (index !== -1) {
                                scope.documents.splice(index, 1);
                            }

                            scope.loading = $http({
                                url: '/ged/move',
                                method: 'post',
                                data: {
                                    type: scope.nodeContext.type,
                                    identifier: scope.nodeContext.identifier,
                                    document: document,
                                    folder: targetFolder
                                }
                            }).success(function(result) {
                                scope.selectFolder();
                            });
                        });


                        scope.enterEventAddFolder = function($event) {
                             if($event.keyCode === 13) {
                                scope.addFolder();
                             }
                        };

                        scope.createFolder = function() {
                            scope.hideErrors();
                            scope.newFolder = true;
                            if(angular.isDefined(scope.folder) || scope.folder === null) {
                                scope.createFolderName = scope.newFolderName;
                            }
                            else {
                                scope.createFolderName = scope.folder.name;
                            }
                        };


                        //Ajout d'un dossier
                        scope.addFolder = function(key) {
                            scope.hideErrors();
                            if (key === 27) {
                                scope.newFolder = false;
                            } else if (key === 13) {
                                var path = pathForPost();
                                var folderName = scope.createFolderName;

                                $http({
                                    method:'POST',
                                    url:scope.addFolderUrl,
                                    data: {
                                            'type': scope.nodeContext.type,
                                            'identifier': scope.nodeContext.identifier,
                                            'folderName': folderName,
                                            'path': path
                                            }
                                }).success(function(response) {
                                    scope.newFolder = false;
                                    iconForFiles(response);
                                })
                                .error(function(response, status) {
                                    if(status === 400) {
                                        iconForFiles(response);
                                        scope.subfolderLimit = true;
                                    }
                                });
                            }
                        };

                        //Navigation avec le breadcrumb
                        scope.breadCrumbNavigation = function(folder) {
                            scope.hideErrors();
                            var index = folderPosition(folder);
                            var newBreadCrumb = [];
                            var breadCrumb = angular.copy(scope.breadCrumb);

                            for(var i = 0; i < index; i++) {
                                newBreadCrumb.push(breadCrumb[i]);
                            }

                            scope.breadCrumb = newBreadCrumb;
                            scope.selectFolder(folder);
                        };

                        scope.showRenameNode = function($event, document) {
                            scope.hideErrors();
                            $event.stopPropagation();
                            scope.node.identifier = document.identifier;
                            scope.node.nameValue = (document.title !== null && angular.isDefined(document.title)) ? document.title : document.name;
                        };

                        scope.renameNodeKeyDown = function($event, document) {
                            if($event.keyCode === 13) {
                                scope.renameNode($event, document);
                            }
                        };

                        scope.renameNode = function($event, document) {

                            if(!sending) {
                                sending = true;

                                var path = pathForPost(document);

                                $http({
                                    method: 'POST',
                                    url: scope.renameNodeUrl,
                                    data: {
                                        'type': scope.nodeContext.type,
                                        'identifier': scope.nodeContext.identifier,
                                        'newName': scope.node.nameValue,
                                        'path': path
                                    }
                                }).success(function(response) {
                                    iconForFiles(response);
                                    scope.resetNode();
                                    sending = false;
                                });
                            }
                        };

                        scope.resetCreatingFolder = function () {
                            scope.creatingFolder = false;
                        };

                        scope.resetNode = function() {
                            scope.node = {};
                        };

                        scope.closeWarning = function() {
                            scope.subfolderLimit = false;
                        };

                        function upload(url, files, path, document, returnParent) {
                             var fileTooBig = false;
                             angular.forEach(files, function(file) {
                                if(file.size > limitSize) {
                                    fileTooBig = true;
                                }
                             });
                             if (fileTooBig) {
                                element.find('.drop-zone .oversized-file').show();
                             } else {
                                element.find('.drop-zone .oversized-file').hide();
                                 var promise = sendFile(url, files, path, document, returnParent);
                                 promise.then(function(upload) {
                                     scope.uploadedFiles = [];
                                     scope.droppedFiles = [];
                                     scope.selectFolder();
                                 }, function(error) {
                                     //TODO: Error message
                                 });
                             }
                        }

                        //Envoie les fichiers au server
                        function sendFile(url, files, path, document, returnParent) {

                            var promises = [];

                            if(!returnParent) {
                                scope.uploadedFiles = files;
                            }

                            angular.forEach(files, function(file) {

                                var dataSend = {};
                                var deffered  = $q.defer();

                                dataSend.files = file;
                                dataSend.type = scope.nodeContext.type;
                                dataSend.identifier = scope.nodeContext.identifier;
                                dataSend.path = path;
                                dataSend.returnParent = returnParent;

                                file.upload = Upload.upload({
                                    url: url,
                                    data: dataSend
                                });

                                file.upload.then(function (response) {
                                        deffered.resolve(response.data);
                                    }, function (response) {
                                           if (response.status > 0)
                                               deffered.reject();
                                       },
                                       function (evt) {
                                            file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                                    });

                                promises.push(deffered.promise);
                            });
                            return $q.all(promises);
                        }

                        function folderPosition(folder) {
                            var index = -1;

                            angular.forEach(scope.breadCrumb, function(breadCrumbFolder, pos) {
                                if(breadCrumbFolder.identifier === folder.identifier) {
                                    index = pos;
                                }
                            });

                            return index;
                        }

                        function breadCrumbPath(folderPath) {
                            var path = [];
                            var breadCrumb = [];

                            if( angular.isDefined(folderPath) && folderPath !== null ) {
                                breadCrumb = folderPath;
                            }
                            else{
                                breadCrumb = scope.breadCrumb;
                            }
                            angular.forEach(breadCrumb, function(folder) {
                                path.push(folder.name);
                            });

                            return path;
                        }

                        function pathForPost(folder, parent) {
                            var path = [];

                            angular.forEach(scope.breadCrumb, function(folder) {
                                path.push(folder.name);
                            });

                            if (parent === true) {
                                path.pop();
                                path.pop();
                            }

                            if(folder !== null && angular.isDefined(folder)) {
                                path.push(folder.name);
                            }

                            return path;
                        }
                    }
                };
        }])
        //Permet de declancher un click sur un input[type='file'] a partir d'un <a> tag
        .directive('uploadFile', function () {
            return {
                restrict: 'A',
                link: function(scope, element) {
                    element.bind('click', function(e) {
                        angular.element(e.target).siblings('.file').trigger('click');
                    });
                }
            };
         })
         //Drag and drop sur une zone
        .directive('fileDrop', ['$document', function($document) {
            return{
                restrict: 'A',
                scope: {
                    folder: '@?',
                    allowedTypes:'@',
                    folderClosed:'@?',
                    folderOpened:'@?'
                },
                link: function(scope, element, attrs) {
                    var onDragOver = function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        element.children('.drop-zone').addClass('drag-over');
                    };

                    var onDragEnter = function(e) {
                        e.preventDefault();
                        e.stopPropagation();

                        if(scope.folder !== null && angular.isDefined(scope.folder)) {
                            //Permet de ne pas déclencher un dragleave sur les enfants
                            element.children().css('pointer-events','none');
                            element.children('div').children('div').children('i.' + scope.folderClosed)
                                .removeClass(scope.folderClosed)
                                .addClass(scope.folderOpened);
                            element.parent().css('background-color', 'rgb(0,200,0, 0.2)');
                        }
                    };

                    var onDragEnd = function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        if(scope.folder !== null && angular.isDefined(scope.folder)) {
                            element.children('div').children('div').children('i.' + scope.folderOpened)
                                .removeClass(scope.folderOpened)
                                .addClass(scope.folderClosed);
                            element.parent().css('background-color', 'transparent');

                            var parent = findParent(element, 'drop-zone');

                            parent.removeClass('drag-over');

                        }else{
                            element.children('.drop-zone').removeClass('drag-over');
                        }
                    };

                    var loadFile = function(files) {
                        var args = {'files' : files, 'folder' : scope.folder, 'format': scope.allowedTypes.split(',')};
                        scope.$emit('eezyDroppedFiles', args);
                    };

                    $document.bind('dragover', onDragOver);
                    element.bind('dragenter', onDragEnter);

                    element.bind('dragleave', onDragEnd)
                        .bind('drop', function (e) {
                            onDragEnd(e);

                            var types = scope.allowedTypes.split(',');
                            var allowedFiles = [];

                            var dropZone = (scope.folder !== null && angular.isDefined(scope.folder))?  findParent(element, 'drop-zone') : element.children('.drop-zone');

                            angular.forEach(e.originalEvent.dataTransfer.files, function(file) {
                                if(scope.checkAllowedTypes(types, file.type)) {
                                    dropZone.find('.wrong-type').css('display','none');
                                    allowedFiles.push(file);
                                }
                                else{
                                    dropZone.find('.wrong-type').css('display', 'block');
                                }
                            });

                            loadFile(allowedFiles);
                        });

                    function setLimitSize() {

                        var limitSizeMb = null;
                        var limitSizeKb = null;

                        if(scope.sizeFileLimitMb !== null && angular.isDefined(scope.sizeFileLimitMb)) {
                            limitSizeMb = parseInt(scope.sizeFileLimitMb);
                        }
                        else if(scope.$parent.$parent.$parent.$$childHead.sizeFileLimitMb !== null && angular.isDefined(scope.$parent.$parent.$parent.$$childHead.sizeFileLimitMb)) {
                            limitSizeMb = parseInt(scope.$parent.$parent.$parent.$$childHead.sizeFileLimitMb);
                        }

                        if(limitSizeMb !== null) {
                            limitSize = limitSizeMb * 1048576;
                        }
                    }

                    function findParent(parent, parentClass) {
                        //remonte jusqu'au parent recherché
                        while(!angular.isDefined(parent.attr('class')) || parent.attr('class').indexOf(parentClass) <= 0) {
                            parent = parent.parent();
                        }

                        return parent;
                    }
                },
                controller: ['$scope', function($scope) {
                    var TYPE_APPLICATION = 'application/';
                    var TYPE_IMAGE = 'image/';
                    var TYPE_TEXT = 'text/';
                    var TYPE_AUDIO = 'audio/';
                    var TYPE_VIDEO = 'video/';

                    $scope.checkAllowedTypes = function(types, fileType) {
                        if(allowedTypes(types).indexOf(fileType) >= 0) {
                            return true;
                        }
                        return false;
                    };

                    function allowedTypes(types) {

                        var typesTable = [];

                        angular.forEach(types, function(type) {
                            switch(angular.lowercase(type)) {
                                case 'pdf' : typesTable.push(TYPE_APPLICATION + type);
                                        break;
                                case 'mp4' : typesTable.push(TYPE_VIDEO + type);
                            }
                        });

                        return typesTable;
                    }
                }]
            };
        }])

         //Drag and drop sur une zone
        .directive('documentDrop', ['$document', function($document) {
            return {
                restrict: 'A',
                scope: {
                    folder: '=',
                    folderClosed:'@',
                    folderOpened:'@'
                },
                link: function(scope, element, attrs) {
                    scope.$parent.onDragMove = function($event, $data) {
                        if (element.parents('.dragging').length === 0) {
                            if (element.find('.drag-enter').length > 0) {
                                element.find('i.' + scope.folderClosed)
                                    .removeClass(scope.folderClosed)
                                    .addClass(scope.folderOpened);
                            } else {
                                element.find('i.' + scope.folderOpened)
                                    .removeClass(scope.folderOpened)
                                    .addClass(scope.folderClosed);
                            }
                        }
                    };

                    scope.$parent.onDragStop = function($event, $data) {
                        element.find('i.' + scope.folderOpened)
                            .removeClass(scope.folderOpened)
                            .addClass(scope.folderClosed);
                    };

                    scope.$parent.onDropComplete = function($event, $data, parent) {
                        var args = {'document' : $data, 'folder' : scope.folder, 'parent': parent};
                        scope.$emit('filemanager.drop-move', args);
                    };
                }
            };
        }])
        ;
    });
})(requirejs);

