(function (requirejs) {
'use strict';

    define('audit',['angular', './audit/audit'], function(angular) {

        angular.module('eezycleanApp.audit', ['eezycleanApp.audit.audit']);

    });
})(requirejs);

