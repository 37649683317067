(function (requirejs) {
'use strict';

    define('ctrlcont/controleContradictoire',['angular'],
        function(angular) {

            var showController = function($scope) {

            };
            showController.$inject = ['$scope'];


            angular.module('eezycleanApp.ctrlcont.controleContradictoire', ['ngRoute', 'pascalprecht.translate'])

            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/ctrlcont/controle-contradictoire', {
                    templateUrl: '/partials/ctrlcont/controleContradictoire/list.tpl.html',
                    controller: ['$scope', 'Session', function($scope, Session) {
                        $scope.order = [[ Session.hasPermission('ADMIN') ? 3 : 2, 'desc' ]];
                    }]
                });
                $routeProvider.when('/ctrlcont/controle-contradictoire/new', {
                    templateUrl: '/partials/ctrlcont/controleContradictoire/create.tpl.html'
                });
                $routeProvider.when('/ctrlcont/controle-contradictoire/:id/synthese', {
                    templateUrl: '/partials/ctrlcont/controleContradictoire/synthese.tpl.html',
                    controller: showController
                });
                $routeProvider.when('/ctrlcont/controle-contradictoire/:id/resultats', {
                    templateUrl: '/partials/ctrlcont/controleContradictoire/resultats.tpl.html',
                    controller: showController
                });
                $routeProvider.when('/ctrlcont/controle-contradictoire/:id/edit', {
                    templateUrl: '/partials/ctrlcont/controleContradictoire/edit.tpl.html'
                });
                $routeProvider.when('/ctrlcont/controle-contradictoire/suivi', {
                    templateUrl: '/partials/ctrlcont/controleContradictoire/monitoring.tpl.html'
                });
            }]);

        }
    );
})(requirejs);

