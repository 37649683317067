(function (requirejs) {
'use strict';


    define('configuration/agent',['angular'],
        function(angular) {

            angular.module('eezycleanApp.configuration.agent', ['ngRoute', 'pascalprecht.translate'])

            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/configuration/agent', {
                    templateUrl: '/partials/configuration/agent/list.tpl.html'
                });
                $routeProvider.when('/configuration/agent/new', {
                    templateUrl: '/partials/configuration/agent/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.Configuration.Agent.Create.Title';
                        $scope.mode = 'create';
                        $scope.url = '/configuration/agent';
                    }]
                });
                $routeProvider.when('/configuration/agent/:id', {
                    templateUrl: '/partials/configuration/agent/edit.tpl.html',
                    controller: ['$scope', function($scope) {
                        $scope.title = 'EE.Core.Configuration.Agent.Edit.Title';
                        $scope.mode = 'edit';
                        $scope.url = '/configuration/agent/:id';
                    }]
                });
            }]);

        }
    );
})(requirejs);

