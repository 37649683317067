(function (requirejs) {
'use strict';


    define('../lib/eezymanager-securesocial/javascripts/eezymanager-securesocial',['angular', './controllers', './directives', './filters', './services', 'angular-route', './admin/user'],
    function(angular, controllers) {

        // Declare app level module which depends on filters, and services
        angular.module('eezymanager.securesocial', ['eezymanager.securesocial.admin.user', 'eezymanager.securesocial.directives', 'eezymanager.securesocial.filters', 'eezymanager.securesocial.services', 'ngRoute', 'pascalprecht.translate'])
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/login', {
                    templateUrl: '/eezymanager/securesocial/partials/login.tpl.html',
                    controller: controllers.LoginController,
                    access: {
                        allowAnonymous: true
                    }
                });
                $routeProvider.when('/not-authorized', {
                    templateUrl: '/eezymanager/securesocial/partials/notAuthorized.tpl.html'
                });
            }])
            .config(['$httpProvider', function($httpProvider) {
                $httpProvider.interceptors.push(['$q', '$location', 'Session', function($q, $location, Session) {
                    return {
                        'responseError': function(rejection) {
                            if (rejection.status == 401) {
                                Session.destroy();
                                $location.path("/login");
                            }
                            if (rejection.status == 403) {
                                $location.path("/not-authorized");
                            }
                            return $q.reject(rejection);
                        }
                    };
                }]);
            }])
            .run(['$http', 'Session', '$rootScope', 'AUTH_EVENTS', 'AUTHORIZATION_EVENTS', '$route', '$location', 'Language', function($http, Session, $rootScope, AUTH_EVENTS, AUTHORIZATION_EVENTS, $route, $location, Language)  {
                Session.init();
                $http.defaults.headers.common.Accept = 'application/json';
                $http.defaults.headers.common['X-Auth-Token'] = function() {
                    return Session.token;
                };

                var checkRoute = function(route) {
                    if (angular.isDefined(route)) {
                        if (!Session.checkAccess(route.access)) {
                            if (Session.authenticated) {
                                $location.path('/not-authorized');
                            } else {
                                $location.path('/login');
                            }
                        }
                    }
                };


                var getIdentity = function() {
                    Session.ready = false;
                    $http.get('/authorization/identity')
                        .success(function(response) {
                            Session.setIdentity(response);
                            if (angular.isDefined(response.lang) && response.lang !== null) {
                                Language.changeLanguage(response.lang);
                                $rootScope.currentLanguage = response.lang;
                            }
                            $route.reload();
                            checkRoute($route.current.$$route);
                        })
                        .error(function(response) {
                            // TODO error
                            Session.setIdentity({});
                            $route.reload();
                        })
                        ;
                };

                $rootScope.$on(AUTH_EVENTS.loginSuccess, function() {
                    window.location.reload();
                });
                $rootScope.$on(AUTHORIZATION_EVENTS.clientChanged, getIdentity);
                $rootScope.$on(AUTHORIZATION_EVENTS.employeeChanged, getIdentity);

                $rootScope.session = Session;

                getIdentity();


                $rootScope.$on('$routeChangeStart', function (event, next) {
                    if (Session.ready) {
                        checkRoute(next.$$route);
                    }
                });

            }]
        );
    });
})(requirejs);

