(function (requirejs) {
'use strict';

    define('audit/audit',['angular'],
        function(angular) {

            var showController = function($scope) {

            };
            showController.$inject = ['$scope'];


            angular.module('eezycleanApp.audit.audit', ['ngRoute', 'pascalprecht.translate'])

            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/audit/audit', {
                    templateUrl: '/partials/audit/audit/list.tpl.html',
                    controller: ['$scope', 'Session', function($scope, Session) {
                      $scope.order = [[ Session.hasPermission('ADMIN') ? 5 : 4, 'desc' ]];
                    }]
                });
                $routeProvider.when('/audit/audit/new', {
                    templateUrl: '/partials/audit/audit/create.tpl.html'
                });
                $routeProvider.when('/audit/audit/:id/synthese', {
                    templateUrl: '/partials/audit/audit/synthese.tpl.html',
                    controller: showController
                });
                $routeProvider.when('/audit/audit/:id/resultats', {
                    templateUrl: '/partials/audit/audit/resultats.tpl.html',
                    controller: showController
                });
                $routeProvider.when('/audit/audit/:id/edit', {
                    templateUrl: '/partials/audit/audit/edit.tpl.html'
                });
            }])
            .directive('eezyAuditStackedBar', ['$parse', '$translate', 'EezyPromise', function($parse, $translate, EezyPromise) {
                return {
                    restrict: 'E',
                    scope: {
                        data: '=',
                        legends: '=?',
                        mode: '@',
                        periodicite: '='
                    },
                    template: '<div class="row"><div class="col-md-12 chart"></div><div class="col-md-12"><div class="legend"><div></div></div></div></div>',
                    link: {
                        pre: function(scope, element, attrs) {
                        },
                        post: function(scope, element, attrs) {

                            scope.renderChart = function() {
                                var chartData = [];
                                var dataIndex = {};

                                var length = scope.data.length;
                                var translations = {
                                    'Applicable et non vu': $translate('Applicable et non vu'),
                                    'Applicable et vu': $translate('Applicable et vu'),
                                    'Non applicable': $translate('Non applicable'),
                                    'Total applicable': $translate('Total applicable')
                                };

                                var promise = EezyPromise.resolveObjectPromises(translations);
                                promise.then(function(result) {

                                    var legends;
                                    if (scope.mode === 'synthese') {
                                        legends = scope.legends;
                                    } else if (scope.mode === 'efficacite') {
                                        legends = [
                                            {
                                                label: result['Applicable et non vu'],
                                                color: '#0089b5'
                                            },
                                            {
                                                label: result['Applicable et vu'],
                                                color: '#cef1fe'
                                            }
                                        ];
                                    } else if (scope.mode === 'pertinence') {
                                        legends = [
                                            {
                                                label: result['Non applicable'],
                                                color: '#848486'
                                            },
                                            {
                                                label: result['Total applicable'],
                                                color: '#e9e9e9'
                                            }
                                        ];
                                    }

                                    // Préparation du squelette des donneés en parcourant les valeurs possibles
                                    angular.forEach(legends, function(value, key) {
                                        var item = {
                                            key: key,
                                            label: value.label,
                                            color: value.color,
                                            position: value.position,
                                            data: []
                                        };

                                        if (!value.nonApplicable) {
                                            // Données envoyées au grapheur
                                            chartData.push(item);

                                            // Index des données
                                            dataIndex[key] = item;
                                        }
                                    });
                                    // Tri par position des légendes
                                    chartData.sort(function(a, b) {
                                        return b.position - a.position;
                                    });

                                    // Pour chaque "chapitre", "thème", ...
                                    var categories = [];
                                    angular.forEach(scope.data, function(row) {
                                        categories.push(row.label);
                                    });
                                    categories.reverse();

                                    if (scope.mode === 'synthese') {
                                        length = 0;
                                        angular.forEach(scope.data, function(row) {
                                            var total;
                                            // Mode avec critères
                                            var counts;
                                            if (angular.isDefined(scope.periodicite)) {
                                                total = row.totalResponseCountByPeriod[scope.periodicite];
                                                counts = row.responseCountByPeriodByType[scope.periodicite];
                                            } else {
                                                total = row.totalResponseCount;
                                                counts = row.totalResponseCountByType;
                                            }
                                            var sum = 0;
                                            if (angular.isDefined(counts) && counts !== null) {
                                                length ++;
                                                angular.forEach(chartData, function(value) {
                                                    if (angular.isDefined(counts[value.key])) {
                                                        var count = counts[value.key];
                                                        count = count * 100 / total;
                                                        value.data.push([
                                                            100 - sum,
                                                            row.label
                                                        ]);
                                                        sum += count;
                                                    }
                                                });
                                            }
                                        });
                                    }

                                    if (scope.mode === 'efficacite') {
                                        angular.forEach(scope.data, function(row) {
                                            var total;
                                            var count;
                                            if (angular.isDefined(scope.periodicite)) {
                                                total = row.totalResponseCountByPeriod[scope.periodicite];
                                                count = row.unAnsweredCountByPeriod[scope.periodicite] * 100 / (total + row.unAnsweredCountByPeriod[scope.periodicite]);
                                            } else {
                                                total = row.totalResponseCount;
                                                count = row.unAnsweredCount * 100 / (total + row.unAnsweredCount);
                                            }
                                            chartData[0].data.push([
                                                100,
                                                row.label
                                            ]);
                                            chartData[1].data.push([
                                                100 - count,
                                                row.label
                                            ]);
                                        });
                                    }

                                    if (scope.mode === 'pertinence') {
                                        angular.forEach(scope.data, function(row) {
                                            var total;
                                            var count;
                                            if (angular.isDefined(scope.periodicite)) {
                                                total = row.totalResponseCountByPeriod[scope.periodicite];
                                                count = row.nonApplicableCountByPeriod[scope.periodicite] * 100 / (total + row.unAnsweredCountByPeriod[scope.periodicite] + row.nonApplicableCountByPeriod[scope.periodicite]);
                                            } else {
                                                total = row.totalResponseCount;
                                                count = row.nonApplicableCount * 100 / (total + row.unAnsweredCount + row.nonApplicableCount);
                                            }

                                            chartData[0].data.push([
                                                100,
                                                row.label
                                            ]);
                                            chartData[1].data.push([
                                                100 - count,
                                                row.label
                                            ]);
                                        });
                                    }

                                    var container = element.find('> div > div.chart');
                                    var legend = element.find('> div > div > div.legend > div');
                                    container.height(length * 30 + (length > 0 ? length - 1 : 0) * 20 + 20);

                                    $.plot(container, chartData, {
                                        grid : {
                                            hoverable : false,
                                            borderWidth: 0
                                        },
                                        series: {
                                            bars: {
                                                show: true,
                                                barWidth: 0.6,
                                                lineWidth: 0,
                                                fill: 1,
                                                align: "center",
                                                horizontal:true
                                            },
                                        },
                                        legend: {
                                            show: true,
                                            container: legend,
                                            noColumns: chartData.length,
                                            sorted: 'reverse'
                                        },
                                        yaxis : {
                                            mode: "categories",
                                            categories: categories,
                                            tickLength: 10,
                                            tickSize: 0,
                                            labelWidth: 200
                                        },
                                        xaxis : {max:100}
                                    });




                                });
                            };


                            scope.$watch('data', function(data) {
                                if (angular.isDefined(data)) {
                                    scope.renderChart();
                                }
                            });

                            // HACK to make it working with tabs
                            $(document).on('shown.bs.tab', function(event) {
                                if ($(event.target.hash)[0].contains(element[0])) {
                                    scope.renderChart();
                                }
                            });
                        }
                    }
                };
            }])
            ;


        }
    );
})(requirejs);

