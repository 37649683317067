(function (requirejs) {
'use strict';


    define('configuration/typeCritereEvaluation',['angular'],
        function(angular) {

            angular.module('eezycleanApp.configuration.typeCritereEvaluation', ['ngRoute', 'pascalprecht.translate'])


            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/configuration/type-critere-evaluation', {
                    templateUrl: '/partials/configuration/typeCritereEvaluation/list.tpl.html'
                });
                $routeProvider.when('/configuration/type-critere-evaluation/new', {
                    templateUrl: '/partials/configuration/typeCritereEvaluation/create.tpl.html'
                });
                $routeProvider.when('/configuration/type-critere-evaluation/:id', {
                    templateUrl: '/partials/configuration/typeCritereEvaluation/edit.tpl.html'
                });
            }]);
        }
    );
})(requirejs);

