(function (requirejs) {
'use strict';

    define('../lib/eezymanager-securesocial/javascripts/controllers',[],function() {

        /* Controllers */

        var controllers = {};

        controllers.LoginController = function($scope, $rootScope, $http, $location, Session, AUTH_EVENTS) {
            if (Session.authenticated) {
                return $location.path("home");
            }
            $scope.onSuccess = function(response) {
                Session.create(response.token, response.expiresOn);
                return $location.path("home");
            };


            $scope.onError = function(response, status) {
                $scope.message = 'Invalid credentials';
            };
        };

        controllers.LoginController.$inject = ['$scope', '$rootScope', '$http', '$location', 'Session', 'AUTH_EVENTS'];


        return controllers;
    });
})(requirejs);

