/* jshint debug: true */
(function (requirejs) {
'use strict';

    define('../lib/eezymanager-common/javascripts/form',['angular'], function(angular) {

        angular.module('eezymanager.common.form', ['eezymanager.common.services', 'ui.sortable', 'angucomplete'])
            .directive('eezyBean', ['$http', '$routeParams', '$location', 'EezyUrl', function($http, $routeParams, $location, EezyUrl) {
                return {
                    restrict: 'E',
                    scope: {
                        onLoaded: '=?'
                    },
                    transclude: true,
                    templateUrl: '/eezymanager/common/partials/form/form.tpl.html',
                    link: {
                        pre: function($scope, element, attrs, ctrl, transclude) {
                            $scope.url = EezyUrl.generate(attrs.url, $routeParams);
                            if (!attrs.name) {
                                // default value
                                $scope.name = 'bean';
                            } else {
                                scope.name = attrs.name;
                            }

                            transclude($scope, function(clone, $scope) {
                                element.append(clone);
                            });
                        },
                        post: function(scope, element, attrs, ctrl, transclude) {

                            attrs.$observe('url', function(){
                                scope.url = EezyUrl.generate(attrs.url, $routeParams);
                                $http.get(scope.url).success(function(response) {
                                    scope[scope.name] = angular.copy(response);
                                    if (angular.isDefined(scope.onLoaded)) {
                                        scope.onLoaded(response);
                                    }
                                    /*
                                    transclude(scope, function(clone, scope) {
                                        element.append(clone);
                                    });*/

                                });
                            });

                        }
                    }
                };
            }])
            .directive('eezyForm', ['$parse', '$http', '$routeParams', '$location', 'EezyUrl', function($parse, $http, $routeParams, $location, EezyUrl) {
                return {
                    restrict: 'E',
                    scope: {
                        onSuccessRedirect: '@',
                        onSuccess: '=',
                        onCancelRedirect: '@',
                        onCancel: '&',
                        onDeleteRedirect: '@',
                        onDelete: '=',
                        onError: '=',
                        mode: '@',
                        init: '=?',
                        autocomplete: '@',
                        preSubmit: '=?',
                        form: '=?'
                    },
                    transclude: true,
                    templateUrl: '/eezymanager/common/partials/form/form.tpl.html',
                    link: {
                        pre: function(scope, element, attrs, ctrl, transclude) {
                            if (angular.isDefined(attrs.labelClass)) {
                                scope.labelClass = attrs.labelClass;
                            }
                            if (angular.isDefined(attrs.controlClass)) {
                                scope.controlClass = attrs.controlClass;
                            }
                            if (!angular.isDefined(attrs.formClass)) {
                                scope.formClass = 'form-horizontal form-bordered';
                            } else {
                                scope.formClass = attrs.formClass;
                            }
                            if (!attrs.name) {
                                // default value
                                scope.name = 'form';
                            } else {
                                scope.name = attrs.name;
                            }
                            scope.$watch(scope.name, function(val) {
                                scope.model = val;
                            });

                            if (angular.isUndefined(scope[scope.name]) || scope[scope.name] === null) {
                                scope[scope.name] = {};
                            }

                            scope.cancel = function() {
                                if (attrs.onCancelRedirect) {
                                    $location.path(EezyUrl.generate(scope.onCancelRedirect, $routeParams));
                                } else if (attrs.onCancel) {
                                    scope.onCancel();
                                }
                            };

                            scope['delete'] = function() {
                                scope.url = EezyUrl.generate(attrs.url, $routeParams);
                                $http({
                                    url: scope.url,
                                    method: 'DELETE'
                                }).success(function(response) {
                                    if (attrs.onDeleteRedirect) {
                                        $location.path(EezyUrl.generate(scope.onDeleteRedirect, $routeParams));
                                    } else if (attrs.onDelete) {
                                        scope.onDelete(response);
                                    } else if (attrs.onSuccessRedirect) {
                                        $location.path(EezyUrl.generate(scope.onSuccessRedirect, response.data));
                                    } else if (attrs.onSuccess) {
                                        scope.onSuccess(response);
                                    }
                                }).error(function(response, status) {
                                    // handle bad request = form validation errors
                                    if (status == 400) {
                                        scope.errors = {};
                                        angular.forEach(response.errors, function(value, key) {
                                            $parse(key).assign(scope.errors, value);
                                        });
                                    }
                                });
                            };

                            scope.submit = function() {
                                scope.url = EezyUrl.generate(attrs.url, $routeParams);
                                var data = angular.copy(scope[scope.name]);
                                if (angular.isDefined(scope.preSubmit)) {
                                    data = scope.preSubmit(data);
                                }
                                $http({
                                    url: scope.url,
                                    method: 'POST',
                                    data: data
                                }).success(function(response) {
                                    if (attrs.onSuccessRedirect) {
                                        $location.path(EezyUrl.generate(scope.onSuccessRedirect, response.data));
                                    } else if (attrs.onSuccess) {
                                        scope.onSuccess(response);
                                    }
                                }).error(function(response, status) {
                                    // handle bad request = form validation errors
                                    if (status == 400) {
                                        scope.errors = {};
                                        angular.forEach(response.errors, function(value, key) {
                                            $parse(key).assign(scope.errors, value);
                                        });
                                    }
                                    if (angular.isDefined(scope.onError) && typeof scope.onError === 'function') {
                                        scope.onError(response, status);
                                    }
                                });
                            };

                            if (scope.mode == 'edit') {
                                scope.url = EezyUrl.generate(attrs.url, $routeParams);
                                $http.get(scope.url).success(function(response) {
                                    scope[scope.name] = angular.copy(response);
                                    transclude(scope, function(clone, scope) {
                                        element.find('form').append(clone);
                                    });
                                });
                            } else {
                                if (angular.isDefined(scope.init)) {
                                    scope[scope.name] = scope.init;
                                }
                                transclude(scope, function(clone, scope) {
                                    element.find('form').append(clone);
                                });
                            }
                        },
                        post: function(scope, element, attrs) {

                        }
                    }
                };
            }])
            .directive('eezyEmbedSortable', ['$parse', function($parse) {

                function escapeRegExp(str) {
                  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
                }

                return {
                    restrict: 'E',
                    scope: {
                        model: '=',
                        positionField: '@?',
                        name: '@',
                        handle: '@?',
                        addLabel: '@?',
                        addUrl: '@?',
                        removeLabel: '@?',
                        sortableId: '@'
                    },
                    transclude: true,
                    templateUrl: '/eezymanager/common/partials/form/embedsortable.tpl.html',
                    link: {
                        pre: function(scope, element, attrs, ctrl, transclude) {
                            if (angular.isDefined(attrs.labelClass)) {
                                scope.labelClass = attrs.labelClass;
                            }
                            if (angular.isDefined(attrs.controlClass)) {
                                scope.controlClass = attrs.controlClass;
                            }
                            scope.childScopes = [];
                            scope.model = [];
                            scope.$parent.$watch('errors', function(newVal, oldVal) {
                                scope.errors = newVal;
                            });
                            scope.sortableOptions = {
                                stop: function(e, ui) {
                                    if (angular.isDefined(scope.positionField)) {
                                        angular.forEach(scope.model, function(value, index) {
                                            value[scope.positionField] = index + 1;
                                        });
                                    }
                                }
                            };

                            if (angular.isDefined(scope.handle)) {
                                scope.sortableOptions.handle = scope.handle;
                            }

                            scope.$watch('model', function(newVal, oldVal) {
                                if (!angular.isDefined(newVal) || newVal === null) {
                                    scope.model = [];
                                }
                            });

                            scope['delete'] = function(index) {
                                scope.model.splice(index, 1);
                                if (angular.isDefined(scope.positionField)) {
                                    angular.forEach(scope.model, function(value, index) {
                                        value[scope.positionField] = index + 1;
                                    });
                                }
                            };

                            scope.add = function() {
                                scope.model.push({});
                                if (angular.isDefined(scope.positionField)) {
                                    angular.forEach(scope.model, function(value, index) {
                                        value[scope.positionField] = index + 1;
                                    });
                                }
                            };
                        },
                        post: function(scope, element, attrs, ctrl, transclude) {
                        }
                    }

                };
            }])
            .directive('eezyInput', ['$parse', function($parse) {
                return {
                    restrict: 'E',
                    scope: {
                        model: '=',
                        name: '@',
                        label: '@',
                        placeholder: '@',
                        size: '@',
                        type: '@',
                        value: '@',
                        required: '@'
                    },
                    link: {
                        pre: function(scope, element, attrs) {
                        },
                        post: function(scope, element, attrs) {
                        }
                    },
                    templateUrl: '/eezymanager/common/partials/form/input.tpl.html'
                };
            }])
            .directive('eezyField', ['$parse', function($parse) {
                return {
                    restrict: 'E',
                    scope: {
                        label: '@',
                        placeholder: '@',
                        size: '@',
                        type: '@',
                        value: '@',
                        required: '@',
                        typefield: '@',
                        errors: '=?'
                    },
                    transclude: true,
                    templateUrl: function(elem, attr) {
                        return '/eezymanager/common/partials/form/field.tpl.html';
                    },
                    link: {
                        pre: function(scope, element, attrs) {
                            scope.name = attrs.name;
                            if (scope.name) {
                                if (scope.name.match(/^[^.]+$/)) {
                                    scope.field = scope.name;
                                    scope.name = 'form.' + scope.name;
                                } else {
                                    var formName = scope.$parent.name;
                                    if (formName === 'form') {
                                        scope.field = scope.name.substring(formName.length + 1);
                                    } else {
                                        scope.field = scope.name;
                                        scope.name = 'form.' + scope.name;
                                    }
                                }
                            }
                            if (!angular.isDefined(attrs.labelClass) && !angular.isDefined(attrs.controlClass)) {
                                if (!angular.isDefined(scope.$parent.labelClass && !angular.isDefined(scope.$parent.controlClass))) {
                                    scope.labelClass = 'col-md-3 col-sm-3';
                                    scope.controlClass = 'col-md-9 col-sm-9';
                                } else {
                                    scope.labelClass = scope.$parent.labelClass;
                                    scope.controlClass = scope.$parent.controlClass;
                                }
                            } else {
                                scope.labelClass = attrs.labelClass;
                                scope.controlClass = attrs.controlClass;
                            }
                            scope.$watch('model', function(val) {
                                // Traitement particulier pour ce type de données
                                if (scope.typefield === 'timepicker') {
                                    if (angular.isDefined(val) && val !== null && (typeof(val) != 'object' || val.constructor != Date)) {
                                        // Millisecondes --> Date
                                        scope.model = new Date(val + (new Date(val)).getTimezoneOffset() * 60 * 1000);
                                    }
                                }
                            });

                        },
                        post: function(scope, element, attrs, ctrl, transclude) {
                            if (scope.name) {
                                scope.$watch('model', function(newVal, oldVal) {
                                    var getter = $parse(scope.name);
                                    var setter = getter.assign;
                                    setter(scope.$parent, newVal);
                                });

                                scope.$parent.$watch(scope.name, function(newVal, oldVal) {
                                    scope.model = newVal;
                                });
                                scope.$parent.$watch('errors', function(newVal, oldVal) {
                                    if (angular.isDefined(newVal) && newVal !== null) {
                                        if (angular.isDefined(scope.$parent.$index)) {
                                            if (angular.isDefined(newVal[scope.$parent.$index])) {
                                                scope.errors = newVal[scope.$parent.$index][attrs.name];
                                            } else {
                                                scope.errors = undefined;
                                            }
                                        } else {
                                            scope.errors = newVal[attrs.name];
                                        }
                                    } else {
                                        scope.errors = undefined;
                                    }

                                    if (angular.isDefined(scope.errors) && angular.isDefined(scope.errors[0]) && angular.isString(scope.errors[0])) {
                                        scope.showErrors = true;
                                    } else {
                                        scope.showErrors = false;
                                    }
                                });
                                /*
                                if (angular.isDefined(scope.$parent.errorPrefix)) {
                                    scope.$parent.$watch('errors[\'' + scope.$parent.errorPrefix + '.' + scope.field + '\']', function(newVal, oldVal) {
                                        scope.fieldErrors = newVal;
                                    });
                                } else {
                                    scope.$parent.$watch('errors.' + scope.field, function(newVal, oldVal) {
                                        scope.fieldErrors = newVal;
                                    });
                                }*/
                            }

                            transclude(scope, function(clone) {
                                element.find('.eezy-form-control').prepend(clone);
                            });
                        }
                    }

                };
            }])
            .directive('eezyChecklist', ['$http', '$parse', function($http, $parse) {
                return {
                    restrict: 'E',
                    scope: {
                        model: '=',
                        options: '=?',
                        optionsUrl: '@'
                    },
                    templateUrl: '/eezymanager/common/partials/form/checklist.tpl.html',
                    link: {
                        pre: function(scope, element, attrs) {
                            scope.key = 'id';
                            if (angular.isDefined(attrs.key)) {
                                scope.key = attrs.key;
                            }
                            scope.label = 'name';
                            if (angular.isDefined(attrs.label)) {
                                scope.label = attrs.label;
                            }
                            scope.model = [];

                            scope.$watch('model', function(newVal, oldVal) {
                                if (!angular.isDefined(newVal) || newVal === null) {
                                    scope.model = [];
                                }
                            });

                            if (angular.isDefined(scope.optionsUrl)) {
                                $http.get(scope.optionsUrl)
                                    .success(function(response) {
                                        scope.options = response;
                                    });
                            }
                        },
                        post: function(scope, element, attrs) {
                        }
                    }
                };
            }])
            .directive('eezyTimepicker', ['Language', function(Language) {
                return {
                    restrict: 'E',
                    scope: {
                        model: '='
                    },
                    templateUrl: '/eezymanager/common/partials/form/timepicker.tpl.html',
                    link: function(scope, element, attrs) {
                        scope.clear = function() {
                            scope.model = null;
                        };

                        scope.$watch('model', function(val) {
                            if (angular.isDefined(val) && val !== null && (typeof(val) != 'object' || val.constructor != Date)) {
                                scope.timeModel = new Date(val + (new Date(val)).getTimezoneOffset() * 60 * 1000);
                            } else if (val === null) {
                                scope.timeModel = null;
                            }
                        });

                        scope.$watch('timeModel', function(val) {
                            if (angular.isDefined(val) && val !== null && typeof(val) === 'object' && val.constructor === Date) {
                                scope.model = new Date(val.getTime() - val.getTimezoneOffset() * 60 * 1000);
                            } else if (val === null) {
                                scope.model = null;
                            }
                        });

                        var showMeridian = true;
                        if (Language.currentLanguage() === 'fr') {
                            showMeridian = false;
                        }

                        scope.showMeridian = showMeridian;
                    }
                };
            }])
            .directive('eezyDatepicker', ['$http', '$parse', 'Language', function($http, $parse, Language) {
                return {
                    restrict: 'E',
                    scope: {
                        model: '=',
                        placeholder: '@?',
                        options: '='
                    },
                    templateUrl: '/eezymanager/common/partials/form/datepicker.tpl.html',
                    link: {
                        pre: function(scope, element, attrs) {
                            var init = true;
                            var datepicker = element.children('div');
                            scope.$watch('options', function(newVal, oldVal) {
                                if (!init) {
                                    datepicker.datepicker('remove');
                                }
                                var options = angular.extend(
                                    {},
                                    {
                                        language: Language.currentLanguage(),
                                        todayHighlight: true,
                                        autoclose: true,
                                        clearBtn: true
                                    },
                                    newVal
                                );
                                datepicker.datepicker(options);
                                init = false;
                            }, true);



                            var skip = false;

                            scope.$watch('model', function(val) {
                                skip = true;
                                var currentDate = datepicker.datepicker("getDate");
                                if (angular.isDefined(val) && val !== null && typeof(val) === 'object' && val.constructor === Date) {
                                    if (currentDate === null || currentDate.getTime() !== val.getTime()) {
                                        datepicker.datepicker("setUTCDate", val);
                                    } else {
                                        skip = false;
                                    }
                                } else {
                                    if (angular.isDefined(val) && val !== null) {
                                        var date = new Date(val);
                                        datepicker.datepicker("setUTCDate", date);
                                        scope.model = date;
                                    } else {
                                        datepicker.datepicker("setUTCDate", null);
                                    }
                                }
                            });
                            datepicker
                                .on('changeDate', function(e) {
                                    var date = datepicker.datepicker("getUTCDate");
                                    var model;
                                    if (angular.isDefined(scope.model) && scope.model !== null && (typeof(scope.model) !== 'object' || scope.model.constructor !== Date)) {
                                        model = new Date(scope.model);
                                    } else {
                                        model = scope.model;
                                    }
                                    if (angular.isDefined(model) && model !== null) {
                                        if (angular.isDefined(date) && date !== null) {
                                            if (model.getTime() != date.getTime()) {
                                                scope.model = date;

                                            } else {

                                            }
                                        } else {
                                            scope.model = undefined;
                                        }
                                    } else {
                                        if (angular.isDefined(date) && date !== null) {
                                            scope.model = date;
                                        }
                                    }
                                    if (!skip) {
                                        scope.$apply();
                                    }
                                    skip = false;
                                });
                        },
                        post: function(scope, element, attrs) {
                        }
                    }
                };
            }])
            .directive('eezyDaterange', ['$http', '$parse', 'Language', function($http, $parse, Language) {
                return {
                    restrict: 'E',
                    scope: {
                        model: '=',
                        placeholderFrom: '@?',
                        placeholderTo: '@?'
                    },
                    templateUrl: '/eezymanager/common/partials/form/daterange.tpl.html',
                    link: {
                        pre: function(scope, element, attrs) {
                            scope.model = [];
                            var datepicker = element.children('div');
                            datepicker.datepicker({
                                language: Language.currentLanguage(),
                                todayHighlight: true,
                                autoclose: true,
                                clearBtn: true
                            });
                            var skip = false;
                            scope.$watch('model', function(val) {
                                var datepickers = datepicker.find('input');
                                var from = datepickers.eq(0).datepicker('getUTCDate');
                                var to = datepickers.eq(1).datepicker('getUTCDate');

                                var modelFrom = null;
                                if (angular.isDefined(scope.model) && scope.model !== null && angular.isDefined(scope.model[0])) {
                                    modelFrom = scope.model[0];
                                }

                                var modelTo = null;
                                if (angular.isDefined(scope.model) && scope.model !== null && angular.isDefined(scope.model[1])) {
                                    modelTo = scope.model[1];
                                }

                                if (!angular.equals(from, modelFrom) && !angular.equals(to, modelTo)) {
                                    // because the two datepickers will be updated, skip first
                                    skip = true;
                                }

                                if (!angular.equals(from, modelFrom)) {
                                    datepickers.eq(0).datepicker('setUTCDate', modelFrom);
                                }
                                if (!angular.equals(to, modelTo)) {
                                    datepickers.eq(1).datepicker('setUTCDate', modelTo);
                                }
                                skip = false;
                            });
                            datepicker
                                .on('changeDate', function(e) {
                                    var datepickers = datepicker.find('input');
                                    var from = datepickers.eq(0).datepicker('getUTCDate');
                                    var to = datepickers.eq(1).datepicker('getUTCDate');

                                    var modelFrom = null;
                                    if (angular.isDefined(scope.model) && scope.model !== null && angular.isDefined(scope.model[0])) {
                                        modelFrom = scope.model[0];
                                    }

                                    var modelTo = null;
                                    if (angular.isDefined(scope.model) && scope.model !== null && angular.isDefined(scope.model[1])) {
                                        modelTo = scope.model[1];
                                    }

                                    if (!skip && !angular.equals([from, to], [modelFrom, modelTo])) {
                                        scope.model = [from, to];
                                        scope.$apply();
                                    }
                                });

                        },
                        post: function(scope, element, attrs) {
                        }
                    }
                };
            }])
            .service('eezySelectCache', ['$http', '$timeout', function($http, $timeout) {
                var cache = {};
                this.get = function(optionsUrl) {
                    if (angular.isDefined(cache[optionsUrl])) {
                        return cache[optionsUrl];
                    } else {
                        var promise = $http.get(optionsUrl);
                        cache[optionsUrl] = promise;

                        // This cache is available for 5 seconds
                        $timeout(function() {
                            delete cache[optionsUrl];
                        }, 5000);

                        return promise;
                    }
                };

                return this;
            }])
            .directive('eezySelect', ['$http', '$parse', 'eezySelectCache', '$timeout', function($http, $parse, eezySelectCache, $timeout) {
                return {
                    restrict: 'E',
                    scope: {
                        model: '=',
                        optionsUrl: '@?',
                        options: '=?',
                        key: '@',
                        label: '@',
                        emptyLabel: '@',
                        outputLabel: '=?',
                        disabled: '=?'
                    },
                    templateUrl: '/eezymanager/common/partials/form/select.tpl.html',
                    link: {
                        pre: function(scope, element, attrs) {
                            scope.optList = [];

                            function processOptions(response) {
                                var options = [];
                                var keyGetter = $parse(scope.key);
                                var labelGetter = $parse(scope.label);

                                var i = 1;
                                angular.forEach(response, function(value) {
                                    if (typeof(value) === 'object') {
                                        options.push({
                                            index: i,
                                            key: keyGetter(value),
                                            label: labelGetter(value)
                                        });
                                    } else {
                                        options.push({
                                            index: i,
                                            key: value,
                                            label: value
                                        });
                                    }
                                    i ++;
                                });
                                var value = scope.model;
                                scope.optList = options;

                                scope.outputLabel = undefined;
                                angular.forEach(scope.optList, function(option) {
                                    if (option.key === scope.model) {
                                        scope.outputLabel = option.label;
                                        scope.internalModel = option.index;
                                    }
                                });
                            }

                            scope.$watch('options', function(response) {
                                if (angular.isDefined(response)) {
                                    processOptions(response);
                                }
                            });

                            scope.$watch('optionsUrl', function(optionsUrl) {
                                if (angular.isDefined(optionsUrl) && optionsUrl !== null && optionsUrl !== '') {
                                    eezySelectCache.get(optionsUrl)
                                        .success(function(response) {
                                            processOptions(response);
                                        });
                                } else {
                                    scope.optList.length = 0;
                                    scope.model = undefined;
                                }
                            });
                            scope.$watch('internalModel', function(value) {
                                if (angular.isDefined(scope.optList) && angular.isDefined(scope.internalModel)) {
                                    angular.forEach(scope.optList, function(option) {
                                        if (option.index === value) {
                                            scope.outputLabel = option.label;
                                            scope.model = option.key;
                                        } else if (value === null) {
                                            scope.model = "";
                                        }
                                    });
                                }
                            });
                            scope.$watch('model', function(value) {
                                if (angular.isDefined(scope.optList)) {
                                    scope.outputLabel = undefined;
                                    angular.forEach(scope.optList, function(option) {
                                        if (option.key === value) {
                                            scope.outputLabel = option.label;
                                            scope.internalModel = option.index;
                                        } else if (value === null) {
                                            scope.internalModel = "";
                                        }
                                    });
                                }
                            });
                        },
                        post: function(scope, element, attrs) {
                        }
                    }
                };
            }])
            .directive('eezyMultiselect', ['$http', '$parse', '$translate', '$rootScope', function($http, $parse, $translate, $rootScope) {
                function setValue(values, output) {
                    angular.forEach(output, function(option) {
                        option.ticked = false;
                    });

                    if (values) {
                        angular.forEach(values, function(value) {
                            angular.forEach(output, function(option) {
                                if (option.id == value) {
                                    option.ticked = true;
                                }
                            });
                        });
                    }
                }

                return {
                    restrict: 'E',
                    scope: {
                        model: '=',
                        options: '=?',
                        optionsUrl: '@?',
                        groupProperty: '@?',
                        helperElements: '@?',
                        emptyLabel: '@?',
                        selectAllLabel: '@?',
                        selectNoneLabel: '@?',
                        resetLabel: '@?',
                        searchLabel: '@?',
                        selectionMode: '@?',
                        disabled:'=?'
                    },
                    templateUrl: '/eezymanager/common/partials/form/multiselect.tpl.html',
                    link: {
                        pre: function(scope, element, attrs) {
                            scope.localLang = {
                                selectAll       : scope.selectAllLabel ? scope.selectAllLabel : 'Tick all',
                                selectNone      : scope.selectNoneLabel ? scope.selectNoneLabel : 'Untick all',
                                reset           : scope.resetLabel ? scope.resetLabel : 'Reset',
                                search          : scope.searchLabel ? scope.searchLabel : 'Type here to search...',
                                nothingSelected : scope.emptyLabel ? scope.emptyLabel : 'None selected'
                            };


                            scope.outputModel = [];
                            scope.inputModel = [];

                            if (angular.isDefined(attrs.label)) {
                                scope.label = attrs.label;
                            } else {
                                scope.label = 'name';
                            }

                            scope.setValue = function(values) {
                                setValue(values, scope.inputModel);
                            };


                        },
                        post: function(scope, element, attrs) {
                            scope.$watch('outputModel', function(newVal, oldVal) {
                                if (scope.inputModel.length > 0) {
                                    var selected = [];
                                    angular.forEach(newVal, function(value) {
                                        selected.push(value.id);
                                    });
                                    scope.model = selected;
                                }
                            }, true);
                            scope.$watch('model', function(newVal, oldVal) {
                                if (scope.inputModel) {
                                    scope.setValue(newVal);
                                }
                            });
                            scope.$watch('options', function(options) {
                                if (angular.isDefined(options)) {
                                    setValue(scope.model, options);
                                    angular.copy(options, scope.inputModel);
                                }
                            });
                            scope.$watch('optionsUrl', function(optionsUrl) {
                                if (angular.isDefined(optionsUrl)) {
                                    $http.get(optionsUrl)
                                        .success(function(response) {
                                            setValue(scope.model, response);
                                            angular.copy(response, scope.inputModel);
                                        });
                                }
                            });
                        }
                    }
                };
            }])
            .directive('eezySubmit', function() {
                return {
                    restrict: 'E',
                    scope: {
                        "class": '@',
                        value: '@'
                    },
                    templateUrl: '/eezymanager/common/partials/form/submit.tpl.html'
                };
            })
            .directive('eezyCreate', function() {
                return {
                    restrict: 'E',
                    templateUrl: '/eezymanager/common/partials/form/create.tpl.html'
                };
            })
            .directive('eezySave', function() {
                return {
                    restrict: 'E',
                    templateUrl: '/eezymanager/common/partials/form/save.tpl.html',
                    scope: {
                        confirm: '@?',
                        confirmMessage: '@?'
                    },
                    link: function(scope, element, attrs) {
                        scope.save = function() {
                            if (scope.confirm == 'true') {
                                element.find('.modal').modal('show');
                            } else {
                                scope.$parent.submit();
                            }
                        };
                        scope.saveConfirm = function() {
                            element.find('.modal')
                            .on('hidden.bs.modal', function() {
                                scope.$parent.submit();
                                scope.$parent.$apply();
                            })
                            .modal('hide');
                        };
                    }
                };
            })
            .directive('eezyDelete', function() {
                return {
                    restrict: 'E',
                    templateUrl: '/eezymanager/common/partials/form/delete.tpl.html',
                    scope: {
                        btnClass: '@?'
                    },
                    link: function(scope, element, attrs) {
                        scope.showModal = function() {
                            element.find('.modal').modal('show');
                        };
                        scope['delete'] = function() {
                            element.find('.modal')
                            .on('hidden.bs.modal', function() {
                                if (angular.isDefined(scope.$parent.$index)) {
                                    scope.$parent.$parent.$parent['delete'](scope.$parent.$index);
                                } else {
                                    scope.$parent['delete']();
                                }
                            })
                            .modal('hide');
                        };
                    }
                };
            })
            .directive('eezyCancel', function() {
                return {
                    restrict: 'E',
                    templateUrl: '/eezymanager/common/partials/form/cancel.tpl.html'
                };
            })
            .directive('eezySortable', ['$http', '$timeout', function($http, $timeout) {
                return {
                    restrict: 'E',
                    templateUrl: '/eezymanager/common/partials/form/sortable.tpl.html',
                    scope: {
                        model: '=',
                        optionsUrl: '@',
                        key: '@',
                        label: '@'
                    },
                    link: {
                        pre: function(scope, element, attr) {
                            scope.stock  = [];
                            scope.selected = [];

                            scope.inputOptions = {
                                connectWith: ".output"
                            };
                            scope.outputOptions = {
                                connectWith: ".input"
                            };

                            $http.get(scope.optionsUrl)
                                .success(function(result) {
                                    scope.options = result;
                                });
                        },
                        post: function(scope, element, attr) {

                            var unregisterOptions = scope.$watch('options', function(newVal, oldVal) {
                                if (angular.isDefined(newVal) && angular.isDefined(scope.model)) {
                                    var selected = [];
                                    var stock = [];

                                    angular.forEach(scope.model, function(value) {
                                        angular.forEach(scope.options, function(opt) {
                                            if (value == opt[scope.key]) {
                                                selected.push(opt);
                                            }
                                        });
                                    });

                                    angular.forEach(newVal, function(value) {
                                        if (scope.model) {
                                            if (scope.model.indexOf(value[scope.key]) == -1) {
                                                stock.push(value);
                                            }
                                        }
                                    });
                                    scope.selected = angular.copy(selected);
                                    scope.stock = angular.copy(stock);
                                    unregisterOptions();
                                }
                            });
                            var unregisterModel = scope.$watch('model', function(newVal, oldVal) {
                                if (angular.isDefined(newVal) && angular.isDefined(scope.options)) {
                                    var selected = [];
                                    var stock = [];

                                    angular.forEach(newVal, function(value) {
                                        angular.forEach(scope.options, function(opt) {
                                            if (value == opt[scope.key]) {
                                                selected.push(opt);
                                            }
                                        });
                                    });

                                    angular.forEach(scope.options, function(value) {
                                        if (scope.model) {
                                            if (newVal.indexOf(value[scope.key]) == -1) {
                                                stock.push(value);
                                            }
                                        }
                                    });
                                    scope.selected = angular.copy(selected);
                                    scope.stock = angular.copy(stock);
                                    unregisterModel();
                                }

                            });
                            scope.$watch('selected', function(newVal, oldVal) {
                                var selected = [];
                                angular.forEach(newVal, function(value) {
                                    selected.push(value[scope.key]);
                                });
                                scope.model = selected;
                            }, true);
                        }
                    }
                };
            }])
            .directive('eezyDropzone', ['$routeParams', 'EezyUrl', '$http', function($routeParams, EezyUrl, $http) {
                return {
                    restrict: 'E',
                    template: '<div class="dropzone"></div>',
                    scope: {
                        url: '@',
                        queuecomplete: '&?',
                        onSuccess: '=?',
                        onError: '=?',
                        onInit: '=?',
                        autoProcessQueue: '@?',
                        processQueue: '=?',
                        params: '=?',
                        maxFileSize: '@?',
                        fileTypeAccepted:'@?',
                        maxUploadFiles:'@?',
                        maxFileSizeError: '@?',
                        fileTypeError: '@?'
                    },
                    link: function(scope, element, attr) {

                        var dropzone = element.find('> div');
                        var url = EezyUrl.generate(scope.url, $routeParams);

                        dropzone.dropzone({
                                url: url,
                                autoProcessQueue: (scope.autoProcessQueue === 'false') ? false : true,
                                queuecomplete: function(event) {
                                    if (angular.isDefined(scope.queuecomplete)) {
                                        scope.queuecomplete();
                                    }
                                },
                                error: function(event, error) {
                                    if (angular.isDefined(scope.onError)) {
                                        scope.onError(error);
                                    }
                                    scope.$apply();
                                },
                                success: function(file, response) {
                                    this.removeFile(file);
                                    if (angular.isDefined(scope.onSuccess)) {
                                        scope.onSuccess(response);
                                    }
                                    scope.$apply();
                                },
                                init: function() {
                                    if (angular.isDefined(scope.onInit)) {
                                        scope.onInit(this);
                                    }
                                }
                            });

                        var myDropzone = dropzone.get(0).dropzone;

                        //set max size MB
                        if(angular.isDefined(scope.maxFileSize)){
                            myDropzone.options.maxFilesize = scope.maxFileSize;
                        }

                        //set mime type accepted like [image/*,application/pdf,.psd]
                        if(angular.isDefined(scope.fileTypeAccepted)){
                            myDropzone.options.acceptedFiles = scope.fileTypeAccepted;
                        }

                        if(angular.isDefined(scope.maxUploadFiles)){
                            myDropzone.options.maxFiles = scope.maxUploadFiles;
                        }

                        if(angular.isDefined(scope.maxFileSizeError)){
                            myDropzone.options.dictFileTooBig = scope.maxFileSizeError;
                        }

                        if(angular.isDefined(scope.fileTypeError)){
                            myDropzone.options.dictInvalidFileType = scope.fileTypeError;
                        }

                        scope.$watch('processQueue', function(newVal){
                            if(scope.processQueue) {
                                myDropzone.options.params = scope.params;
                                myDropzone.processQueue();
                                scope.processQueue = !scope.processQueue;
                            }
                        });
                    }
                };
            }])
            .directive('eezyAutocomplete', ['$http', function($http) {
                return {
                    restrict: 'E',
                    templateUrl: '/eezymanager/common/partials/form/autocomplete.tpl.html',
                    scope: {
                        url: '@',
                        field: '@',
                        model: '=',
                        placeholder: '@',
                        titlefield: '@',
                        imagefield: '@',
                        minlength: '@',
                        output: '=?',
                        outputlabel: '=?'
                    },
                    link: function(scope, element, attr) {
                        scope.$watch('output', function(output) {
                            if (angular.isDefined(output) && output !== null && angular.isDefined(output.originalObject)) {
                                scope.model = output.originalObject[scope.field];
                            } else {
                                scope.model = null;
                            }
                        });
                        scope.$watch('model', function(model) {
                            if (angular.isDefined(model)) {
                                scope.$evalAsync(function() {
                                    if (scope.placeholder === 'Agent') {
                                        // Patch pas top
                                        if (model) {
                                            $http.get('/planning/agent/'+model).success(function(response) {
                                                element.find('input').val(response.fullName);
                                            });
                                        } else {
                                            element.find('input').val("");
                                        }
                                    }
                                });

                                if (angular.isDefined(scope.output) && angular.isDefined(scope.output.originalObject)) {
                                    scope.outputlabel = scope.output.originalObject[scope.titlefield];
                                }
                            }
                        });
                    }
                };
            }])
            .directive('eezyRange', ['$http', function($http){
                return {
                    restrict: "E",
                    scope:{
                        model: "=",
                        width: "@",
                        min: "@",
                        max: "@",
                        field: "@?"
                    },
                    templateUrl: '/eezymanager/common/partials/form/range.tpl.html',
                    link: {
                        pre: function(scope, element, attrs) {
                            scope.model=[];
                            scope.change = function(slider){
                                scope.model[0] = slider.from;
                                scope.model[1] = slider.to;
                                scope.$apply();
                            };
                        }
                     }
                 };
            }]);
    });
})(requirejs);

