(function (requirejs) {
'use strict';

    define('evaluation/evaluation',['angular'],
        function(angular) {

            var showController = function($scope) {

            };
            showController.$inject = ['$scope'];


            angular.module('eezycleanApp.evaluation.evaluation', ['ngRoute', 'pascalprecht.translate'])

            // Routes
            .config(['$routeProvider', function($routeProvider) {
                $routeProvider.when('/evaluation/evaluation/:id', {
                    templateUrl: '/partials/evaluation/evaluation/list.tpl.html',
                    controller: ['$scope', 'Session', function($scope, Session) {
                        $scope.order = [[ Session.hasPermission('ADMIN') ? 2 : 1, 'desc' ]];
                    }]
                });
                $routeProvider.when('/evaluation/evaluation/new', {
                    templateUrl: '/partials/evaluation/evaluation/create.tpl.html'
                });
                $routeProvider.when('/evaluation/evaluation/:id/synthese', {
                    templateUrl: '/partials/evaluation/evaluation/synthese.tpl.html',
                    controller: showController
                });
                $routeProvider.when('/evaluation/evaluation/:id/resultats', {
                    templateUrl: '/partials/evaluation/evaluation/resultats.tpl.html',
                    controller: showController
                });
                $routeProvider.when('/evaluation/evaluation/:id/edit', {
                    templateUrl: '/partials/evaluation/evaluation/edit.tpl.html'
                });
            }]);

        }
    );
})(requirejs);

