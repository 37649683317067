(function (requirejs) {
'use strict';


define('../lib/eezymanager-core/javascripts/admin/client',['angular'],
    function(angular) {

        angular.module('eezymanager.core.admin.client', ['ngRoute', 'pascalprecht.translate'])

        // Routes
        .config(['$routeProvider', function($routeProvider) {
            $routeProvider.when('/admin/client', {
                templateUrl: '/eezymanager/core/partials/admin/client/list.tpl.html'
            });
            $routeProvider.when('/admin/client/new', {
                templateUrl: '/eezymanager/core/partials/admin/client/create.tpl.html'
            });
            $routeProvider.when('/admin/client/:id', {
                templateUrl: '/eezymanager/core/partials/admin/client/edit.tpl.html'
            });
        }])
        .controller('CoreClientIconCtrl', ['$scope', '$http','$routeParams', 'EezyUrl', 'EEZY_REFRESH_ICON', function($scope, $http, $routeParams, EezyUrl, EEZY_REFRESH_ICON){
            $scope.refresh = function(){
                $scope.$broadcast(EEZY_REFRESH_ICON);
            };
        }]);
    });
})(requirejs);

