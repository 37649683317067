(function (requirejs) {
'use strict';


define('../lib/eezymanager-core/javascripts/configuration/employee',['angular'],
    function(angular) {

        angular.module('eezymanager.core.configuration.employee', ['ngRoute', 'pascalprecht.translate'])


        // Routes
        .config(['$routeProvider', function($routeProvider) {
            $routeProvider.when('/configuration/employee', {
                templateUrl: '/eezymanager/core/partials/configuration/employee/list.tpl.html'
            });
            $routeProvider.when('/configuration/employee/new', {
                templateUrl: '/eezymanager/core/partials/configuration/employee/create.tpl.html'
            });
            $routeProvider.when('/configuration/employee/:id', {
                templateUrl: '/eezymanager/core/partials/configuration/employee/edit.tpl.html'
            });
        }]);

    });
})(requirejs);

