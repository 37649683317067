(function (requirejs) {
'use strict';

    define('admin',['angular', './admin/processus'], function(angular) {

        /* Services */

        // Demonstrate how to register services
        // In this case it is a simple value service.
        angular.module('eezycleanApp.admin', ['eezycleanApp.admin.processus']);


    });
})(requirejs);

